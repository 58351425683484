import { FC, MouseEventHandler, ReactNode, Ref } from 'react';
import styles from './Select.module.scss';
import clsx from 'clsx';

type SelectRenderValueProps = {
  label?: string;
  value: ReactNode;
  ref?: Ref<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const SelectRenderValue: FC<SelectRenderValueProps> = ({ value, label, ref, onClick }) => {
  return (
    <>
      <div ref={ref} onClick={onClick} className={styles.subtitleheading}>
        {label ? <div className={clsx(styles.placeholder, value ? '' : styles.novalue)}>{label}</div> : null}
        {value ? <div className={styles.value}>{value}</div> : null}
      </div>
      <img className={styles.iconsArrow} alt="" src="/icons/arrowDown.svg" />
    </>
  );
};

export default SelectRenderValue;
