import { Dispatch, SetStateAction } from 'react';
import { DateView } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import {enUS, ru} from 'date-fns/locale';

import styles from './RangeDatePicker.module.scss';
import Button from '../../Button';
import { getNormalDate } from '../../../utils/formatting';
import i18n from "i18next";

interface SwitchViewButtonProps {
  viewDate: Date;
  setCurrentView: Dispatch<SetStateAction<DateView[]>>;
}

const SwitchViewButton = ({ viewDate, setCurrentView }: SwitchViewButtonProps) => {
  const locale = i18n.language.startsWith('ru') ? ru : enUS
  const month = format(getNormalDate(viewDate), 'LLLL', { locale: locale });
  const year = format(getNormalDate(viewDate), 'yyyy', { locale: locale });
  const handleClick = (targetView: DateView) => {
    setCurrentView(s => (s[0] !== targetView ? [targetView] : ['day']));
  };
  return (
    <div className={styles.SwitchViewButtons}>
      <Button secondary variant="text" onClick={() => handleClick('month')}>
        {month}
      </Button>
      <Button secondary variant="text" onClick={() => handleClick('year')}>
        {year}
      </Button>
    </div>
  );
};

export default SwitchViewButton;
