import {FunctionComponent, useEffect, useState} from 'react';
import clsx from 'clsx';
import Button from '../Button';
import FilterPrice from './FilterPrice';
import FilterTransfers from './FilterTransfers';
import FilterTransferDuration from './FilterTransferDuration';
import FilterCompanies from './FilterCompanies';
import FilterDepartureArrival from './FilterDepartureArrival';
import styles from './FilterSidebar.module.scss';
import SkeletonFilter from '../Skeleton/SkeletonFilter/SkeletonFilter';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import { filterSelector, switchFilter } from '../../store/slices/filter';
import { filterBackendData, resetFilters, searchSelector } from "../../store/slices/search";
import {useTranslation} from "react-i18next";

interface FilterSidebarProps {
  loading?: boolean;
}

const FilterSidebar: FunctionComponent<FilterSidebarProps> = ({ loading }) => {
  const dispatch = useAppDispatch();
  const { isFilterOpen } = useSelector(filterSelector);
  const { from, to, search_airlines, search_airports, filter_airlines, airlines, data } = useSelector(searchSelector);
  const { t } = useTranslation();

  /*const [price, setPrice] = useState([0, 10000]);
  const [transferDuration, setTransferDuration] = useState([0, 720]);*/
  /*const handlePriceChange = (e: Event, newValue: number | number[]) =>
    typeof newValue !== 'number' && setPrice(newValue);
  const handleTransferDurationChange = (e: Event, newValue: number | number[]) =>
    typeof newValue !== 'number' && setTransferDuration(newValue);*/
  const handleSwitchFilter = () => {
    dispatch(switchFilter({ isFilterOpen: !isFilterOpen }));
  };

  const handleApplyFilters = (e: any, name?: string, newValue?: any) => {
      let url = new URL(window.location.href);
      let attribute = e !== null ? e.target.getAttribute('name') : name;
      let value = e !== null ? e.target.value : newValue;
      if (e !== null && e.target.getAttribute('type') === 'checkbox') {
          const attributeName = attribute?.substring(0, attribute?.indexOf('[')).trim();
          const current = url.searchParams.get(attributeName);
          const code = attribute.replace(attributeName, '').replace('[', '').replace(']', '').trim();
          value = [];
          if (current !== null) value = JSON.parse(decodeURIComponent(current));
          if (e.target.checked) {
              value.push(code);
          } else {
              value.splice(value.indexOf(code), 1);
          }
          if (value.length) {
              url.searchParams.set(attributeName, encodeURIComponent(JSON.stringify(value)));
          } else {
              url.searchParams.delete(attributeName);
          }
          dispatch(filterBackendData({ key: attributeName, value: value }))
      } else {
          dispatch(filterBackendData({ key: name, value: newValue }))
      }
      window.history.replaceState(null, '', url);
  }

  const handleResetFilters = () => {
    dispatch(resetFilters());
    window.history.replaceState(null, '', '/search');
  }

  if (loading)
    return (
      <div className={styles.filter}>
        <div className={styles.filter1}>
          <SkeletonFilter />
          {/*<SkeletonFilter filterHeight="213px" />*/}
          <SkeletonFilter />
          <SkeletonFilter filterHeight="296px" />
        </div>
      </div>
    );

  const hasBackTickets = (data && data?.tickets && data.tickets.length > 0 && data.tickets[0].from !== undefined && data.tickets[0].from !== null) ?? false;
  const otherAirports = (data && data?.trip_airports) ? data.trip_airports : false;

  const filters = (
    <>
      <div className={styles.filter2}>
        {/*<FilterPrice value={price} min={0} max={10000} onChange={handlePriceChange} />*/}
        <FilterTransfers onChange={ handleApplyFilters } />
        {/*<FilterTransferDuration value={transferDuration} min={0} max={720} onChange={handleTransferDurationChange} />*/}
        { Object.keys(search_airlines).length > 0 && <FilterCompanies search_airlines={ search_airlines } available={ filter_airlines } airlines={ airlines } onChange={ handleApplyFilters } />}
        { Object.keys(search_airports).length > 0 && from && to && <FilterDepartureArrival search_airports={ search_airports } from={ otherAirports ? otherAirports.from : from } to={ otherAirports ? otherAirports.to : to } oneway={ !hasBackTickets } onChange={ handleApplyFilters } />}
      </div>
    </>
  );

  const handleCancelFilterMenu = () => {
    handleSwitchFilter();
    handleResetFilters();
  }

  return (
    <div className={styles.filter}>
      <Button
        className={styles.filterButton}
        onClick={handleSwitchFilter}
        variant="outlined"
        iconBefore="/icons/filter.svg"
      >
        {t('filter.filter')}
      </Button>
      <div className={styles.filterDesktop}>
        {filters}
        <Button className={styles.resetButton} variant="text" iconBefore="/icons/close.svg" onClick={handleResetFilters}>
          {t('filter.reset')}
        </Button>
      </div>
      <div className={clsx(styles.filterMobile, isFilterOpen ? styles.open : '')}>
        <div className={styles.filterHeader}>
          <span>{t('filter.filter')}</span>
          <Button variant="text" className={styles.cross} onClick={handleCancelFilterMenu}>
            <img src="/icons/closeThin.svg" alt="" />
          </Button>
        </div>
        {filters}
        {!!isFilterOpen && (
          <div className={styles.filterMobile_buttons}>
            <Button onClick={handleSwitchFilter}>{t('filter.showTickets')}</Button>
            <Button className={styles.resetButton} variant="text" iconBefore="/icons/close.svg" onClick={handleResetFilters}>
              {t('filter.reset')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSidebar;
