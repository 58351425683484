import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useLangRoute = () => {

  const [ langRoute, setLangRoute ] = useState<'/en' | ''>('')

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/en')) {
      setLangRoute('/en');
    } else {
      setLangRoute('');
    }
  }, [location.pathname]);

  return {
    langRoute,
  }

}
