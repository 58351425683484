import { FunctionComponent } from 'react';
import styles from './MainLogo.module.scss';
import { useAppDispatch } from '../../../store/store';
import { resetSearch } from '../../../store/slices/search';
import { reset } from '../../../store/slices/datePicker';
import {useTranslation} from "react-i18next";
import { LangLink } from '../../LangLink/LangLing';
import { routes } from '../../../constants/routes';

type MainLogoType = {
  variant?: 'light' | 'dark';
};

const MainLogo: FunctionComponent<MainLogoType> = ({ variant = 'dark' }) => {
  const {t} = useTranslation();
  const style = variant === 'dark' ? styles.iconWhite : styles.iconBlack;
  const dispatch = useAppDispatch();
  return (
    <LangLink to={routes.home} onClick={() => {
      dispatch(resetSearch());
      dispatch(reset());
    }}>
      <img className={style} alt="" src={t('images.kupitripLogo')} />
    </LangLink>
  );
};

export default MainLogo;
