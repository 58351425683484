import { FunctionComponent } from 'react';
import styles from './NumberInput.module.scss';

type NumberInputType = {
  label: string;
  description: string;
  value: number;
  name: string;
  min?: number;
  max?: number;
  onChange: (n: number, name: string) => void;
};

const NumberInput: FunctionComponent<NumberInputType> = ({ label, description, value, name, min, max, onChange }) => {
  const onAdd = () => onChange(value + 1, name);
  const onSubtract = () => onChange(Math.max(0, value - 1), name);

  return (
    <div className={styles.root}>
      <div className={styles.subtitleheading}>
        <div className={styles.label}>{label}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.quantity}>
        <button disabled={min === value} className={styles.scrollArrow} onClick={onSubtract}>
          <img className={styles.icon} alt="" src="/icons/minus.svg" />
        </button>
        <div className={styles.value}>{value}</div>
        <button disabled={max === value} className={styles.scrollArrow} onClick={onAdd}>
          <img className={styles.icon} alt="" src="/icons/plus.svg" />
        </button>
      </div>
    </div>
  );
};

export default NumberInput;
