import {IAirlines, ProposalsEntity} from './backend';
import { IPassenger } from './passenger';
import {Airport} from "./ticket";

export const ORDER_STATUS = {
  WAITING: 'wait-for-payment',
  PAYED: 'paid',
  CANCELED: 'cancel',
  RETURN: 'return_request'
} as const;

export const ORDER_PAYMENT_TYPE = {
  CARD_RU: 'card_ru',
  CARD: 'card',
  SBP: 'sbp'
} as const;

export type IOrderStatusKeys = keyof typeof ORDER_STATUS;
export type IOrderStatus = typeof ORDER_STATUS[IOrderStatusKeys];
export type IOrderPaymentTypeKeys = keyof typeof ORDER_PAYMENT_TYPE;
export type IOrderPaymentType = typeof ORDER_PAYMENT_TYPE[IOrderPaymentTypeKeys];

export interface IOrder {
  id: number;
  id_hash?: string;
  created_at?: string;
  updated_at?: string;
  user_id: number;
  email: string;
  phone: string;
  register?: boolean;
  paid_status: IOrderStatus;
  ticket: { trip: ProposalsEntity | null; back?: ProposalsEntity | null };
  orderDate: number;
  passengers: IPassenger[];
  passengersCount?: number;
  comment?: string | null;
  booking_number?: string | null;
  need_booking?: boolean | null;
  airports: Airport[] | {};
  airlines: IAirlines[] | {};
  price?: number;
  price_euro?: number;
  locale?: string;
}

export interface IOrderPayment {
  order_id: number;
  type: IOrderPaymentType;
}

export interface IOrderPaymentResult {
  status: string | boolean;
  data?: string | object | null;
  message?: string;
}
