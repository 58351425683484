import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const useLangNavigate = () : NavigateFunction => {

  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const langNavigate = (to: To, options?: NavigateOptions): void => {

    const isEnglish = i18n.language === 'en';

    const newPath = isEnglish ? `/en${to}` : to;

    navigate(newPath, options);

  }

  return <NavigateFunction>langNavigate;

}
