import clsx from 'clsx';
import { Dialog } from '@mui/material';
import { FC } from 'react';
import { closeAll } from '../../../store/slices/modal';
import { useAppDispatch } from '../../../store/store';
import Button from '../../Button';
import styles from './Modal.module.scss';

type Props = {
  isOpen: boolean;
  header?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

const Modal: FC<Props> = ({ isOpen, header, children, className }) => {
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeAll());

  return (
    <Dialog classes={{ paper: clsx(styles.paper, className || '') }} open={isOpen} onClose={handleClose}>
      <div className={styles.close}>
        <Button variant="text" className={styles.cross} onClick={handleClose}>
          <img src="/icons/closeThin.svg" alt="" />
        </Button>
      </div>
      {header && <div className={styles.header}><h1 className={styles.h1}>{header}</h1></div>}
      <div className={styles.root}>{children}</div>
    </Dialog>
  );
};

export default Modal;
