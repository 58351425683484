import { IOrder } from '../types/order';
import { ProposalsEntity } from '../types/backend';

/*export const getOrderPrice = (order: IOrder) => {
  const price = order.passengers.filter(p => p.type !== 'baby').length * 699;
  return `${price} ₽`;
};
export const getOrderDetailedPrice = (order: IOrder) => {
  const passengers = order.passengers.filter(p => p.type !== 'baby').length;
  return passengers > 1 ? `${passengers} x 699 ₽` : null;
};*/

export const formatDate = (dateToFormat: Date | string) => {
  if (!dateToFormat || dateToFormat === '') return '';
  if (typeof dateToFormat === 'string') {
    if (dateToFormat.indexOf('-') > -1) {
      dateToFormat = dateToFormat.trim().substring(0, 10).split('-').reverse().join('.');
    }
    return dateToFormat.trim();
  }
  try {
    const date = new Date(dateToFormat);
    return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth()+1)).slice(-2)}.${date.getFullYear()}`;
  } catch (e) {
    return '';
  }
}

export const getDocument = (document: string) => (documents: Record<string, any>) => documents[document];

export const formatDocumentNumber = (documentNumber: string) =>
  `${documentNumber.substring(0,4)} ${documentNumber.substring(4,documentNumber.length)}`

export const onDateFieldChange= (event: any, field: any) => {
  const value = event.target.value.replace(/[^0-9/-/.]/gi, '');
  if (/[^\d\.]/.test(value)){
    return
  }
  if (value.length > 10) {
    return
  }
  let newValue = value;
  if (
    (value.length === 3 || value.length === 6) &&
    value[value.length - 1] !== "."
  ) {
    newValue =
      value.substring(0, value.length - 1) +
      "." +
      value[value.length - 1]
  }
  const dateValues = newValue.split('.');
  if (/\d{2}.\d{2}.\d{4}/.test(newValue)){
    const newDate = new Date(+dateValues[2], +dateValues[1] - 1, +dateValues[0], 23, 59)
    field.onChange(formatDate(newDate));
  } else {
    field.onChange(newValue);
  }
}