import { FunctionComponent, RefObject, useCallback } from 'react';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { Box } from '@mui/system';

import styles from './PassengersDropdown.module.scss';
import NumberInput from './NumberInput';
import TabButtons from './TabButtons';
import { Fade } from '@mui/material';
import { useAppDispatch } from '../../../store/store';
import {searchSelector, SearchTypeEnum, setPassengers, setType} from '../../../store/slices/search';
import { useSelector } from 'react-redux';
import {useTranslation} from "react-i18next";

export interface PassengersDropdownProps {
  anchorRef: RefObject<HTMLElement>;
  open: boolean;
  id: string;
}

const getValue = (adults: number, children: number, babies: number) => adults + children + babies;

const PassengersDropdown: FunctionComponent<PassengersDropdownProps> = ({ anchorRef, open, id }) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const { adults, children, babies, type } = useSelector(searchSelector);
  const count = getValue(adults, children, babies);

  const handleChange = useCallback((n: number, name: string) => {
    dispatch(setPassengers({ [name]: n }));
  }, []);

  const handleChangeType = useCallback((value: any) => {
    dispatch(setType({ type: value }));
  }, []);

  const SearchTypeEnum = {
    // 'any': 'Любой',
    'eco': t('searchTypeEnum.eco'),
    'biz': t('searchTypeEnum.biz'),
  };

  const options = Object.entries(SearchTypeEnum).map(([key, value]) => {
    return {
      'value': key,
      'label': value
    }
  });

  return open ? (
    <PopperUnstyled
      modifiers={[
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'flip', options: { fallbackPlacements: ['bottom-start'] } },
      ]}
      anchorEl={anchorRef.current}
      disablePortal
      id={id}
      open={open}
      className={styles.dropdown}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box className={styles.box}>
            <NumberInput
              name="adults"
              onChange={handleChange}
              label={t('passengersDropdown.adults')}
              description={t('passengersDropdown.adultsDescription')}
              value={adults}
              min={1}
              max={count < 9 ? (adults + 1) : adults}
            />
            <NumberInput
              name="children"
              onChange={handleChange}
              label={t('passengersDropdown.children')}
              description={t('passengersDropdown.childrenDescription')}
              value={children}
              min={0}
              max={count < 9 ? (children + 1) : children}
            />
            <NumberInput
              name="babies"
              onChange={handleChange}
              label={t('passengersDropdown.babies')}
              description={t('passengersDropdown.babiesDescription')}
              value={babies}
              min={0}
              max={count < 9 ? (babies + 1) : babies}
            />
            <TabButtons options={options} value={type} onChange={handleChangeType} />
          </Box>
        </Fade>
      )}
    </PopperUnstyled>
  ) : null;
};

export default PassengersDropdown;
