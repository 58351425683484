import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {Review} from "../../types/review";
import {BASE_URL} from "../../utils/api";

const basicHeaders = {Accept: 'application/json', 'Content-type': 'application/json'};

export const reviewsApi = createApi({
    reducerPath: 'reviewsApi',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: builder => ({
        getReviews: builder.query< Review[] , { offset: number }>({
            query: ({offset}) => ({
                url: 'reviews',
                headers: {Accept: 'text/html'},
                params: {offset: offset}
            }),
        }),
        getReviewsCount: builder.query<{ count: number }, {}>({
            query: () => ({
                url: 'reviews-count',
                headers: {Accept: 'text/html'},
                params: {}
            }),
        }),
        createReview: builder.mutation<{ data: {} }, { name: string, surname: string, rating: number, text: string }>({
            query: (data) => ({
                url: 'review',
                method: 'POST',
                headers: {...basicHeaders},
                body: data
            }),
        }),
    }),
});

export const {useGetReviewsQuery, useGetReviewsCountQuery, useCreateReviewMutation} = reviewsApi;