import { PARTNER_BONUS_ON_RUB } from '../../components/Partnership/PartnershipLinks/PartnershipLinks';

export const ru = {
  translation: {
    navigationButton: {
      buttonHotels: 'Отели',
      buttonAvia: 'Авиабилет'
    },

    links: {
      main: 'Главная',
      tariff: 'Тарифы',
      help: 'Помощь',
      blog: 'Блог',
      feedback: 'Отзывы',
      securityAndReturnPolicy: 'Политика безопасности и возврата',
      rules: 'Договор оферты',
      policy: 'Политика конфиденциальности'
    },
    common: {
      search: 'Найти',
      back: 'Назад',
      noResults: 'Нет результатов',
      kupitrip: 'КупиТрип',
      showMore: 'Показать ещё',
      loading: 'Идет загрузка...'
    },
    main: {
      header: {
        part1: 'Бронирование',
        part2: 'авиабилетов для визы',
        account: {
          loggedIn: 'Мой профиль',
          login: 'Войти'
        }
      },
      age: {
        adults: {
          label: 'Взрослые',
          description: '12 лет и старше'
        },
        children: {
          label: 'Дети',
          description: 'от 2 до 12 лет'
        },
        infants: {
          label: 'Младенцы',
          description: 'До 2 лет, без места'
        }
      },
      searchBar: {
        labels: {
          from: 'Откуда',
          to: 'Куда',
          flightDate: 'Дата туда',
          returnDate: 'Дата обратно',
          passengersClass: 'Пассажиры, класс'
        }
      },

      transfers: {
        '0': 'пересадок',
        '1': 'пересадка',
        '2': 'пересадки',
        '5': 'пересадок'
      },
      passengers: {
        '0': 'пассажиров',
        '1': 'пассажир',
        '2': 'пассажира',
        '5': 'пассажиров'
      },
      howWorksPart1: 'Как работает ',
      howWorksPart2: 'КупиТрип',
      step: 'шаг',
      searchAndSelectARoute: 'Поиск и выбор маршрута',
      searchAndSelectARouteDescription: 'В строке поиска выберите нужные города, даты и количество пассажиров. Из предложенных вариантов вы можете забронировать любой подходящий вариант перелета.',
      fillingInTheData: 'Заполнение данных',
      fillingInTheDataDescription: 'Укажите данные загранпаспорта и контактную информацию.',
      paymentForBooking: 'Оплата бронирования',
      paymentForBookingDescription: `Вы оплачиваете только наш сервисный сбор - {{price}}, не думая о полной стоимости билета. Оплатить можно любой банковской картой`,
      orThroughSBP: ' или через СБП.',
      validityPeriod: 'Срок действия',
      upToSevenDays: 'до 7 дней',
      validityPeriodDescription: 'Бронирование будет доступно до 7 дней, после истечения срока оно аннулируется',
      sendingCompletedDocuments: 'Отправка готовых документов',
      sendingCompletedDocumentsDescription: 'Мы отправим готовые документы на указанную электронную почту в течение 30 минут. Распечатайте и приложите их к заявлению на визу или для любых других целей.',
      questionsAndAnswers: 'Вопросы и ответы',
      registrationSuccess: 'Успешная регистрация',
      thankYouForChoosingOurService: 'Спасибо, что выбрали наш сервис.',
      questionList: [
        {
          id: 1,
          question: 'Сколько стоят ваши услуги?',
          answer: 'Стоимость бронирования вы можете посмотреть на нашем сайте в разделе <a href=\'/tariff\'>Тариф</a>'
        },
        {
          id: 2,
          question: 'Через сколько я получу бронь после оформления заказа?',
          answer: 'Максимальное время выписки брони билета 30 минут (обычно выписываем в течение 15 минут).'
        },
        {
          id: 3,
          question: 'Когда лучше делать бронирование для визы, если подача на визу через неделю?',
          answer: 'Чтобы бронь держалась в момент рассмотрения визы, вам лучше оформить её за 1 день до подачи.'
        },
        {
          id: 4,
          question: 'Подходит ли ваша бронь для прохождения пограничного контроля в аэропорту?',
          answer:
            'Да, бронь авиабилета подходит также для прохождения пограничного контроля в стране вылета/прилета. Маршрутная квитанция авиабилета предоставляется на английском и русском языке, что соответствует правилам, а также содержит номер бронирования по которому можно проверить билет.'
        },
        {
          id: 5,
          question: 'Можно ли выкупить свою бронь билета для осуществления полета?',
          answer:
            'Нет, бронь билета нельзя выкупить. Для осуществления полета вы можете купить билет на сайтах по продаже билетов.'
        }
      ],
      moreQuestions: 'Больше вопросов',
      weAreAlwaysInTouch: 'Мы всегда на связи!',
      anyQuestionsLeft: 'Остались вопросы?',
      anyQuestionsDescription: 'Служба поддержки работает круглосуточно и без выходных. Ответим на вопросы по бронированию и подскажем как оформить заказ на сайте.',
      writeToChat: 'Написать в чат',
      writeToUsAt: 'Напишите нам в ',
      telegram: 'Телеграме',
      ourSupportServiceIsAvailable: 'Наша служба поддержки есть в популярных мессенджерах',
      feedbacks: 'Отзывы',
      moreFeedbacks: 'Все отзывы'

    },
    hotels: {
      bookingTitle1: 'Бронирование',
      bookingTitle2: 'отелей для визы',
      howItwork: 'Как это работает',
      step1: {
        title: '1 шаг',
        subtitle: 'Выбор города',
        description: 'В строке поиска выберите город, даты и количество гостей.'
      },
      step2: {
        title: '2 шаг',
        subtitle: 'Заполнение данных',
        description: 'Укажите данные гостей и контактную информацию.'
      },
      step3: {
        title: '3 шаг',
        subtitle: 'Оплата брони',
        description: `Вы оплачиваете только наш сервисный сбор - {{price}}, не думая о полной стоимости бронирования.`,
        descriptionPart2: ' Оплатить можно любой банковской картой',
        orThroughSBP: ' Оплатить можно любой банковской картой или через СБП.'
      },
      step3second: {
        title: 'Срок действия',
        subtitle: 'до 14 дней',
        description: 'Бронирование будет доступно до 14 дней, после истечения срока оно аннулируется'
      },
      step4: {
        title: '4 шаг',
        subtitle: 'Отправка готовых документов',
        description: 'Мы отправим готовые документы на указанную электронную почту в течение 60 минут. Распечатайте и\n' +
          '                    приложите их к заявлению на визу или для любых других целей.'
      },
      headerQuests: 'Вопросы и ответы',
      buttonMoreQuests: 'Больше вопросов',
      weAreAlwaysInTouch: {
        title: 'Мы всегда на связи!',
        firstBlock: {
          title: 'Остались вопросы?',
          subtitle: 'Служба поддержки работает круглосуточно и без выходных. Ответим на вопросы по бронированию и подскажем как оформить заказ на сайте.'
        },
        secondBlock: {
          title: 'Напишите нам в ',
          secondTitle: 'Телеграмме',
          subtitle: 'Наша служба поддержки есть в популярных мессенджерах'
        }
      },
      buttonReadChat: 'Написать в чат',
      buttonAllFeedback: 'Все отзывы',
      feedbackButtonText: 'Все отзывы',
      feedbackButton: 'Читать полностью',
      answer: 'Есть ответ',
      feedbackHeader: 'Oтзывы',
      popularQuestionsHotels: [
        {
          id: 1,
          question: 'Можно ли выбрать отель?',
          answer: 'Выбор отеля недоступен. При оформлении заказа вы можете выбрать категорию отеля, а также, при необходимости, указать предпочтения по расположению в комментарии. '
        },
        {
          id: 2,
          question: 'Сколько держится бронь отеля?',
          answer: 'Бронь отеля держится до 14 дней (если до даты заезда более 20 дней).\n' +
            'Если до даты заезда менее 20 дней, то срок действия брони зависит от близости даты заезда.'
        },
        {
          id: 3,
          question: 'Сколько стоит оформить бронирование отеля?',
          answer: 'Стоимость указана на странице <a href=\'/tariff\'>Тариф</a> '
        },
        {
          id: 4,
          question: 'Надо отменять бронь?',
          answer:
            'Бронирование отеля отменяется автоматически по истечении времени. С вашей стороны никакого действия не требуется.'
        },
        {
          id: 5,
          question: 'Как проверить бронь?',
          answer:
            'Бронь отеля проверяется через связь с отелем по телефону.'
        },
        {
          id: 6,
          question: 'Могу ли я оформить заказ с датой заезда в отель сегодня?',
          answer:
            'Нет. Дата заезда в отель должна быть минимум через 3 дня от момента оформления заказа.'
        },
        {
          id: 7,
          question: 'Когда ждать бронь?',
          answer: 'Все заказы обрабатываются в порядке очереди в течение 60 мин. Мы отправим бланк бронирования отеля на вашу почту.'
        }
      ]


    },

    tariff: {
      tariff: 'Тариф',
      airTickets: 'Авиабилеты',
      pricePerPassenger: 'Цена за 1 пассажира:',
      rub: {
        whenPayingIn: 'При оплате в рублях:',
        description: 'При оплате картой выпущенной банком РФ оплата в рублях'

      },
      eur: {
        whenPayingIn: 'При оплате в евро:',
        description: 'В евро оплата производится картой, выпущенной банком любой страны.'
      },
      validityPeriod: 'Срок действия:',
      upToDays: 'до 7 дней',
      upToDaysPopup: 'После бронирования авиакомпания может уменьшить тайм-лимит по авиабилетам в одностороннем порядке',
      bookingSuitable: 'Бронь подойдет:',
      toApplyForAVisa: 'Для получения визы',
      toProvideAReturnTicket: 'Для предоставления обратного билета в стране вылета\\прилета',
      forAnyOtherPurposes: 'Для любых других целей, где нужна бронь',
      book: 'Забронировать',
      example: 'Пример брони (PDF)'
    },
    tariffHotels: {
      tariff: 'Отели',
      pricePerGuests: 'Цена за 1 гостя:',
      rub: {
        whenPayingIn: 'При оплате в рублях:',
        description: 'При оплате картой выпущенной банком РФ оплата в рублях'

      },
      eur: {
        whenPayingIn: 'При оплате в евро:',
        description: 'В евро оплата производится картой, выпущенной банком любой страны.'
      },
      validityPeriod: 'Срок действия:',
      upToDays: 'до 14 дней',
      bookingSuitable: 'Бронь подойдет:',
      toApplyForAVisa: 'Для  подачи на визу',
      forAnyOtherPurposes: 'Для любых других целей, где нужна бронь',
      book: 'Забронировать',
      example: 'Пример брони (PDF)'
    },
    placeOrderHotelPage: {
      sidebar: {
        paymentInRubles: 'При оплате в рублях',
        or: 'или',
        paymentInEuros: 'При оплате в евро',
        validFor14Days: 'Бронь действительна до 14 дней\n' +
          '          (Если до даты заезда на момент бронирования больше 20 дней)',
        amountDue: 'К оплате',
        headerDetail: 'Бронь за одного гостя'
      },
      bokingWishCost: {
        header: ' Желаемая стоимость отеля (за все ночи)',
        label: ' Желаемая стоимость отеля (за все ночи)',
        upTo: 'До'
      },

      typeHotel: {
        header: 'Подбор отеля происходит вручную менеджерами, поэтому отель будет подобран согласно выбранным данным по:',
        paragraph1: 'городу',
        paragraph2: 'типу отеля',
        paragraph3: 'датам заезда',
        paragraph4: 'количеству гостей',
        description: 'Желаемый тип отеля не гарантирован и может быть изменен менеджерами Купитрип в зависимости от наличия и доступности номеров в отеле.',
        label: 'Тип отеля',
        guest: 'Гость',
        labelGuestFirst: 'Имя (на латинском)',
        labelGuestLast: 'Фамилия (на латинском)',
        sortOptions: [
          { id: 0, label: 'Хостел (кровать в общем номере)', value: '1' },
          { id: 1, label: 'Отель ★★', value: '2' },
          { id: 2, label: 'Отель ★★★', value: '3' },
          { id: 3, label: 'Отель ★★★★', value: '4' },
          { id: 4, label: 'Отель ★★★★★', value: '5' }
        ]

      }
    },

    footer: {
      onlineServiceForBooking: 'Онлайн-сервис бронирования авиабилетов для визы',
      help: 'Помощь',
      popularQuestions: 'Популярные вопросы',
      partnership: 'Сотрудничество',
      partnershipProgram: 'Партнерская программа',
      forClients: 'Клиентам',
      tariff: 'Тариф',
      blog: 'Блог',
      aviaTicket: 'Авиабилеты',
      hotels: 'Отели',
      pay: 'Оплата и возврат',
      feedback: 'Отзывы',
      weAreOnSocialNetworks: 'Мы в социальных сетях',
      weAccept: 'Мы принимаем',
      safetyReturnPolicy: 'Политика возврата и безопасности',
      delaemIt: 'Сделано в Делаем IT',
      kupiTrip: '©“КУПИТРИП”2014-',
      copyright: 'КУПИТРИП'
    },
    searchBar: {
      selectDepartureCity: 'Выберите город вылета',
      selectYourArrivalCity: 'Выберите город прибытия',
      selectDate: 'Выберите дату',
      selectAnotherArrivalCity: 'Укажите разные города',
      any: 'Любой',
      departure: 'Откуда',
      arrival: 'Куда',
      departureDate: 'Дата туда',
      returnDate: 'Дата обратно'
    },
    searchBarHotels: {
      selectDepartureCity: 'Выберите город',
      selectYourArrivalCity: 'Выберите город прибытия',
      selectDate: 'Выберите дату',
      selectAnotherArrivalCity: 'Укажите разные города',
      any: 'Любой',
      departure: 'Откуда',
      arrival: 'Куда',
      checkInDate: 'Дата заезда',
      checkOutDate: 'Дата выезда',
      searchButton: 'Найти'

    },
    guestSelect: {
      countGuests: 'Количество, гостей',
      description: 'Все возраста, максимум 5 чел в заказе',
      label: 'Гости',
      getRightEndingGuests: {
        guest: 'гость',
        guestsFew: 'гостя',
        guests: 'гостей'
      }

    },

    bookingAdvice: {
      title: 'Советуем оформлять бронь билетов в день предъявления на границе/для визы.',
      description: 'Мы оформляем заказы круглосуточно в течение 15 минут!'
    },
    cookiesBanner: {
      agreement: 'Продолжая использовать наш сайт, вы соглашаетесь с правилами сбора информации и использования cookie',
      ok: 'Хорошо'
    },
    feedbackCard: {
      hadAnswer: 'Есть ответ',
      readMore: 'Читать полностью'
    },
    filter: {
      filter: 'Фильтр',
      reset: 'Сбросить фильтр',
      showTickets: 'Показать билеты',
      departureAndArrival: 'Вылет и прибытие',
      wayTo: 'ТУДА',
      wayReturn: 'ОБРАТНО',
      departureFrom: 'Вылет из ',
      arrivalTo: 'Прибытие в ',
      price: 'Цена',
      transferDuration: 'Длительность пересадки',
      transfersList: {
        none: 'Без пересадок',
        one: '1 пересадка',
        more: '2 и более'
      },
      transfers: 'Пересадки',
      airlines: 'Авиакомпании'
    },
    loader: {
      findingTickets: 'Ищем для вас лучшие билеты!',
      subtitle: 'Пожалуйста, подождите........'
    },
    personalPage: {
      personalData: 'Персональные данные'
    },
    feedbackPage: {
      addFeedback: 'Оставить отзыв',
      showMore: 'Показать еще',
      title: 'Отзывы'
    },
    partnershipSettings: {
      card: {
        label: 'Номер карты',
        description: 'По номеру карты'
      },
      phone: {
        label: 'По номеру телефона (СБП)',
        description: 'Через СПБ'
      },
      req: {
        label: 'По реквизитам',
        description: 'Физическое или юридическое лицо'
      },
      personalInfo: {
        name: 'Имя',
        surname: 'Фамилия',
        patronymic: 'Отчество'
      },
      bankAccount: {
        bik: 'БИК банка',
        checkingAccount: 'Расчётный счёт',
        corpAccount: 'Корпоративный счёт'
      },
      settingsSavedSuccessfully: 'Настройки успешно сохранены',

      bankName: 'Наименование банка',
      phoneLinkedToSBP: 'Телефон (привязанный к СБП)',
      sbpBankName: 'Наименование банка',
      enterPhoneLinkedToSBP: 'Введите телефон привязанный к системе быстрых платежей',
      recipient: 'Получатель',
      accountDetails: 'Данные счёта',
      save: 'Сохранить',
      becomePartner: 'Стать партнером',
      accountSettings: 'Настройки кабинета',
      paymentMethod: 'Способ выплаты'
    },
    modals: {
      becomePartner: {
        registrationError: 'Ошибка регистрации',
        becomePartner: 'Стать партнером',
        linkPlacement: 'Место размещения ссылки',
        link: 'Ссылка',
        examplePlatforms: 'Например: Яндекс Дзен, личный сайт, YouTube канал',
        paymentMethod: 'Способ выплаты',
        cardNumber: 'Номер карты',
        bankName: 'Наименование банка'
      },
      createPartnerLink: {
        error: 'Не удалось создать ссылку',
        success: 'Ссылка успешно создана',
        linkCopiedToClipboard: 'Ссылка успешно скопирована в буфер обмена',
        newLink: 'Новая ссылка',
        linkTitle: 'Название ссылки',
        add: 'Добавить',
        cancel: 'Отмена'
      },
      deleteLink: {
        linkDeletionSuccess: 'Ссылка успешно удалена',
        deleteLinkPrompt: 'Удалить ссылку?',
        deleteLinkPromptDescription: 'Ссылка станет больше не доступна',
        delete: 'Удалить',
        cancel: 'Отмена'
      },
      deletePassenger: {
        passengerDeletionSuccess: 'Пассажир успешно удален',
        header: 'Удалить пассажира?',
        description: 'Удалятся все персональные данные и данные документа',
        delete: 'Удалить',
        cancel: 'Отмена'
      },
      feedbackCreate: {
        thankYouForFeedback: 'Спасибо за отзыв',
        reviewPendingModeration: 'После модерации ваш отзыв будет опубликован.',
        fieldRequired: 'Поле обязательно',
        fieldMaxLength: 'Превышено максимальное количество символов',
        name: 'Имя',
        surname: 'Фамилия',
        rating: 'Оценка',
        feedback: 'Отзыв'
      },
      createPassword: {
        done: 'Готово!',
        passwordSaved: `Ваш пароль успешно сохранен. Вы будете перенаправлены на главную через несколько мгновений`,
        passwordSaveError: 'Не удалось сохранить пароль',
        fieldRequired: 'Поле обязательно',
        passwordMinLength: 'Минимальная длина пароля 8 символов',
        createPasswordPrompt: 'Придумайте пароль',
        passwordMismatch: 'Пароль не совпадает',
        passwordLengthRequirement: 'Пароль должен содержать не менее 8 символов',
        passwordCriteriaTitle: 'Пароль надежный, если содержит:',
        latinLettersInDifferentCases: 'Латинские буквы в разном регистре',
        digits: 'Цифры',
        repeatPassword: 'Повторите пароль'
      },
      updatePassenger: {
        passengerSuccessfullyUpdated: 'Пассажир успешно обновлен',
        adult12AndOlder: 'Взрослый, 12 лет и старше',
        adultText: 'Дату рождения изменить нельзя т.к мы выдаем поисковую выдачу по типу возраста пассажира. Редактируйте данные в рамках типа пассажира или сформируйте новый поисковый запрос.',
        save: 'Сохранить'
      },
      register: {
        header: 'Проверьте почту',
        text: 'Мы отправили ссылку для подтверждения регистрации на ',
        emailAlreadyInUseByAnotherUser: 'Данный e-mail адрес уже используется другим пользователем',
        register: 'Регистрация',
        registerText: 'Введите e-mail и мы отправим письмо для подтверждения на вашу электронную почту.',
        required: 'Поле обязательно',
        minLength: 'Минимальная длина пароля 8 символов',
        passwordRequirementLength: 'Пароль должен содержать не менее 8 символов.',
        strongPasswordCriteria: 'Пароль надежный, если содержит:',
        latinLettersDifferentCase: 'Латинские буквы в разном регистре',
        digits: 'Цифры',
        registerButton: 'Зарегистрироваться',
        passwordMismatch: 'Пароль не совпадает',
        confirmPassword: 'Повторите пароль',
        createPassword: 'Придумайте пароль'
      },
      forgotPassword: {
        header: 'Смена пароля',
        description: 'Укажите почту, с которой вы регистрировались. Мы вышлем ссылку для смены пароля.',
        fieldRequired: 'Поле обязательно',
        emailLabel: 'E-mail',
        submitButton: 'Сменить пароль',
        checkYourEmail: 'Проверьте почту',
        passwordRecoveryLinkSentTo: 'Мы отправили ссылку для восстановления пароля на',
        serverError: 'Ошибка сервера'
      },
      formInfo: {
        header: 'Как заполнить данные',
        generalInfoTitle: 'Общая информация',
        generalInfoText: 'Данные для оформления брони билетов должны соответствовать документу пассажира, по которому он собирается путешествовать. При вводе данных все буквы должны быть написаны латиницей (в английской раскладке клавиатуры), без пробелов и дефисов. Двойная фамилия указывается слитно, без пробелов и дефисов.',
        passportTitle: 'Паспорт',
        passportDetail1: 'Написание как в документе  1234567890',
        passportDetail2: 'Срок действия паспорта (до какого годен) указывается при наличии',
        passportNumberLabel: 'Номер документа'
      },
      paymentModal: {
        header: 'Оплата заказа',
        cardRuLabel: 'Банковской картой РФ',
        cardRuDescription: 'Оплата возможна только картой выпущенной банком РФ',
        cardLabel: 'Банковской картой любой страны',
        cardDescription: 'Оплата возможна только картой любой страны (кроме РФ). Стоимость указана в евро фиксированная, она не зависит от курса.',
        sbpLabel: 'Через СБП',
        sbpDescription: 'Система быстрых платежей',
        cryptoTitle: 'Криптовалютой',
        cryptoDescriptionPart1: 'Для оплаты напишите номер заказа в наш ',
        chatSupport: 'чат поддержки',
        cryptoDescriptionPart2: ' или ',
        telegram: 'телеграм',
        errorSelectPayment: 'Выберите способ оплаты или напишите нам в чат поддержки или телеграм (для криптовалюты)',
        rublesPaymentLabel: 'При оплате в рублях:',
        or: 'или',
        euroPaymentLabel: 'При оплате в евро:',
        howToPayInEuro: 'Как оплатить в Евро?',
        howToPayInEuroDetails: 'Для оплаты банковской картой в евро необходимо изменить валюту оплаты на Евро (EUR) справа вверху в разделе «Язык и валюта» в евро доступна оплата картой банка любой страны, кроме РФ.',
        howToPayInRub: 'Как оплатить в Российских рублях?',
        howToPayInRubDetails: 'Для оплаты банковской картой в рублях необходимо изменить валюту оплаты на Российский рубль (RUB) справа вверху в разделе «Язык и валюта» в рублях доступна оплата только картой банка РФ',
        payButton: 'Оплатить',
        agreementTextPart1: 'Даю согласие на ',
        agreementLink1: 'обработку персональных данных',
        agreementTextPart2: ', согласен(а) с ',
        agreementLink2: 'тарифом',
        agreementTextPart3: ',',
        agreementLink3: ' условиями договора-оферты',
        variantPayEur: 'Возможна оплата в €',
        variantPayRub: 'Возможна оплата в ₽'
      },
      paymentHotelModal: {
        header: 'Оплата заказа',
        cardRuLabel: 'Банковской картой РФ',
        cardRuDescription: 'Оплата возможна только картой выпущенной банком РФ',
        cardLabel: 'Банковской картой любой страны',
        cardDescription: 'Оплата возможна только картой любой страны (кроме РФ). Стоимость указана в евро фиксированная, она не зависит от курса.',
        sbpLabel: 'Через СБП',
        sbpDescription: 'Система быстрых платежей',
        cryptoTitle: 'Криптовалютой',
        cryptoDescriptionPart1: 'Для оплаты напишите номер заказа в наш ',
        chatSupport: 'чат поддержки',
        cryptoDescriptionPart2: ' или ',
        telegram: 'телеграм',
        errorSelectPayment: 'Выберите способ оплаты или напишите нам в чат поддержки или телеграм (для криптовалюты)',
        rublesPaymentLabel: 'При оплате в рублях:',
        or: 'или',
        euroPaymentLabel: 'При оплате в евро:',
        securityHeader: 'Как не попасться на мошенников?',
        securityTextPart1: 'Уважаемые путешественники, напоминаем вам, что при оплате услуг важно проверять подлинность сайта (',
        kupitripLink: 'https://kupitrip.online/',
        securityTextPart2: ') и системы оплаты (',
        best2payLink: 'https://pay.best2pay.net/',
        and: ' и ',
        stripeLink: 'https://checkout.stripe.com/',
        securityTextPart3: '). Пожалуйста, будьте внимательны, когда вас просят перейти по ссылке для оплаты услуг или осуществить перевод по номеру телефона/карты. Мошенники могут таким способом украсть ваши деньги.',
        cautionText: 'Пожалуйста, будьте внимательны, когда вас просят перейти по ссылке для оплаты услуг или осуществить перевод по номеру телефона/карты. Мошенники могут таким способом украсть ваши деньги.',
        payButton: 'Оплатить',
        agreementTextPart1: 'Даю согласие на ',
        agreementLink1: 'обработку персональных данных',
        agreementTextPart2: ', согласен(а) с ',
        agreementLink2: 'тарифом',
        agreementTextPart3: ',',
        agreementLink3: ' условиями договора-оферты'
      },

      paymentModalAbout: {
        orderDetails: 'О заказе',
        orderNumber: 'Номер заказа',
        orderAmount: 'Сумма заказа',
        flightDirectionTo: 'Направление перелета, туда',
        departureDateTo: 'Дата вылета, туда',
        flightDirectionBack: 'Направление перелета, обратно',
        departureDateBack: 'Дата вылета, обратно',
        passengerCount: 'Кол-во пассажиров'
      },
      paymentModalHotelAbout: {
        buttonExpand: 'Развернуть',
        buttonCollapse: 'Свернуть',
        orderDetails: 'О заказе',
        orderNumber: 'Номер заказа',
        orderAmount: 'Сумма заказа',
        directionTo: 'Направление',
        departureDateTo: 'Дата вылета, туда',
        flightDirectionBack: 'Направление перелета, обратно',
        departureDateBack: 'Дата заезда и выезда',
        guestsCount: 'Кол-во гостей',
        variantsPay: 'Способы оплаты'
      },
      paymentModalPassengers: {
        hidePassengers: 'Скрыть пассажиров',
        showPassengers: 'Показать пассажиров'
      }
    },
    blogPost: {
      relatedArticles: 'Читайте также',
      share: 'Поделиться',
      latestNews: 'Последние новости:',
      viewAll: 'Смотреть все'
    },
    ticketDetails: {
      to: 'ТУДА',
      back: 'ОБРАТНО',
      direct: 'direct'
    },
    noResult: {
      title: 'Нет подходящих вариантов',
      subtitle: 'К сожалению, по выбранному вами направлению не найдено вариантов перелета. Попробуйте изменить даты и/или города вылета/прилета.'
    },
    payStub: {
      title: 'Страница оплаты',
      subtitle: 'Тут будет отображаться страница оплаты',
      buttonText: 'Назад'
    },
    passengersPage: {
      title: 'Пассажиров пока нет',
      subtitle: 'Создайте пассажиров, чтобы быстро добавлять их в бронирования',
      buttonText: 'На главную',
      addPassenger: 'Добавить пассажира',
      passengers: 'Пассажиры'
    },
    addPassengerPage: {
      addPassenger: 'Добавить пассажира',
      editPassenger: 'Редактировать пассажира',
      back: 'Назад'
    },
    login: {
      loginToAccount: 'Войти в личный кабинет',
      password: 'Пароль',
      register: 'Зарегистрироваться',
      login: 'Войти',
      withGoogle: 'С помощью Google',
      loginError: 'Ошибка входа',
      forgotPassword: 'Забыли пароль?'
    },
    ticket: {
      hideDetails: 'Скрыть детали',
      showDetails: 'Детали перелета',
      book: 'Забронировать',
      buy: 'Купить'
    },
    ordersPage: {
      orders: 'Заказы',
      noOrders: 'Заказов пока нет',
      subtitle: 'У вас нет купленных бронирований, перейдите на главную для поиска билета',
      buttonText: 'На главную'
    },
    partnershipPage: {
      partnershipLinks: 'Партнерские ссылки',
      accountSettings: 'Настройка кабинета'
    },
    orderDetailsPage: {
      orderDocuments: 'Документы заказа',
      passengers: 'Пассажиры'
    },
    buying: {
      title: 'Обратите внимание!',
      note: 'Вы переходите на сайт ПОКУПКИ авиабилета (билет может быть невозвратным), бронирование авиабилета вы можете совершить на предыдущей странице по кнопке "Забронировать"',
      return: 'Вернуться',
      consentToProceed: 'Даю согласие на переход на сайт покупки авиабилета',
      continue: 'Продолжить'
    },
    partnership: {
      steps: [
        {
          step: '1',
          mainText: 'Размещаете партнерскую ссылку',
          secondaryText: 'У себя на сайте/канале/блоге',
          img: '/images/partrnership1.png'
        },
        {
          step: '2',
          mainText: 'Пользователь переходит по ссылке и оформляет бронирование',
          img: '/images/partrnership2.png'
        },
        {
          step: '3',
          mainText: 'Вы получаете свою комиссию!',
          secondaryText: 'Комиссия составляет до ' + PARTNER_BONUS_ON_RUB + '₽ за каждого пассажира',
          img: '/images/partrnership3.png',
          accent: true
        }
      ],
      partners: [
        { name: 'Блогеры', description: '', icon: '/icons/profile.svg' },
        {
          name: 'Владельцы сайтов',
          description: 'Рекомендуемая тематика: туризм, путешествия, региональные порталы, форумы и т.д.)',
          icon: '/icons/world.svg'
        },
        {
          name: 'Владельцы групп и каналов в социальных сетях',
          description: 'Telegram, VK, Facebook, и т.д.',
          icon: '/icons/comment.svg'
        }
      ],
      possibilities: [
        {
          main: 'Отслеживание статистики',
          description: 'Вы получите возможность анализировать количество заказов, человек.',
          img: '/images/partnership_1.png'
        },
        {
          main: 'Создание партнерских ссылок',
          description: 'Создавайте несколько ссылок под разные цели и направления.',
          img: '/images/partrnership_new.png'
        },
        {
          main: 'Аналитика заказа',
          description: 'Отслеживайте популярные направления, кол-во пассажиров, дату заказа.',
          img: '/images/partrnership_analytics.png'
        },
        {
          main: 'Станьте партнером КупиТрип',
          description: 'Попробуйте все возможности личного кабинета и зарабатывайте прямо сейчас!',
          img: '/images/partnership_4.png',
          accent: true
        }
      ],
      partnerProgram: 'Партнерская программа',
      earningsFromFlightBookings: 'Зарабатывайте с бронирования авиабилетов до 80₽ за каждого пассажира!',
      becomePartner: 'Стать партнером',
      goToThePartnerProgram: 'Перейти в партнерскую программу',
      howItWorks: 'Как это работает?',
      whoCanBecomePartner: 'Кому пойдет партнерство?',
      opportunities: 'Возможности'
    },
    bookingNeedTicket: {
      statusChanged: 'Статус бронирования изменен',
      bookingWithTicketNumberOffer: 'Если на границе требуют билет с номером, предлагаем вам услугу оформления брони с номером электронного билета. Это выкупленный билет, который проверяется на сайте авиакомпании, имеет код брони и номер билета',
      caution1: '- Бронь необходимо оформлять за 1 день до момента предъявления либо день в день.',
      caution2: '- Срок действия билета - 48 часов.',
      caution3: '- Вы не оплачиваете стоимость билета.',
      pricePerPerson: 'Цена за 1 человека:',
      paymentInRubles: 'При оплате в рублях',
      paymentInEuros: 'При оплате в евро',
      bookWithTicketNumber: 'Оформить бронь с номером билета',
      bookingWithTicketNumber: 'Бронь с номером билета'
    },
    passengerBookingPrice: {
      adultBooking: 'Бронь взрослого',
      childBooking: 'Бронь ребенка',
      babyBooking: 'Бронь младенца'
    },
    bookingTicket: {
      noTicketSelected: 'Не выбран билет для бронирования'
    },
    bookingSidebar: {
      paymentInRubles: 'При оплате в рублях',
      or: 'или',
      paymentInEuros: 'При оплате в евро',
      airlineMayReduceTimeLimit: 'После бронирования авиакомпания может уменьшить тайм-лимит по авиабилетам в одностороннем порядке.',
      validFor7Days: 'Бронь действительна до 7 дней',
      amountDue: 'К оплате'
    },
    orderPaymentSuccess: {
      header: 'Заказ оплачен :)',
      text: 'Спасибо за ваш заказ! В течение 30 минут мы сформируем маршрутную квитанцию и отправим вам отдельным письмом.',
      passengers: 'Пассажиры'
    },
    successfulPayment: {
      header: 'Заказ оплачен :)',
      text: 'Спасибо за ваш заказ, ваучер бронирования отеля мы отправим на вашу электронную почту в течении 60 минут!',
      guests: 'Гости',
      sum: 'Сумма заказа',
      countGuests: 'Кол-во гостей',
      numberOrder: 'Номер заказа',
      dateOrder: 'Дата заказа',
      paid: 'Оплачен'
    },
    orderPaymentFail: {
      header: 'Заказ не оплачен :(',
      text: 'Произошла ошибка оплаты вашего заказа, попробуйте оплатить снова.',
      proceedToPayment: 'Перейти к оплате',
      toHomepage: 'На главную'
    },
    notSuccessfulPayment: {
      header: 'Заказ не оплачен :(',
      text: 'Произошла ошибка оплаты вашего заказа, попробуйте оплатить снова.',
      proceedToPayment: 'Перейти к оплате',
      toHomepage: 'На главную'
    },
    blogPosts: {
      title: 'Блог о путешествиях',
      all: 'Все'
    },
    badGateway: {
      title: 'Упс.. ведутся технические работы, попробуйте вернуться через пару минут',
      error: 'Ошибка 502'
    },
    flightArrow: {
      transfer: 'Пересадка'
    },
    suggestion: {
      noMatches: 'Нет совпадений'
    },
    orderStatus: {
      paymentFailed: 'Оплата не прошла',
      payed: 'Оплачено',
      waiting: 'Ожидает оплаты',
      expired: 'Просрочен'
    },
    orderPrice: {
      total: 'Сумма заказа'
    },
    orderPassengers: {
      numberOfPassengers: 'Кол-во пассажиров'
    },
    orderNumber: {
      orderNumber: 'Номер заказа'
    },
    orderDocument: {
      orderDocument: 'Документы бронирования',
      helpMessage: 'Документы с бронированием будут доступны для скачивание через 30 минут',
      download: 'Скачать'
    },
    orderDetails: {
      notUploaded: 'Билет не загружен',
      pay: 'Оплатить'
    },
    orderDate: {
      orderDate: 'Дата заказа'
    },
    orderCard: {
      more: 'Подробнее',
      pay: 'Оплатить'
    },
    previewOrder: {
      process: 'Оформить',
      cancel: 'Отменить',
      noOrderError: 'Не выбран заказ для просмотра'
    },
    bookingComment: {
      bookingComment: 'Комментарий к заказу'
    },
    help: {
      header: 'Помощь'
    },
    notFound: {
      title: 'Страница не найдена :(',
      subtitle: 'Попробуйте обновить страницу или вернуться на главную',
      buttonText: 'На главную'
    },
    flightRoute: {
      routes: 'Рейсы',
      route: 'Рейс',
      days: 'д',
      hours: 'ч',
      minutes: 'мин'
    },
    passengers: {},
    passwordData: {
      oldPassword: 'Старый пароль',
      passwordsDoNotMatch: 'Пароль не совпадает',
      newPassword: 'Новый пароль',
      confirmPassword: 'Повтор пароля',
      changePassword: 'Изменить пароль'
    },
    contactsData: {
      phone: 'Телефон',
      contactDetails: 'Контактные данные',
      loggedInWithGoogle: 'Вы вошли через Google'
    },
    formSubmit: {
      personalDataSaved: 'Персональные данные успешно сохранены'
    },
    personalForm: {
      alreadyTaken: 'Указанный адрес электронной почты принадлежит другому пользователю',
      save: 'Сохранить',
      failedToUpdatePasswordCheckOldPassword: 'Не удалось обновить пароль, проверьте старый пароль',
      passwordNotUpdatedCheckOldValidity: 'Пароль не обновлен, проверьте валидность старого!'
    },
    citizenship: {
      Russia: 'Россия',
      Belarus: 'Беларусь',
      Ukraine: 'Украина',
      Kazakhstan: 'Казахстан'
    },
    personalCitizenship: {
      popular: 'Популярное',
      allCountries: 'Все страны',
      noResults: 'Нет совпадений'
    },
    passengerType: {
      passengerType: 'Тип пассажира',
      adult: {
        label: 'Взрослый',
        description: 'От 12 лет'
      },
      child: {
        label: 'Ребенок',
        description: 'От 2 до 12 лет'
      },
      baby: {
        label: 'Младенец',
        description: 'До 2 лет, без места'
      }
    },
    passengerHeader: {
      passenger: 'пассажир',
      howToFillData: 'Как заполнить данные'
    },
    passengerForm: {
      add: 'Добавить',
      save: 'Сохранить',
      cancel: 'Отмена',
      delete: 'Удалить',
      added: 'Пассажир успешно создан',
      updated: 'Пассажир успешно обновлен'
    },
    passengerCard: {
      gender: 'Пол',
      male: 'Мужской',
      female: 'Женский',
      birth: 'День рождения',
      citizenship: 'Гражданство',
      passportId: 'Номер документа',
      edit: 'Редактировать',
      editPassenger: 'Редактировать пассажира',
      save: 'Сохранить'
    },
    placementInput: {
      enterLink: 'Введите корректную ссылку',
      required: 'Поле обязательно'
    },
    placementLinks: {
      successMessage: 'Места размещения успешно сохранены',
      errorMessage: 'Что-то пошло не так',
      maxEntries: 'Нельзя добавить больше 5 записей',
      placement: 'Место размещения',
      link: 'Ссылка',
      save: 'Сохранить',
      placements: 'Места размещения',
      addPlacement: 'Добавить место размещения',
      delete: 'Удалить'
    },
    register: {
      register: 'Регистрация'
    },
    contactDetails: {
      contactDetails: 'Контактные данные',
      contactDetailsDescription: 'На почту мы отправим документ “Подтверждение бронирования”',
      required: 'Поле обязательно',
      phone: 'Телефон'
    },
    securityAndReturnPolicy: {
      securityAndReturnPolicy: 'Политика безопасности и возврата',
      paymentSecurityPolicy: 'Политика безопасности платежей',
      paymentOptions: 'Оплатить заказ можно:',
      paymentOption1: '1. С помощью банковских карт международных платёжных систем Visa, MasterCard, МИР.',
      paymentOption2: '2. Через Систему Быстрых Платежей.',
      paymentOption3: '3. Yandex Pay.',
      paymentSecurityDescription: 'Приём платежей происходит через защищённое безопасное соединение, используя протокол TLS 1.2. Компания\n' +
        '          Best2pay соответствует международным требованиями PCI DSS для обеспечения безопасной обработки реквизитов\n' +
        '          банковской карты плательщика. Ваши конфиденциальные данные необходимые для оплаты (реквизиты карты,\n' +
        '          регистрационные данные и др.) не поступают в интернет-портал, их обработка производится на стороне\n' +
        '          процессингового центра Best2pay и полностью защищена. Никто, в том числе интернет-портал КУПИТРИП, не может\n' +
        '          получить банковские и персональные данные плательщика.',
      refundPolicy: 'Правила возврата',
      refundPolicyDescription: 'При отказе от услуги, оплаченная сумма за услугу, подлежит возврату в течение\n' +
        '          10 дней (ст 22 Закона РФ «О защите прав потребителей» N 2300-1 от 07.02- далее Закон № 2300-1).',
      attentionNotice: 'Обратите внимание!',
      serviceCancellationPolicy: 'Отказ от услуги возможен только до бронирования авиабилетов, отправленных на\n' +
        '          электронную почту. Возврат осуществляется на ту же банковскую карту, с которой была оплачена услуга.'
    },
    language: {
      header: 'Язык и валюта',
      language: 'Язык',
      currency: 'Валюта',
      russian: 'Русский',
      english: 'English',
      rubles: 'Российский рубль (RUB)',
      euros: 'Евро (EUR)',
      confirm: 'Подтвердить'
    },
    validation: {
      required: 'Поле обязательно',
      invalidDocumentNumber: 'Неверный номер документа',
      invalidLinkFormat: 'Тут должна быть ссылка',
      maxLength: 'Максимальная длина 255 знаков',
      minLength: 'Минимальная длина пароля 8 символов',
      useLatinLettersHyphenSpaceOnly: 'Используйте только латинские буквы, дефис или пробел',
      useLettersDotHyphenSpaceOnly: 'Используйте только буквы, точку, дефис или пробел',
      invalidPassportNumber: 'Неверный номер паспорта',
      FormainvalidEmailt: 'Некорректный формат электронной почты',
      invalidEmailFormat: 'Некорректный формат электронной почты',
      selectOneFromList: 'Выберите один пункт из списка',
      invalidBirthCertificateNumber: 'Неверный номер свидетельства о рождении',
      passwordMustContainLatinLettersNumbersAndSymbols: 'Пароль может состоять лишь из латинских букв разного регистра, цифр и символов',
      invalidDate: 'Неверная дата',
      invalidDateFormat: 'Неверный формат даты (используйте: дд.мм.гггг)',
      dateInFutureNotAllowed: 'Дата в будущем недопустима',
      documentExpiryDateMustBeAfterArrivalDate: 'Дата срока окончания документа должна быть больше даты прилета',
      invalidBirthYear: 'Неверный год рождения',
      dateCannotBeGreaterThan: 'Не может быть больше',
      dateCannotBeLessThan: 'Не может быть меньше',
      andCannotBeGreaterThan: 'и больше',
      dateInThePastNotAllowed: 'Дата в прошлом недопустима',
      onlyLatinCharactersAllowed: 'Допустимы только латинские символы',
      invalidPhoneNumberFormat: 'Некорректный формат номера телефона',
      stringMustStartWithLatinLetter: 'Строка должна начинаться с латинской буквы',
      stringCannotConsistOfSpaces: 'Строка не должна состоять из пробелов',
      cardNumberMustContainAtLeast16Characters: 'Номер карты содержит минимум 16 символов',
      minCostRub: 'Минимальная стоимость 1 000 ₽',
      minCostEur: 'Минимальная стоимость 10 €'
    },
    partnershipLandingPage: {
      partnershipProgramTitle: 'Партнерская программа КупиТрип',
      partnershipProgramSubtitle: 'Зарабатывайте с бронирования авиабилетов до {{amount}}₽ за каждого пассажира!',
      becomePartnerButton: 'Стать партнером',
      howItWorksTitle: 'Как это работает?',
      whoCanPartnerTitle: 'Кому пойдет партнерство?',
      opportunitiesTitle: 'Возможности'
    },
    rules: {
      offerAgreementTitle: 'Договор оферты',
      publicOfferTitle: 'Публичная оферта о заключении договора на оказание услуг бронирования авиабилетов и прочих услуг',
      offerText: 'Настоящая Оферта, является официальным публичным предложением «Купитрип» ИП Воронин А.Н ОГРН 322237500003197 (далее Агентство) заключить Договор между Клиентом и Агентством на оказание услуг бронирования авиабилетов и прочих услуг далее Договор) на Веб-сайте Агентства www.kupitrip.online. Договор считается заключенным и приобретает силу с момента совершения физическим лицом действий, предусмотренных офертой и означающих безоговорочное принятие всех условий оферты без каких-либо изъятий или ограничений, на условиях присоединения.',
      termsDefinitionsTitle: '1. Термины и определения',
      termsDefinitionsIntro: '1.1 В рамках настоящей Оферты нижеприведенные термины используются в следующем значении:',
      termOffer: '1.1.1 Оферта – адресованное любому физическому лицу предложение Агентства на заключение Договора на условиях, содержащихся в настоящей Оферте.',
      termPublication: '1.1.2 Опубликование – размещение текста настоящей Оферты в свободном доступе в сети Интернет по адресу: www.kupitrip.online/offerta',
      termAgency: '1.1.3 Агентство – «Купитрип» ИП Воронин А.Н ОГРН 322237500003197',
      termAgencyNotCarrier: '1.1.4 Агентство не является авиаперевозчиком, услуги Агентства носят посреднический характер. Агентство обязуется оказать Клиенту услуги по бронированию авиабилетов на регулярные рейсы перевозчиков и прочих услуг поставщиков, а Заказчик обязуется оплатить услуги Агентства по установленной стоимости в разделе Тарифы.',
      termClient: '1.1.5 Клиент (пользователь) – физическое, совершающее онлайн-бронирование на интернет-портале Агентства: www.kupitrip.online',
      termCarrier: '1.1.6 Перевозчик – авиакомпания эксплуатант, осуществляющий воздушные перевозки пассажиров, багажа, имеющий лицензию на осуществление подлежащего лицензированию в соответствии с законодательством Российской Федерации вида деятельности в области авиации, обеспечивающий услуги по перевозке пассажиров и багажа в пункт временного/постоянного пребывания.',
      termPassenger: '1.1.7 Пассажир – физическое лицо, заключившее договор воздушной перевозки пассажира, либо физическое лицо, в целях перевозки которого заключен договор фрахтования воздушного судна (воздушный чартер).',
      termContract: '1.1.8 Договор - заключаемый посредством акцепта оферты договор между Клиентом и Агентством на указанных ниже условиях, является официальным предложением (публичной офертой) и содержит все существенные условия по оказанию услуг на Веб-сайте Агентства www.kupitrip.online',
      termOrder: '1.1.9 Заказ – должным образом, оформленный на веб-сайте запрос Клиента на бронирование авиабилетов или прочих услуг, включающий сведения о маршруте перевозки, датах вылета/прилета, классе обслуживания, фамилии, имени, отчества клиента/ов, данные документов, удостоверяющих личность пассажиров, контакты. В случае оформления международной перевозки, имя и фамилия пассажира в латинской транскрипции должны точно соответствовать их написанию в загранпаспорте. Маршрут-квитанция электронного билета - часть электронного билета, содержащая установленные законодательством Российской Федерации сведения о перевозке пассажира и является документом, удостоверяющим заключение договора воздушной перевозки пассажира между авиакомпанией-перевозчиком и пассажиром.',
      termTimeLimit: '1.1.10 Тайм-лимит - срок выкупа авиабилета или отеля, устанавливается согласно правилам и распоряжениям Перевозчиков или Отеля. Перевозчики или отель оставляют за собой право на изменение тайм-лимита, аннуляции бронирования в любое время.',
      termClientAction: '1.1.11 Совершение Клиентом действий по заказу электронных документов, посредством интернет-ресурса www.kupitrip.online, электронной почты, свидетельствует о принятии Вами всех условий настоящего Договора-оферты. В соответствии с условиями использования нашего Сайта Вы подтверждаете, что Вам исполнилось 18 лет, Вы дееспособны, правоспособны, имеете законное право пользоваться банковской платежной картой и вступать в договорные отношения с Агентством. Вы берете на себя ответственность по обязательствам, что возникают в результате исполнения настоящего договора. Если Клиент не согласен с этими правилами и условиями, он не имеет права использовать настоящий интернет-портал в целях использования размещенной на нем системы бронирования.',
      contractSubjectTitle: '2. Предмет договора',
      contractSubjectAgencyObligation: '2.1 Агентство обязуется оказать Клиенту посреднические услуги по бронированию авиабилетов и прочих услуг.',
      contractSubjectAgencyObligationDependent: '2.2 Обязательство Агентства по обеспечению услуги бронирования, предусмотренное настоящим Договором, во всех случаях зависит от фактического наличия мест у Поставщиков.',
      contractSubjectAgencyObligationCompleted: '2.3 С момента получения Клиентом оформленных документов по электронной почте, обязательства Агентства перед Клиентом считаются исполненными, а услуга - оказанной. В случае отказа Клиентом от оказанной услуги, оплаченные денежные средства не подлежат возврату.',
      contractSubjectAgencyResponsibility: '2.4 Агентство не несет ответственность за решение консульством/посольством о выдачи визы или гарантированного прохождения границы в стране вылета/прилета.',
      bookingPaymentOrderTitle: '3. Порядок бронирования и оплаты',
      bookingPaymentOrderAgencyObligation: '3.1 Обязательства Агентства по бронированию распространяются на подтвержденные и не отмененные Клиентом Заказы, содержащие информацию, доводимую Клиентом до Агентства на интернет-портале www.kupitrip.oline путем самостоятельного заполнения формы Заказа. Заказ Клиента должен содержать следующие сведения: маршрут перевозки, даты вылета/прилета, класс обслуживания, фамилию, имя, отчество, пол клиентов/пассажиров (в случае оформления международной перевозки, имя и фамилия пассажира в латинской транскрипции должны точно соответствовать их написанию в загранпаспорте), данные документов, удостоверяющих личность пассажиров, контакты для обратной связи с Клиентом: телефон, электронная почта или иное средство оперативной связи.',
      bookingPaymentOrderClientAcceptance: '3.2 Заполнение формы Заказа онлайн является акцептом настоящего Договора со стороны Клиента. Заказ является указанием Клиента для выполнения поручения и содержит в себе информацию, указанную в п. 3.1. настоящего Договора.',
      bookingPaymentOrderConfirmation: '3.3 После получения оплаты, Агентство высылает на адрес электронной почты Клиента оформленные документы.Бронирование билета оформляется на основании данных документа, удостоверяющего личность пассажира в соответствии с законодательством Российской Федерации или международным договором Российской Федерации для перевозки пассажира по маршруту, предусмотренному договором воздушной перевозки пассажира. Агентство считается исполнившим свои обязательства в момент отправки на электронную почту Клиента маршрутной квитанции.',
      agencyObligationsTitle: '4. Обязанности Агентства',
      agencyObligationsBooking: '4.1 Агентство обязуется забронировать авиабилет или прочую услугу согласно подтвержденному Заказу Клиента (за исключением случаев отмены Заказа).',
      clientObligationsTitle: '5. Обязанности Клиента',
      clientObligationsPayment: '5.1 Произвести своевременную оплату и предоставить достоверные и точные сведения, необходимые для бронирования авиабилетов или прочих услуг, указанные в пункте 3.1.',
      clientObligationsContactInfo: '5.2 Предоставить Агентству точную информацию о своем электронном адресе и телефоне, необходимую Агентству для оперативной связи с Клиентом.',
      clientObligationsInform: '5.3 Ознакомить всех пассажиров (в случае оформления заказа в интересах третьих лиц) с содержанием настоящего Договора и со всей информацией, предоставленной Агентством Клиенту, обеспечить выполнение ими всех обязанностей Клиента по Договору. Клиент гарантирует наличие у себя полномочий на осуществление сделки в интересах иных лиц.',
      offerValidityTitle: '6. Срок действия и изменение условий оферты',
      offerValidityEffective: '6.1 Оферта вступает в силу с момента опубликования на сайте Агентства в сети интернет, по адресу www.kupitrip.online и действует до момента отзыва Оферты Агентством.',
      offerValidityChanges: '6.2 Агентство оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. В случае внесения Агентством изменений в Оферту, такие изменения вступают в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен дополнительно при их публикации.',
      contractDurationTitle: '7. Срок действия, изменение и расторжение договора',
      contractDurationEffective: '7.1 Договор вступает в силу с момента Акцепта Оферты и действует до момента исполнения Агентством обязательств по бронированию услуги и отправления документов на электронную почту Клиента.',
      contractDurationTermination: '7.2 Настоящий Договор может быть расторгнут по взаимному согласию Сторон или по иным основаниям, предусмотренным действующим законодательством РФ',
      contractDurationCancellation: '7.3 Отказ от услуги возможен только до бронирования авиабилетов, отправленных на электронную почту.',
      otherConditionsTitle: '8. Прочие условия',
      otherConditionsGoverningLaw: '8.1 Все вопросы, не урегулированные Офертой или Договором, регламентируются действующим законодательством Российской Федерации. Споры и разногласия, которые могут возникнуть при исполнении настоящего Договора, будут по возможности разрешаться путем переговоров между сторонами Договора и в претензионном порядке. Претензионный порядок рассмотрения спора является обязательным. Срок ответа на претензию устанавливается в соответствии с действующим законодательством. В случае не урегулирования разногласий спор подлежит рассмотрению в суде с применением права РФ.',
      otherConditionsIntegrity: '8.2 Целостность Договора. Агентство не принимает на себя никаких условий и обязательств в отношении предмета Оферты (Заказа), не указанных в Оферте. Исключение могут составлять случаи, когда такие условия или обязательства зафиксированы в письменном виде и подписаны Агентством и Заказчиком.',
      otherConditionsInvalidity: '8.3 Последствия признания условий оферты недействительными. Если какое-либо из условий Оферты признано судом недействительным или является ничтожным, данный факт не влияет на недействительность Договора.',
      otherConditionsPersonalData: 'Клиенту разъяснены и понятны права субъекта персональных данных. ' +
        'Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо ' +
        'пунктом оферты, Вы вправе отказаться от услуг Агентства и не совершать действий, указанных в настоящей ' +
        'Оферте.'
    },
    personalData: {
      personalData: 'Персональные данные',
      surnameLabel: 'Фамилия (на латинице)',
      nameLabel: 'Имя (на латинице)',
      patronymicLabel: 'Отчество',
      noPatronymicLabel: 'Нет отчества',
      birthDateLabel: 'Дата рождения',
      genderLabel: 'Пол',
      male: 'Мужской',
      female: 'Женский'
    },
    bookingHeader: {
      backToSearch: 'Вернуться к поиску'
    },
    flightTag: {
      direct: 'Прямой'
    },
    flightTransfer: {
      transfer: 'Пересадка'
    },
    scrollStories: {
      moreDetails: 'Подробнее'
    },
    rangeDatePicker: {
      oneWayOnly: 'Только в одну сторону'
    },
    partnershipLinks: {
      title: 'Партнерские ссылки',
      newLinkButton: 'Новая ссылка',
      minPayment: 'Минимальная сумма выплат по партнерской программе составляет 1000 рублей. По условиям партнерской программы - за каждого оформленного пассажира в заказе - вы получаете до {{amount}} рублей.',
      passengers: 'Пассажиры',
      orders: 'Заказы',
      income: 'Доход',
      expectedPayout: 'Ожидаемая сумма выплаты в конце месяца',
      loading: 'Идет загрузка...',
      noLinksTitle: 'Нет созданных ссылок',
      noLinksSubtitle: 'Создайте первую ссылку что бы начать пользоваться партнерской программой',
      sortOptions: {
        allTime: 'Все время',
        today: 'Сегодня',
        sevenDays: '7 дней',
        thirtyDays: '30 дней'
      }
    },
    partnershipLinkDetails: {
      linkCopied: 'Ссылка успешно скопирована в буфер обмена',
      loading: 'Идет загрузка...',
      noLinkData: 'Нет данных по ссылке',
      copyLink: 'Скопировать ссылку',
      deleteLink: 'Удалить ссылку',
      passengers: 'Пассажиры',
      orders: 'Заказы',
      income: 'Доход',
      noCurrentOrdersTitle: 'Нет текущих заказов',
      noCurrentOrdersSubtitle: 'За данный промежуток времени по ссылке не оформлены заказы'
    },
    ordersLinksTable: {
      sort: 'Сортировать',
      passengers: 'Пассажиры',
      date: 'Дата оформления',
      income: 'Доход',
      apply: 'Применить',
      descending: 'По убыванию',
      ascending: 'По возрастанию',
      direction: 'Направление'
    },
    linksTable: {
      sort: 'Сортировать',
      descending: 'По убыванию',
      ascending: 'По возрастанию',
      apply: 'Применить',
      orders: 'Заказы',
      passengers: 'Пассажиры',
      income: 'Доход'

    },
    stepCard: {
      step: 'шаг'
    },
    personalDocument: {
      passportData: 'Данные паспорта',
      citizenship: 'Гражданство',
      document: 'Документ',
      passportNumber: 'Номер документа',
      validity: 'Срок действия',
      hasValidity: 'Срок действия',
      foreignPassportRF: 'Заграничный паспорт РФ',
      foreignPassport: 'Иностранный документ',
      internalPassportRF: 'Внутренний паспорт РФ'
    },
    profileMenu: {
      partnerUser: 'Пользователь-партнер',
      personalData: 'Персональные данные',
      orders: 'Заказы',
      passengers: 'Пассажиры',
      partnershipProgram: 'Партнерская программа',
      logout: 'Выйти'
    },
    confidentialPolicy: {
      processingPersonalData: 'Обработка персональных данных',
      generalProvisions: '1. Общие положения',
      policyText1: 'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных \'Купитрип\' ИП Воронин А.Н ОГРН 322237500003197 (далее – Оператор).',
      policyText2: 'Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://kupitrip.online.',
      termsDefinitions: '2. Основные понятия, используемые в Политике',
      automatedProcessing: 'Автоматизированная обработка персональных данных',
      automatedProcessingDescription: 'обработка персональных данных с помощью средств вычислительной техники',
      blockingData: 'Блокирование персональных данных',
      blockingDataDescription: 'временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных)',
      website: 'Веб-сайт',
      websiteDescription: 'совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://kupitrip.online',
      infoSystem: 'Информационная система персональных данных',
      infoSystemDescription: 'совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств',
      dataAnonymization: 'Обезличивание персональных данных',
      dataAnonymizationDescription: 'действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных',
      dataProcessing: 'Обработка персональных данных',
      dataProcessingDescription: 'любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных',
      operator: 'Оператор',
      operatorDescription: 'государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными',
      personalData: 'Персональные данные',
      personalDataDescription: 'любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://kupitrip.online',
      user: 'Пользователь',
      userDescription: 'любой посетитель веб-сайта https://kupitrip.online',
      dataProvision: 'Предоставление персональных данных',
      dataProvisionDescription: 'действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц',
      dataDistribution: 'Распространение персональных данных',
      dataDistributionDescription: 'любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом',
      crossBorderTransfer: 'Трансграничная передача персональных данных',
      crossBorderTransferDescription: 'передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу',
      dataDestruction: 'Уничтожение персональных данных',
      dataDestructionDescription: 'любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных',
      dataProcessingPurpose: '3. Оператор может обрабатывать следующие персональные данные Пользователя',
      dataProcessingPurposeText1: 'Фамилия, имя, отчество',
      dataProcessingPurposeText2: 'Электронный адрес',
      dataProcessingPurposeText3: 'Номера телефонов',
      dataProcessingPurposeText4: 'Год, месяц, дата и место рождения',
      dataProcessingPurposeText5: 'Реквизиты документа, удостоверяющего личность',
      dataProcessingPurposeText6: 'Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других)',
      dataProcessingPurposeText7: 'Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные',
      dataProcessingPurposeText8: 'Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем',
      dataProcessingPurposeText9: 'Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях',
      dataProcessingPurposeText10: 'Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты info@kupitrip.online с пометкой «Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях»',
      dataProcessingPurposeText11: 'Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания',
      legalBasis: '5. Правовые основания обработки персональных данных',
      legalBasisText1: 'Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте https://kupitrip.online.',
      legalBasisText2: 'Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.',
      legalBasisText3: 'Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).',
      processingOrder: '6. Порядок сбора, хранения, передачи и других видов обработки персональных данных',
      processingOrderText1: 'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.',
      processingOrderText2: 'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
      processingOrderText3: 'Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.',
      processingOrderText4: 'В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора info@kupitrip.online с пометкой «Актуализация персональных данных».',
      processingOrderText5: 'Срок обработки персональных данных является неограниченным.',
      processingOrderText6: 'Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@kupitrip.online с пометкой «Отзыв согласия на обработку персональных данных».',
      crossBorderTransferText1: 'Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.',
      crossBorderTransferText2: 'Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.',
      finalProvisions: '8. Заключительные положения',
      finalProvisionsText1: 'Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты info@kupitrip.online.',
      finalProvisionsText2: 'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.',
      finalProvisionsText3: 'Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://kupitrip.online/confedential_policy'
    },
    searchTypeEnum: {
      eco: 'Эконом',
      biz: 'Бизнес'
    },
    passengersDropdown: {
      adults: 'Взрослые',
      adultsDescription: '12 лет и старше',
      children: 'Дети',
      childrenDescription: 'От 2 до 12 лет',
      babies: 'Младенцы',
      babiesDescription: 'До 2 лет, без места'
    },
    loginCard: {
      signIn: 'Авторизуйтесь',
      loginToAccount: 'Вход в личный кабинет',
      buttonLogin: 'Вход'
    },
    partnershipLinksTable: {
      name: 'Название/ссылка',
      ordersTotal: 'Заказы',
      passengersTotal: 'Пассажиры',
      incomeTotal: 'Доход'
    },
    passengerTypes: {
      adult: 'взрослый',
      child: 'ребенок',
      baby: 'младенец'
    },
    images: {
      price: '/images/3png@3x2.png',
      search: '/images/1png@3x.png',
      passengerInfo: '/images/2png@3x.png',
      pdf: '/images/1png1@2x.png',
      telegram: '/images/1png3@3x.png',
      kupitripLogo: '/icons/kupitrip_logo.svg',
      statusPay: '/icons/status-pay.svg',
      fillInHotel: '/images/image-second-step.png',
      costPriceHotel: '/images/image-thirth-step-cost.png'
    },
    titles: {
      bookingAirlineTicketsForVisa: 'Бронирование авиабилетов для визы',
      fareAndPriceForKupiTripBooking: 'Тариф и цена на бронирование купитрип/ купитрип бронь/',
      howMuchDoKupiTripServicesCost: 'Сколько стоят услуги КупиТрип?',
      kupiTripBookingReviews: 'КупиТрип бронирование, отзывы'
    },
    app: {
      createPassword: {
        header: 'Создать пароль',
        text: 'Регистрация почти завершена, осталось создать пароль для аккаунта',
        button: 'Создать'
      },
      changePassword: {
        header: 'Сменить пароль',
        text: 'Введите новый пароль',
        button: 'Сохранить'
      },
      invalidLink: {
        header: 'Неверная ссылка',
        text: 'Некорректная ссылка. Получите ссылку заново'
      }
    },
    metaDescriptions: {
      bookingAirlineTicketsForVisa: 'Бронирование Авиабилетов для визы без оплаты билета. Бронь билета без оплаты за 1190 рублей. Официальная бронь. Онлайн бронирование билетов для визы',
      fareAndPriceForKupiTripBooking: 'Тариф и цена на бронирование купитрип/ купитрип бронь/',
      kupiTripBookingReviews: 'Отзывы о сервисе купитрип. Бронирование авиабилетов купитрип отзывы.',
      frequentlyAskedQuestionsKupiTrip: 'Популярные вопросы к сервису купитрип. Сколько стоят услуги купитрип. Можно ли вернуть бронь купитрип? Официальная бронь.'
    }
  }
};
