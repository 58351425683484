import {FunctionComponent, useState} from 'react';
import FilterItem from '../FilterItem/FilterItem';
import FlightTimeFilter from './FlightTimeFilter';
import { useSelector } from 'react-redux';
import { searchSelector } from '../../../store/slices/search';
import {Airport, City} from "../../../types/ticket";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface Props {
  search_airports: any;
  from: Airport | City;
  to: Airport | City;
  oneway: boolean;
  onChange?: any;
}

const FilterDepartureArrival: FunctionComponent<Props> = ({ search_airports, from, to, oneway, onChange }) => {
  const {
    time_trip_from_min,
    time_trip_from_max,
    time_trip_to_min,
    time_trip_to_max,
    time_back_from_min,
    time_back_from_max,
    time_back_to_min,
    time_back_to_max
  } = useSelector(searchSelector);

  const { t } = useTranslation();
  const handleChangeTo = (name: string, newValue: number | number[]) => {
    if (typeof newValue === 'number' || !name) return;
    onChange(null, name.includes(t('filter.departureFrom'))  ? 'time_trip_to' : 'time_trip_from', newValue);
  };
  const handleChangeFrom = (name: string, newValue: number | number[]) => {
    if (typeof newValue === 'number' || !name) return;
    onChange(null, name.includes(t('filter.departureFrom')) ? 'time_back_to' : 'time_back_from', newValue);
  };
  const isRussianLanguage = i18n.language.startsWith('ru');


  return (from && to) ? (
    <FilterItem name={t('filter.departureAndArrival')}>
      <FlightTimeFilter
        direction={t('filter.wayTo')}
        departureLabel={
          t('filter.departureFrom') + (
            isRussianLanguage ?
              (from?.cases?.dative ?? search_airports[from.code]?.cases?.dative ?? from.city ?? from.name ?? 'NULL')
              :
              (from.city_en ?? from.name_en ?? 'NULL')
          ).replace(/[`~!@#$%^*()_|+=?;:'",.<>/]/gi, '')
      }
        departure={[time_trip_to_min, time_trip_to_max]}
        arrivalLabel={t('filter.arrivalTo') + (
          isRussianLanguage ?
            (to.city ?? to.name ?? 'NULL')
            :
            (to.city_en ?? to.name_en ?? 'NULL')
        ).replace(/[`~!@#$%^*()_|+=?;:'",.<>/]/gi, '')
      }
        arrival={[time_trip_from_min, time_trip_from_max]}
        onChange={handleChangeTo}
      />
      {!oneway && <FlightTimeFilter
        direction={t('filter.wayReturn')}
        departureLabel={t('filter.departureFrom') + (
          isRussianLanguage ?
            (to?.cases?.dative ?? search_airports[to.code]?.cases?.dative ?? to.city ?? to.name ?? 'NULL')
            :
            (to.city_en ?? to.name_en ?? 'NULL')
        ).replace(/[`~!@#$%^*()_|+=?;:'",.<>/]/gi, '')}
        departure={[time_back_to_min, time_back_to_max]}
        arrivalLabel={t('filter.arrivalTo') + (
          isRussianLanguage ?
            (from.city ?? from.name ?? 'NULL')
            :
            (from?.city_en ?? from?.name_en ?? 'NULL')
        ).replace(/[`~!@#$%^*()_|+=?;:'",.<>/]/gi, '')}
        arrival={[time_back_from_min, time_back_from_max]}
        onChange={handleChangeFrom}
      />}
    </FilterItem>
  ) : null;
};

export default FilterDepartureArrival;
