import { FunctionComponent, RefObject, useCallback } from 'react';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { Box } from '@mui/system';

import styles from './GuestsDropdown.module.scss';
import NumberInput from './NumberInput';
import TabButtons from './TabButtons';
import { Fade } from '@mui/material';
import { useAppDispatch } from '../../../store/store';
import {searchSelector, SearchTypeEnum, setPassengers, setType} from '../../../store/slices/search';
import { useSelector } from 'react-redux';
import { searchHotelSelector, setGuests } from '../../../store/slices/searchHotels';
import { useTranslation } from 'react-i18next';

export interface PassengersDropdownProps {
  anchorRef: RefObject<HTMLElement>;
  open: boolean;
  id: string;
}

const getValue = (adults: number, children: number, babies: number) => adults + children + babies;

const GuestsDropdown: FunctionComponent<PassengersDropdownProps> = ({ anchorRef, open, id }) => {
  const dispatch = useAppDispatch();
  // const { adults, children, babies, type } = useSelector(searchSelector);
  const {t}= useTranslation()
  const { persons_count } = useSelector(searchHotelSelector)

  const handleChange = useCallback((n: number, name: string) => {
    dispatch(setGuests(n));
  }, []);


  const options = Object.entries(SearchTypeEnum).map(([key, value]) => {
    return {
      'value': key,
      'label': value
    }
  });

  return open ? (
    <PopperUnstyled
      modifiers={[
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'flip', options: { fallbackPlacements: ['bottom-start'] } },
      ]}
      anchorEl={anchorRef.current}
      disablePortal
      id={id}
      open={open}
      className={styles.dropdown}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box className={styles.box}>
            <NumberInput
              name="adults"
              onChange={handleChange}
              label={t('guestSelect.label')}
              description={t('guestSelect.description')}
              value={persons_count}
              min={1}
              max={persons_count < 5 ? (persons_count + 1) : persons_count}
            />
          </Box>
        </Fade>
      )}
    </PopperUnstyled>
  ) : null;
};

export default GuestsDropdown;
