import {useSelector} from 'react-redux';
import { storiesSelector, setStories } from '../../../store/slices/stories';
import StoryCard from '../StoryCard/StoryCard';
import styles from './StoriesList.module.scss';
import {useGetStoriesQuery} from "../../../store/api/storiesService";
import {userSelector} from "../../../store/slices/user";
import { Story } from '../../../types/stories';
import {useEffect} from "react";
import {useAppDispatch} from "../../../store/store";

type Props = {
  hotels? : boolean
};

const StoriesList = ({hotels}: Props) => {
  const dispatch = useAppDispatch();
  const { token } = useSelector(userSelector);
  const { data: objects } = useGetStoriesQuery({ token });

  useEffect(() => {
    dispatch(setStories({ stories: objects?.data ? objects.data : [] }))
  }, [ objects ])

  const { stories } = useSelector(storiesSelector);

  return (
    <div className={`${styles.stories}  ${hotels? styles.hotels: ''}`}>
      {stories.length && stories.map(story => (
        <StoryCard key={story.id} story={story} />
      ))}
    </div>
  );
};

export default StoriesList;
