import { Controller, useFormContext } from 'react-hook-form';
import styles from './BokingWishCost.module.scss';
import { useTranslation } from 'react-i18next';
import { maxLength,minCostEur, validateMinCostEur, validateMinCostRub } from '../../utils/formValidation';
import { useAppDispatch } from '../../store/store';
import Card from '../Card/Card';
import Input from '../Input/Input';
import { setWishCost } from '../../store/slices/searchHotels';
import i18n from 'i18next';


const BokingWishCost = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');

  const rules = {
    maxLength,
    validate: currency === 'rub' ? validateMinCostRub : validateMinCostEur
  };


  const {
    control,
    formState: { errors }
  } = useFormContext();


  const formatNumber = (event: any, field: any) => {
    const value = event.target.value;
    const num = parseInt(value
        .replace(/\D/g, '')
        .replace(/ +/g, ' ')
      , 10);
    if (isNaN(num)) return '';
    const formatted = currency === 'rub'
      ? `${t('placeOrderHotelPage.bokingWishCost.upTo')} ₽ ${num.toLocaleString('ru-RU')}`
      : `${t('placeOrderHotelPage.bokingWishCost.upTo')} € ${num.toLocaleString('ru-RU')}`;

    field.onChange(formatted);
    return formatted;
  };


  return (
    <Card>
      <div className={styles.header}>{t('placeOrderHotelPage.bokingWishCost.header')}</div>
      <Controller
        name="wish_cost"
        control={control}
        rules={rules}
        render={({ field }) =>
          <Input
            {...field}
            hotels={true}
            type="text"
            label={t('placeOrderHotelPage.bokingWishCost.label')}
            error={errors['wish_cost']?.message as string}
            onChange={(e) => {
              const formattedValue = formatNumber(e, field);
              dispatch(setWishCost(formattedValue));
            }}
          />
        }
      />
    </Card>
  );
};

export default BokingWishCost;
