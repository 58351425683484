import { FunctionComponent, ReactNode } from 'react';
import styles from './SelectOptionDetail.module.scss';
import OptionUnstyled from '@mui/base/OptionUnstyled';

type SelectOptionDetailType = {
  value: string;
  label: ReactNode;
  code?: string;
};

const SelectOptionDetail: FunctionComponent<SelectOptionDetailType> = ({ code, label, value }) => {
  return (
    <OptionUnstyled value={value} className={styles.root}>
      <img className={styles.icon} alt="" src="/icons/plane.svg" />
      <div className={styles.title}>
        <div className={styles.label}>{label}</div>
      </div>
      {code ? <div className={styles.code}>{code}</div> : null}
    </OptionUnstyled>
  );
};

export default SelectOptionDetail;
