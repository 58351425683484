import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { addDays, addMonths, addYears, format, isPast, isSameDay, startOfDay, startOfToday, subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import { enUS, ru } from 'date-fns/locale';

import { Box, ClickAwayListener, Fade, TextField } from '@mui/material';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateView } from '@mui/x-date-pickers';

import styles from './RangeDatePickerHotels.module.scss';
import { dateRangeSelectorHotels, setTo, setFromTo, setFrom } from '../../../store/slices/datePickerHotels';
import { setDateEnd, setDateStart } from '../../../store/slices/searchHotels';
import { useAppDispatch } from '../../../store/store';

import Button from '../../Button';
import useIsMobile from '../../../utils/useIsMobile';
import { capitalize, getNormalDate, isBetween } from '../../../utils/formatting';
import CustomPickersDayHotels from './CustomPickersDayHotels';
import DatePickerHeader from './CustomDatePickerHeader';
import CustomDatePickerHotels from './CustomDatePickerHotels';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface RangeDatePickerProps {
  labelTo: string;
  labelFrom: string;
  error?: string;
  clearError?: () => void;
  hotels?: boolean;
}

const MINIMAL_WIDTH = 740;

const RangeDatePickerHotels: FC<RangeDatePickerProps> = ({ labelTo, labelFrom, error, clearError, hotels }) => {
  const dispatch = useAppDispatch();
  const buttonsRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile(MINIMAL_WIDTH);
  const { t } = useTranslation();

  const { from, to } = useSelector(dateRangeSelectorHotels);
  const [currentOpen, setCurrentOpen] = useState<'from' | 'to' | null>(null);
  const [currentViewDate, setCurrentViewDate] = useState<Date>(addDays(new Date(), 1));
  /**
   * actually `currentView` must be a single DateView and assingned to `view`,
   * but controlling `view` prop does not affect view change somehow.
   * Looks like a bug in MUI. So here we control avalible views directly
   */
  const [currentView, setCurrentView] = useState<DateView[]>(['day']);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  const id = 'transition-popper';

  useEffect(() => {
    if (currentOpen) return;
    const refDate = from || to || addDays(new Date(), 1);
    setCurrentViewDate(new Date(refDate));
    setCurrentView(['day']);
    setHoveredDate(null);
  }, [currentOpen]);


  const renderWeekPickerDay = (props: PickersDayProps<Date>) => {
    const day = props.day;
    const onMouseEnter = () => setHoveredDate(isMobile ? null : day);
    const isSelected = !!(day ? [from, to].find(d => (d ? isSameDay(d, day) : null)) : false);
    const isDayInPast = isPast(day);
    const isDayDisabled = day.getTime() >= addYears(new Date(), 1).getTime();
    const getIsBetween = () => {
      if (!to && from && hoveredDate) return isBetween(from, hoveredDate.getTime(), day);
      if (!from && to && hoveredDate) return isBetween(to, hoveredDate.getTime(), day);
      if (from && to) {
        if (isBetween(from, to, day)) return true;
        const refDate = currentOpen === 'to' ? from : to;
        return !!hoveredDate && isBetween(refDate, hoveredDate.getTime(), day);
      }
      return false;
    };
    const isDayBetween = getIsBetween();

    const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
      /**
       have to call *handleChange* explictly because
       when clicked date in StaticDatePicker is the same as StaticDatePicker.value -
       no update is called and *handleChanged* is not called either.
       Now *handleChanged* is not passed into StaticDatePicker.onChange prop,
       but passing it does'n change this behaviour.
       We have to pass value as a day of shown month or StaticDatePicker won't update.
       DefaultValue does not update view when month is changed
       */
      handleChange(day);
    };

    return (
      <CustomPickersDayHotels
        {...props}
        onClick={handleClick}
        className={isDayBetween ? styles.dayBetween : ''}
        onMouseEnter={onMouseEnter}
        disabled={isDayInPast || isDayDisabled}
        selected={isSelected}
        isdaybetween={!!isDayBetween}
        disableHighlightToday={true}
      />
    );
  };

  const handleChange = (newValue: Date | null) => {
    let value = null;
    if (!newValue) value = null;
    else if (startOfDay(newValue) <= startOfToday()) value = addDays(startOfDay(newValue), 1).getTime();
    else value = startOfDay(newValue).getTime();
    if (from && value && isSameDay(from, value)) {
      setCurrentOpen(to ? 'to' : 'from');
      dispatch(setFromTo({ from: to, to: null }));
      dispatch(setDateStart({ dateStart: to }));
    } else if (to && value && isSameDay(to, value)) {
      dispatch(setTo({ to: null }));
      dispatch(setDateEnd({ dateEnd: null }));
      setCurrentOpen('to');
    } else if (currentOpen === 'from') {
      dispatch(setFromTo({ from: value, to: null }));
      dispatch(setDateStart({ dateStart: value }));
      setCurrentOpen('to');
    } else if (currentOpen === 'to') {
      dispatch(setTo({ to: value }));
      dispatch(setDateEnd({ dateEnd: value }));
      setCurrentOpen(null);
    }
  };

  // TODO: Удалить старый модуль календаря и переписать на новый
  const currentDate = getNormalDate(new Date());

  const minDate = addDays(currentDate, 1);
  const maxDate = subMonths(addYears(minDate, 1), 1);

  const minDate2 = addMonths(minDate, 1);
  const maxDate2 = subMonths(addYears(minDate2, 1), 1);

  const controlDate = subMonths(maxDate, isMobile ? 1 : 2);

  const setDisabledRightButton = (state: boolean) => {
    const find = document.querySelector('[class*=RangeDatePicker_CalendarHeader]');
    if (find && find?.childNodes.length > 0) {
      find.childNodes[find.childNodes.length - 1]?.firstChild?.parentElement?.toggleAttribute('disabled', state);
    }
  };
  const onFirstMonthChange = (newMonth: Date) => {
    if (newMonth.getTime() > controlDate.getTime()) {
      setCurrentViewDate(getNormalDate((isMobile ? maxDate : subMonths(maxDate, 1))));
      setDisabledRightButton(true);
    } else {
      setDisabledRightButton(false);
    }
  };

  const handleOpen = () => {
    clearError && clearError();
    if (from && to) setCurrentOpen('from');
    else if (from) setCurrentOpen('to');
    else setCurrentOpen('from');
  };
  const handleClose = () => {
    setCurrentOpen(null);
  };

  const locale = i18n.language.startsWith('ru') ? ru : enUS;


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <ClickAwayListener onClickAway={handleClose}>
        <div className={styles.DatePickerWrapper}>
          <div className={clsx(styles.DatePicker, error ? styles.withError : '')} ref={buttonsRef} aria-describedby={id}
               style={{ 'content': error }}>
            <TextField
              focused={currentOpen === 'from' || !!error}
              onClick={handleOpen}
              onFocus={handleOpen}
              sx={{ p: 0, width: '100%' }}
              value={from ? format(from, 'dd MMMM', { locale: locale }) : ''}
              variant="filled"
              classes={{ root: styles.root }}
              label={labelFrom}
              InputProps={{
                readOnly: true,
                sx: hotels ? {
                  '&.Mui-focused': {
                    border: error === '' ? '1px solid var(--purple-6100FF) !important' : '1px solid var(--red-ff3d00) !important'
                  }
                } : {}
              }}
            />
            <span className={clsx(styles.withErrorPlaceholder)}>{error}</span>
            <div className={styles.divider}></div>
            <div className={styles.seconDateContainer}>
              <TextField
                focused={currentOpen === 'to' || !!error}
                onClick={handleOpen}
                onFocus={handleOpen}
                sx={{ p: 0, width: '100%' }}
                value={to ? format(to, 'dd MMMM', { locale: locale }) : ''}
                variant="filled"
                classes={{ root: styles.root }}
                label={labelTo}
                InputProps={{
                  readOnly: true,
                  sx: hotels ? {
                    '&.Mui-focused': {
                      border: error === '' ? '1px solid var(--purple-6100FF) !important' : '1px solid var(--red-ff3d00) !important'
                    }
                  } : {}
                }}
              />
              <span className={clsx(styles.withErrorPlaceholderEnd)}>{error}</span>
            </div>
          </div>

          {currentOpen ? (
            <PopperUnstyled
              modifiers={[
                { name: 'offset', options: { offset: [0, 10] } },
                { name: 'flip', options: { fallbackPlacements: ['bottom-start'] } }
              ]}
              slotProps={{
                root: {
                  className: styles.paperRoot,
                  style: isMobile ? { width: document.body.scrollWidth } : {}
                }
              }}
              className={styles.paper}
              id={id}
              open={Boolean(currentOpen)}
              anchorEl={isMobile ? null : buttonsRef.current}
              placement="bottom-start"
              disablePortal
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Box className={styles.popperBox}>
                    <div className={styles.DatePickerPopperWrapper}>
                      <div className={styles.DatePicker} aria-describedby={id}>
                        <TextField
                          sx={{ p: 0, width: '100%', border: 'none' }}
                          value={from ? format(from, 'dd MMMM', { locale: locale }) : ''}
                          variant="filled"
                          classes={{ root: styles.root }}
                          label={labelFrom}
                          inputProps={{ readOnly: true }}
                        />
                        <div className={styles.divider}></div>
                        <TextField
                          sx={{ p: 0, width: '100%', border: 'none' }}
                          value={to ? format(to, 'dd MMMM', { locale: locale }) : ''}
                          variant="filled"
                          classes={{ root: styles.root }}
                          label={labelTo}
                          inputProps={{ readOnly: true }}
                        />
                      </div>
                    </div>

                    {isMobile ? (
                      <div className={styles.close}>
                        <Button variant="text" className={styles.cross} onClick={handleClose}>
                          <img src="/icons/closeThin.svg" alt="" />
                        </Button>
                      </div>
                    ) : null}
                    <div className={styles.containerDatePickerHeader}>
                      <div className={styles.plugDatePickerHeader}> </div>
                      <DatePickerHeader
                        viewDate={currentViewDate}
                        currentView={currentView}
                        setCurrentView={setCurrentView}
                        setCurrentViewDate={setCurrentViewDate}
                        isMobile={isMobile}
                      />
                    </div>


                    <Box className={styles.calendarBox} ref={calendarRef}>
                      <CustomDatePickerHotels
                        disablePast
                        disableFuture
                        disableHighlightToday
                        value={currentViewDate}
                        dayOfWeekFormatter={capitalize}
                        onMonthChange={onFirstMonthChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        views={currentView}
                        reduceAnimations
                        slots={{
                          day: renderWeekPickerDay,
                          actionBar: () => null,
                          toolbar: () => null,
                          nextIconButton: isMobile ? undefined : () => null,
                          switchViewButton: () => null
                        }}
                      />
                      {isMobile ? null : (
                        <CustomDatePickerHotels
                          disableFuture
                          value={addMonths(currentViewDate, 1)}
                          disableHighlightToday
                          dayOfWeekFormatter={capitalize}
                          minDate={minDate2}
                          maxDate={maxDate2}
                          views={currentView}
                          reduceAnimations
                          slots={{
                            day: renderWeekPickerDay,
                            actionBar: () => null,
                            toolbar: () => null,
                            previousIconButton: () => null,
                            switchViewButton: () => null
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Fade>
              )}
            </PopperUnstyled>
          ) : null}
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};
export default RangeDatePickerHotels;
