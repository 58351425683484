import clsx from 'clsx';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import styles from './Question.module.scss';
import i18n from "i18next";

export interface IQuestion {
  id: number;
  question: string;
  answer: string | string[];
  question_en: string;
  answer_en: string;
  hotels?: boolean;
}
type Props = Omit<IQuestion, 'id'>;

const Question = ({ question, answer, question_en, answer_en, hotels }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(s => !s);
  };

  return (
    <div className={styles.root}  onClick={handleOpen}>
      <div className={styles.content}>
        <div className={styles.question}>{(!i18n.language.startsWith('ru') && question_en) || question}</div>
        <Collapse in={isOpen} className={styles.tagheading}>
          <div className={styles.answer}>
            {typeof answer === 'string' ? (
                <p className={styles.p} dangerouslySetInnerHTML={{ __html: (!i18n.language.startsWith('ru') && answer_en) || answer }} />
            ) : (
              answer.map((str, i) => (
                  <p key={i} className={styles.p} dangerouslySetInnerHTML={{ __html: str }} />
              ))
            )}
          </div>
        </Collapse>
      </div>
      <button type="button" className={clsx(styles.accordeonButton, isOpen ? (hotels? styles.openHotel : styles.open) : '')} />
    </div>
  );
};
export default Question;
