import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { updateModal } from '../../../store/slices/modal';
import { userSelector } from '../../../store/slices/user';
import { useAppDispatch } from '../../../store/store';
import styles from './LoginButton.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../utils/useIsMobile';
import { routes } from '../../../constants/routes';
import { useLangNavigate } from '../../../utils/langNavigate';

type Props = {
  variant?: 'light' | 'dark' | 'blue';
  className?: string;
  type?: string;
  onClick: any;
};

const LoginButton: FunctionComponent<Props> = ({ variant, className, type, onClick }) => {
  const dispatch = useAppDispatch();
  const { token, isMobileLKMenuOpened } = useSelector(userSelector);
  const isMobile = useIsMobile(1025);
  const langNavigate = useLangNavigate();

  const { t } = useTranslation();

  const onButtonIconContainerClick = () => {
    if (!!token) langNavigate(isMobile ? routes.lk : routes.lk_profile);
    else dispatch(updateModal({ login: true }));
  };
  const style = variant === 'dark' ? styles.buttonIconDark : styles.buttonIconLight;
  const currentPath = location.href.split('/');
  const inLk = location.href.indexOf(routes.lk) > -1 && !currentPath.includes('success') && !currentPath.includes('fail');

  return (
    <button
      type="button"
      className={clsx(style, type === 'mobile' ? styles.mobile : '', className)}
      onClick={inLk ? onClick : onButtonIconContainerClick}
    >
      <img
        className={styles.icon}
        alt=""
        src={
          variant !== 'dark' ? '/icons/userBlue.svg' : '/icons/userFlat.svg'
        }
      />
      <div className={styles.text}>{!!token ? t('main.header.account.loggedIn') : t('main.header.account.login')}</div>
    </button>
  );
};

export default LoginButton;
