import { Story } from '../../../types/stories';
import styles from './StoryCard.module.scss';
import clsx from 'clsx';
import { useAppDispatch } from '../../../store/store';
import { openStory } from '../../../store/slices/stories';
import i18n from "i18next";

type Props = {
  story: Story;
};

const StoryCard = ({ story }: Props) => {
  const dispatch = useAppDispatch();
  const header = story.slides[0].header;
  const header_en = story.slides[0].header_en;
  const image = story.slides[0].image;

  const handleOpenStory = () => {
    dispatch(openStory({ id: story.id }));
  };

  return (
    <div className={clsx(styles.root, story.viewed ? styles.viewed : '')} onClick={handleOpenStory}>
      <div className={styles.image}>
        <img className={styles.imageChild} alt="" src={image} />
        {header ? (
          <div className={styles.gradienttext}>
            <div className={styles.header}>{(!i18n.language.startsWith('ru') && header_en) || header}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StoryCard;
