import clsx from 'clsx';
import { ButtonProps } from '@mui/material';
import { FunctionComponent } from 'react';
import styles from './IconButton.module.scss';

type IconButtonType = ButtonProps & {
  icon?: string;
};

const IconButton: FunctionComponent<IconButtonType> = ({ icon, className }) => {
  return (
    <button className={clsx(styles.iconButton, className)}>
      <img className={styles.icons} alt="" src={icon} />
    </button>
  );
};

export default IconButton;
