import { FunctionComponent, useMemo } from 'react';
import styles from './SelectOption.module.scss';
import OptionUnstyled from '@mui/base/OptionUnstyled';

type SelectOptionType = {
  label: string;
  value: string;
  description?: string;
  code?: string;
};

const SelectOption: FunctionComponent<SelectOptionType> = ({ label, value, description, code }) => {
  return (
    <OptionUnstyled value={value} className={styles.root}>
      <div className={styles.title}>
        <div className={styles.value}>{label}</div>
        {description ? <div className={styles.description}>{description}</div> : null}
      </div>
      {code ? <div className={styles.code}>{code}</div> : null}
    </OptionUnstyled>
  );
};

export default SelectOption;
