import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {IHelp} from "../../types/help";
import {BASE_URL} from "../../utils/api";

const basicHeaders = {Accept: 'application/json', 'Content-type': 'application/json'};

export const helpApi = createApi({
    reducerPath: 'helpApi',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: builder => ({
        getHelp: builder.query<{ data: IHelp }, {}>({
            query: () => ({
                url: 'help',
                headers: {Accept: 'text/html'}
            }),
        }),
    }),
});

export const {useGetHelpQuery} = helpApi;