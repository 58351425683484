import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IFilterSlice {
  isFilterOpen: boolean;
}

const initialState: IFilterSlice = {
  isFilterOpen: false,
};

export type ISwitchFilterPayload = { isFilterOpen: boolean };

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    switchFilter: (state, { payload: { isFilterOpen } }: PayloadAction<ISwitchFilterPayload>) => {
      state.isFilterOpen = isFilterOpen;
    },
  },
});

export const { switchFilter } = filterSlice.actions;
export default filterSlice.reducer;

export const filterSelector = (state: RootState) => state.filter;
