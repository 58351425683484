import { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { ButtonBaseProps } from '@mui/material';

type ButtonType = ButtonBaseProps & {
  iconBefore?: string;
  iconAfter?: string;
  variant?: 'contained' | 'outlined' | 'text';
  children?: JSX.Element | string;
  onClick?: () => void;
  secondary?: boolean;
  /** Style props */
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  hotels?:boolean;
};

const Button: FC<ButtonType> = ({
  iconBefore,
  iconAfter,
  variant = 'contained',
  secondary,
  children,
  onClick,
  type = 'button',
  style = {},
  className = '',
  disabled,
  hotels
}) => {
  return (
    <ButtonUnstyled
      type={type}
      className={clsx(className, styles.button, styles[variant], secondary ? styles.secondary : styles.primary, { [styles.hotels]: hotels })}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      {iconBefore && <img className={styles.icon} alt="" src={iconBefore} />}
      {children}
      {iconAfter && <img className={styles.icon} alt="" src={iconAfter} />}
    </ButtonUnstyled>
  );
};

export default Button;
