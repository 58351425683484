import {FunctionComponent} from 'react';
import Input from '../../Input/Input';
import FilterItem from '../FilterItem/FilterItem';
import styles from './FilterCompanies.module.scss';
import {useTranslation} from "react-i18next";

interface Props {
    search_airlines: any;
    available: string[];
    airlines: any;
    onChange?: any;
}

const FilterCompanies: FunctionComponent<Props> = ({ search_airlines, available, airlines, onChange }) => {
  const { t } = useTranslation();
  return (
    <FilterItem name={t('filter.airlines')}>
      <div className={styles.container}>
        <div className={styles.list + ' ' + (search_airlines && Object.keys(search_airlines).length > 6 ? styles.list_scrollable : '')}>
          { search_airlines && Object.keys(search_airlines).length &&
            Object.keys(search_airlines).map((company, index) => {
                return (available && available.includes(company)) && <Input
                    key={ index }
                    type="checkbox"
                    checkboxIcon={<img className={styles.icon} src={'https://mpics.avs.io/al_square/64/64/' + company + '.png'} alt="" />}
                    label={ search_airlines[company].name }
                    name={ 'airlines[' + company + ']' }
                    defaultChecked={ airlines ? airlines.includes(company) : false }
                    onChange={ (e) => onChange(e) }
                    checked={airlines.indexOf(company) !== -1}
                />
            })
          }
        </div>
        <div className={styles.shade} />
      </div>
    </FilterItem>
  );
};

export default FilterCompanies;
