import { ReactNode } from 'react';
import styles from './NavigationButton.module.scss';
import Button from '../Button';
import { routes } from '../../constants/routes';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLangNavigate } from '../../utils/langNavigate';

interface NavigationButtonProps {
  className?: string;
  children?: ReactNode;
  active: 'airline' | 'hotels'
}


export const NavigationButton = (props: NavigationButtonProps) => {
  const {
    className,
    children,
    active,
    ...otherProps
  } = props;
  const {t} = useTranslation()
  const langNavigate = useLangNavigate();


  return (
    <div className={styles.container}
         {...otherProps}
    >
      <Button
        onClick={() => langNavigate(routes.home)}
        className={ clsx(styles.aviaButton, active === 'airline' && styles.active)}>
        <>
          <img
            className={styles.imgAvia}
            src={active === 'airline' ? "/icons/avia-button-blue.svg" : "/icons/avia-button.svg"}
            alt="avia-icon"
          />
          {t('navigationButton.buttonAvia')}
        </>
      </Button>
      <Button
        onClick={() => langNavigate(routes.hotels)}
        className={clsx(styles.hotelsButton, active === 'hotels' && styles.active)}
      >
        <>
          <img
            className={styles.imgHotels}
            src={active === 'hotels' ? "/icons/hotel-button-purple.svg" : "/icons/hotel-button.svg"}
            alt="hotel-icon"
          />
          {t('navigationButton.buttonHotels')}
        </>
      </Button>
    </div>
  );
};
