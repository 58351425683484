import { FunctionComponent, useMemo } from 'react';
import { Property } from 'csstype';
import styles from './SkeletonFilter.module.scss';
import { Skeleton } from '@mui/material';

type SkeletonFilterType = {
  filterHeight?: Property.Height;
};

const SkeletonFilter: FunctionComponent<SkeletonFilterType> = ({ filterHeight }) => {
  return (
    <div className={styles.filter} style={{ height: filterHeight }}>
      <Skeleton className={styles.nameFilter} />
    </div>
  );
};

export default SkeletonFilter;
