import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { stories } from '../../mock/stories';
import { ID, Story } from '../../types/stories';
import { RootState } from '../store';

export interface StoriesSlice {
  stories: Story[];
  activeStory: number | null;
  activeSlide: number | null;
  isPaused: boolean;
}

const initialState: StoriesSlice = {
  stories: [],
  activeStory: null,
  activeSlide: null,
  isPaused: false,
};

export type IOpenStoryPayload = { id: ID };
export type ISetSlidePayload = { index: number };
export type IPauseSlidePayload = { isPaused: boolean };
export type ISetStoriesPayload = { stories: Story[]; append?: boolean };

const getNextStory = (state: StoriesSlice, direction: 'next' | 'prev' = 'next') => {
  const shift = direction === 'next' ? 1 : -1;
  if (state.activeStory === null) return null;
  if (state.activeStory === 0 && direction === 'prev') return null;
  if (state.activeStory === state.stories.length - 1 && direction === 'next') return null;
  return state.activeStory + shift;
};

const storiesSlice = createSlice({
  name: 'Stories',
  initialState,
  reducers: {
    openStory: (state, { payload: { id } }: PayloadAction<IOpenStoryPayload>) => {
      const activeStoryId = state.stories.findIndex(st => st.id === id);
      if (activeStoryId === -1) return;
      state.activeStory = activeStoryId;
      state.activeSlide = 0;
    },
    closeStory: state => {
      state.activeSlide = null;
      state.activeStory = null;
    },
    nextStory: state => {
      state.activeSlide = 0;
      state.activeStory = getNextStory(state, 'next');
    },
    prevStory: state => {
      state.activeSlide = 0;
      state.activeStory = getNextStory(state, 'prev');
    },
    nextSlide: state => {
      if (state.activeSlide === null || state.activeStory === null) return;
      const nextIndex =
        state.activeSlide + 1 === state.stories[state.activeStory].slides.length ? Infinity : state.activeSlide + 1;
      if (Number.isFinite(nextIndex)) {
        state.activeSlide = nextIndex;
      } else {
        state.activeStory = getNextStory(state, 'next');
        state.activeSlide = 0;
      }
    },
    prevSlide: state => {
      if (state.activeSlide === null || state.activeStory === null) return;
      const nextIndex = state.activeSlide - 1 < 0 ? -Infinity : state.activeSlide - 1;
      if (Number.isFinite(nextIndex)) {
        state.activeSlide = nextIndex;
      } else {
        state.activeStory = getNextStory(state, 'prev');
        state.activeSlide = 0;
      }
    },
    pauseSlide: (state, { payload: { isPaused } }: PayloadAction<IPauseSlidePayload>) => {
      state.isPaused = isPaused;
    },
    setStories: (state, { payload: { stories, append = false } }: PayloadAction<ISetStoriesPayload>) => {
      append ? (state.stories = state.stories.concat(stories)) : (state.stories = stories);
    },
  },
});

export default storiesSlice.reducer;
export const { openStory, closeStory, nextStory, prevStory, nextSlide, prevSlide, pauseSlide, setStories } =
  storiesSlice.actions;

export const storiesSelector = (state: RootState) => state.stories;
