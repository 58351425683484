import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Airports, ProposalsEntity } from '../../types/backend';
import { IOrder } from '../../types/order';
import { IPassenger } from '../../types/passenger';
import { Airport, ITicket } from '../../types/ticket';
import { RootState } from '../store';

interface IOrderSlice {
  orders: IOrder[],
  currentOrder: IOrder | null,
  ticket: ITicket | null;
  trip: ProposalsEntity | null;
  back?: ProposalsEntity | null;
  airports: Airports
}

const initialState: IOrderSlice = {
  orders: [],
  currentOrder: null,
  ticket: null,
  trip: null,
  back: null,
  airports: {},
};

export type ISetOrdersPayload = { orders: IOrder[] };
export type ISetAirportsPayload = { airports: Airports };
export type ISetOrderPayload = { order?: IOrder | null };
export type ISetTicketPayload = {
  ticketEntry: { ticket: ITicket | null; trip: ProposalsEntity | null; back?: ProposalsEntity | null };
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrders: (state, { payload: { orders } }: PayloadAction<ISetOrdersPayload>) => {
      state.orders = orders;
    },
    setOrder: (state, { payload: { order } }: PayloadAction<ISetOrderPayload>) => {
      state.currentOrder = order ?? null;
    },
    setAirports: (state, { payload: { airports } }: PayloadAction<ISetAirportsPayload>) => {
      state.airports = airports;
    },
    setTicket: (state, { payload: { ticketEntry } }: PayloadAction<ISetTicketPayload>) => {
      state.ticket = ticketEntry.ticket;
      state.trip = ticketEntry.trip;
      state.back = ticketEntry.back;
    },
    resetTicketAndOrder: (state) => {
      state.ticket = null;
      state.trip = null;
      state.back = null;
      state.currentOrder = null;
    }
  },
});

export const { setOrders, setOrder, setAirports, setTicket, resetTicketAndOrder } = orderSlice.actions;
export default orderSlice.reducer;

export const ordersSelector = (state: RootState) => state.order;
