import {Controller, useFormContext} from 'react-hook-form';
import Card from '../../../Card/Card';
import Input from '../../../Input/Input';
import styles from './BookingCommentHotels.module.scss';
import {combineValidators, validateMaxLength, validateStartEndSpaces} from '../../../../utils/formValidation';
import {useTranslation} from "react-i18next";
import { setComment } from '../../../../store/slices/searchHotels';
import { useAppDispatch } from '../../../../store/store';


const BookingCommentHotels = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch()
  const {
    control,
    formState: {errors},
  } = useFormContext();
  return (
    <Card>
      <div className={styles.header}>{t('bookingComment.bookingComment')}</div>
      <Controller
        name="comment"
        control={control}
        rules={{
          validate: combineValidators([validateStartEndSpaces, validateMaxLength])
        }}
        render={({field}) =>
          <Input
            {...field}
            hotels={true}
            type="textarea"
            label={t('bookingComment.bookingComment')}
            error={errors['comment']?.message as string}
            onChange={(e) => {
              field.onChange(e);
              dispatch(setComment(e.target.value));
            }}
          />
        }
      />
    </Card>
  );
};

export default BookingCommentHotels;
