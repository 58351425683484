import { FunctionComponent } from 'react';
import Header from './Header/Header';
import SearchBar from './SearchBar/SearchBar';
import styles from './HeaderCatalogTickets.module.css';

interface Props {
  search?: boolean;
  height?: number;
  variant?: 'light' | 'dark';
}

const HeaderCatalogTickets: FunctionComponent<Props> = ({ search = true, height = 140, variant }) => {
  return (
    <div className={styles.headerCatalogTickets}>
      {variant !== 'light' ? (
        <div className={styles.gradientHome} style={{ height: `${height}px` }}>
          <img className={styles.headerhome1Icon} alt="" src="/images/headerhome-1@2x.png" />
        </div>
      ) : null}
      <div className={styles.home}>
        <Header variant={variant} />
        {search ? (
          <div className={styles.searchDropdown}>
            <SearchBar />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeaderCatalogTickets;
