import {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler, useEffect,
  useRef,
  useState
} from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import styles from '../Input/Input.module.scss';
import styles_suggestion from './Suggestion.module.scss';
import { ClickAwayListener, IconButton, InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import { IPassenger } from '../../types/passenger';
import { toast } from 'react-toastify';
import onChange = toast.onChange;
import { useTranslation } from 'react-i18next';

export type ErrorType =
  | string
  | FieldError
  | Partial<{ type: string | number; message: string }>
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;
export type SuggestionInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  checkboxIcon?: JSX.Element;
  iconAfter?: string;
  error?: ErrorType;
  description?: string;
  noCapitalize?: boolean;
  onIconClick?: MouseEventHandler;
  options?: any[];
  option_search: string;
  callback?: any;
  field?: any
  hotels? : boolean
};

const SuggestionInput = forwardRef<HTMLInputElement, SuggestionInputProps>((props, ref) => {

  const textRef = useRef<HTMLInputElement | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState(props.options);

  const value =
    props.noCapitalize
      ? props.value?.toLocaleString()
      : props.value?.toLocaleString().toLocaleUpperCase();

  const outputItem = (item: IPassenger) => {
    return (
      <li
        className={styles_suggestion.option}
        onClick={() => handleChange(item)}
      >
        {(
          <>
            <div className={styles_suggestion.title}>
              <div className={styles_suggestion.value}>{item?.surname} {item?.name}</div>
            </div>
          </>
        )}
      </li>
    );
  };

  useEffect(() => {

    if (props.value) {

      const filter =
        props.options?.map(
          (option) => {
            if (String(option[props.option_search]).indexOf(String(value)) !== -1) {
              return option;
            }
          }).filter(Boolean);

      setFilterOptions(filter);

    } else {
      setFilterOptions(props.options);
    }

  }, [value, props.options]);

  const handleChange = (passenger: IPassenger) => {
    props.callback(passenger);
    setIsUpdating(true);
    handleClose();
  };
  const handleFocus = () => {
    handleOpen();
    setIsUpdating(true);
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = (e?: MouseEvent | TouchEvent) => {
    if (textRef?.current) {
      if (e?.target === textRef.current) return;
      setIsOpen(false);
      setIsUpdating(false);
    }
  };

  const handleStartEdit = () => {
    setIsUpdating(true);
    handleOpen();
  };

  const handleKey: KeyboardEventHandler = e => {
    if (e.key == 'Tab') handleClose();
    if (e.key == 'Escape') handleClose();
  };

  const { t } = useTranslation();

  // @ts-ignore
  return (
    <div className={clsx(styles.wrapper, styles_suggestion.root)}>
      <TextField
        inputRef={textRef}
        autoFocus={props.autoFocus}
        error={!!props.error}
        onChange={props.onChange}
        value={value}
        required={props.required}
        label={props.label}
        name={props.name}
        variant="filled"
        helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
        className={styles.input}
        type={'text'}
        autoComplete="new-password"
        onClick={handleStartEdit}
        onKeyDown={handleKey}
        onFocus={handleFocus}

        InputProps={{
          sx: props.hotels ? {
            '&.Mui-focused': {
              border: '1px solid var(--purple-6100FF) !important'
            }
          } : {},

          classes: { root: props.readOnly ? styles.readOnly : '' },
          endAdornment: props.iconAfter ? (
            <InputAdornment className={styles.InputAdornment} position="start">
              <IconButton
                className={styles.passswordButton}
                aria-label="toggle password visibility"
                onClick={props.onIconClick}
                edge="end"
              >
                <img src={props.iconAfter} />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }}
      />
      {isOpen && props?.options?.length ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={styles_suggestion.paper}>
            <ul className={styles_suggestion.listbox}>
              <>
                {
                  filterOptions && filterOptions.length > 0
                    ?
                    filterOptions.map((item) => {
                      return outputItem(item);
                    })
                    :
                    <li className={styles_suggestion.option} style={{ minHeight: 30, fontSize: '16px' }}
                        key="noResults">{t('suggestion.noMatches')}</li>
                }
              </>
            </ul>
          </div>
        </ClickAwayListener>
      ) : ''}
    </div>
  );
});

export default SuggestionInput;
