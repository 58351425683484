import { IPassenger, IPassengerType } from '../types/passenger';
import {
  citizenshipOptions,
  russiaDocumentOptions,
} from '../components/PassengerForm/components/PersonalDocument/PersonalDocument';

export class Passenger {
  private static _uid = 0;

  id: number;
  user_id: number;
  name: string;
  surname: string;
  patronymic: string;
  birth: string;
  gender: 'male' | 'female';
  citizenship: string;
  document: string;
  passport_id: string;
  validity: string;
  hasValidity: boolean;
  type: IPassengerType;

  constructor(data: Partial<Passenger>) {
    this.id = data.id ?? Passenger.getUserId();
    this.user_id = data.user_id || 0;
    this.name = data.name ?? '';
    this.surname = data.surname ?? '';
    this.patronymic = data.patronymic ?? '';
    this.birth = data.birth ?? '';
    this.gender = data.gender ?? 'male';
    this.citizenship = data.citizenship ?? citizenshipOptions[0].value;
    this.document = data.document ?? russiaDocumentOptions[0].value;
    this.passport_id = data.passport_id ?? '';
    this.validity = data.validity ?? '';
    this.hasValidity = (data.validity ? data.validity !== '' : true);
    this.type = data.type ?? 'adult';
  }

  private static getUserId() {
    return ++Passenger._uid;
  }
  getPassenger(): IPassenger {
    return {
      id: this.id,
      user_id: this.user_id,
      name: this.name,
      surname: this.surname,
      patronymic: this.patronymic,
      birth: this.birth,
      gender: this.gender,
      citizenship: this.citizenship,
      document: this.document,
      passport_id: this.passport_id,
      validity: this.validity,
      hasValidity: this.hasValidity,
      type: this.type,
    };
  }

  public toString() {
    return JSON.stringify(this.getPassenger());
  }
}
