import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isdaybetween?: boolean;
}

const CustomPickersDayHotels = styled(PickersDay, {
  shouldForwardProp: () => true,
})<CustomPickerDayProps>(({ selected, disabled, isdaybetween }) => ({
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'var(--white-ffffff) !important',
    border: '1px solid var(--black-222222) !important',
    color: 'var(--black-222222) !important',
    borderRadius: 8,
  },
  ...(selected && {
    backgroundColor: 'var(--black-222222) !important',
    color: 'var(--white-ffffff) !important',
  }),
  ...(disabled && {
    color: 'var(--gray-d5d7e2)',
  }),
  ...(isdaybetween
    ? {
        backgroundColor: 'var(--gray-eef0f6)',
        borderRadius: 0,
      }
    : {}),
})) as React.ComponentType<CustomPickerDayProps>;

export default CustomPickersDayHotels;
