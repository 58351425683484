import { memo, ReactNode, useEffect } from 'react';
import styles from './SuccessfulPayment.module.scss';
import PublicLayout from '../Layouts/PublicLayout/PublicLayout';
import { useParams } from 'react-router-dom';
import { useGetOrderSuccessPayQuery } from '../../store/api/searchHotelService';
import { formatDates, getSummPrice } from '../../utils/formatting';
import { useLangNavigate } from '../../utils/langNavigate';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { routes } from '../../constants/routes';

interface SaccessfulPaymentProps {
  className?: string;
  children?: ReactNode;
}


export const SuccessfulPayment = memo((props: SaccessfulPaymentProps) => {
  const {
    className,
    children,
    ...otherProps
  } = props;

  const { id_order } = useParams<{ id_order: string }>();
  const { data, isLoading, error } = useGetOrderSuccessPayQuery({ order_id: id_order });
  const navigate = useLangNavigate();
  const BASE_PRICE = (process.env.REACT_APP_BASE_PRICE_HOTEL || 1399);
  const BASE_PRICE_EURO = (process.env.REACT_APP_BASE_PRICE_HOTEL_EURO || 17);
  const { t } = useTranslation();
  const isRussianLanguage = i18n.language.startsWith('ru');
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');
  const guests = data?.persons;
  const dateOrder = data?.created_at.split('-').join('.');
  const numberOrder = data?.id;
  const summOrder = currency === 'rub' ? `${data?.persons_count ? getSummPrice(BASE_PRICE, data?.persons_count) : ''} ₽` : `${data?.persons_count ? getSummPrice(BASE_PRICE_EURO, data?.persons_count) : ''} €`;
  const detailSummOrder = `${data?.persons_count} х ${currency === 'rub' ? `${BASE_PRICE} ₽` : `${BASE_PRICE_EURO} €`}`;
  const quantitiesGuests = data?.persons_count;
  const city = isRussianLanguage ? `${data?.city?.city}, ${data?.city?.country?.country}` : `${data?.city?.city_en}, ${data?.city?.country?.country_en}`;
  const dates = data?.date_from && data?.date_to ? formatDates(isRussianLanguage, data.date_from, data.date_to) : null;

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        navigate(routes.hotels_payment_error);
      }
    }
  }, [isLoading, error, navigate]);


  return (
    <PublicLayout filter={false} search={false} height={80} variant="light">
      {!isLoading && !error &&
        <div className={styles.paymentSuccess}>
          <div>
            <div className={styles.header}>
              <img src="/icons/check-pay.svg" alt="Success" className={styles.successIcon} />
              <div className={styles.title}>{t('successfulPayment.header')}</div>
            </div>
            <p className={styles.description}>
              {t('successfulPayment.text')}
            </p>
          </div>

          <div className={styles.orderDetails}>
            <div className={styles.hotelInfo}>
              <div>
                <div className={styles.cityContainer}>
                  <img src="/icons/location-icon.svg" alt="location-icon" className={styles.locationIcon} />
                  <div>
                    <div className={styles.city}>{city}</div>
                    <p className={styles.dates}>{dates}</p>
                  </div>
                </div>
              </div>
              <img src={t('images.statusPay')} alt="status-pay" className={styles.statusPay} />
            </div>
            <div className={styles.bookingInfo}>
              <div className={styles.detail}>
                <p className={styles.label}>{t('successfulPayment.dateOrder')}</p>
                <p className={styles.infoColumn}>{dateOrder}</p>
              </div>
              <div className={styles.detail}>
                <p className={styles.label}>{t('successfulPayment.numberOrder')}</p>
                <p className={styles.infoColumn}>№ {numberOrder}</p>
              </div>
              <div className={styles.detail}>
                <p className={styles.label}>{t('successfulPayment.countGuests')}</p>
                <div className={styles.guestContainer}>
                  <img src="/icons/men.svg" alt="man icon" className={styles.manIcon} />
                  <p className={styles.infoColumn}>{quantitiesGuests}</p>
                </div>
              </div>
              <div className={styles.detail}>
                <p className={styles.label}>{t('successfulPayment.sum')}</p>
                <p className={styles.infoColumn}>{summOrder}</p>
                <p className={styles.label}>{detailSummOrder}</p>

              </div>
            </div>

          </div>

          <div className={styles.guests}>
            <div className={styles.titleGuests}>{t('successfulPayment.guests')}</div>
            <div className={styles.guestList}>
              {guests && guests.map((guest, index) => (
                <div key={index} className={styles.guestCard}>
                  <img src="/icons/avatar-guest.svg" alt="avatar-guest icon" className={styles.avatarGuest} />
                  {`${guest.first_name}   ${guest.last_name}`}
                </div>
              ))
              }
            </div>
          </div>
        </div>
      }
    </PublicLayout>
  );
});
