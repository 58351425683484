import { useSelector } from 'react-redux';
import { closeStory, storiesSelector } from '../../../store/slices/stories';
import { useAppDispatch } from '../../../store/store';
import PortalPopup from '../PortalPopup/PortalPopup';
import ScrollStories from '../ScrollStories/ScrollStories';

const StoryViewer = () => {
  const dispatch = useAppDispatch();
  const { activeStory, stories } = useSelector(storiesSelector);
  if (activeStory === null) return null;
  const currentStory = stories[activeStory];
  // console.log({ activeStory, currentStory });

  const handleClose = () => dispatch(closeStory());

  return (
    <PortalPopup overlayColor="rgba(0, 0, 0, 0.5)" placement="Centered" onOutsideClick={handleClose}>
      <ScrollStories story={currentStory} onClose={handleClose} />
    </PortalPopup>
  );
};

export default StoryViewer;
