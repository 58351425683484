import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007aff',
      light: '#bfdeff',
      dark: '#0068d9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
    error: {
      main: '#FF3D00',
    },
    warning: {
      main: '#FFA620',
    },
    info: {
      main: '#007aff',
    },
    success: {
      main: '#60b56e',
    },
    grey: {
      '700': '#33343a',
      '600': '#52545c',
      '500': '#81838e',
      '400': '#a5a7b2',
      '300': '#d5d7e2',
      '200': '#eef0f6',
      '100': '#f3f4fb',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['"Exo 2"', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: 'var(--white-ffffff)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          width: '96%',
          alignSelf: 'center',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          border: '1px solid var(--gray-d5d7e2)',
          overflow: 'hidden',
          borderRadius: 8,
          backgroundColor: 'var(--white-ffffff)',
          transition: '0.3s',
          '&:hover': {
            border: '1px solid var(--gray-a5a7b2)',
            backgroundColor: 'var(--white-ffffff)',
          },
          '&.Mui-focused': {
            border: '1px solid var(--blue-007aff) !important',
            backgroundColor: 'var(--white-ffffff)',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        style: { color: 'var(--gray-a5a7b2)' },
      },
    },
  },
});
