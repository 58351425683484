import { FC, forwardRef, Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import styles from './Select.module.scss';
import SelectOption from './SelectOption';
import SelectOptionDetail from './SelectOptionDetail';
import SelectRenderValue from './SelectRenderValue';
import { PopperPlacementType } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface OptionType {
  value: string;
  label: ReactNode;
  code?: string;
}

export interface OptionGroupType {
  value: string;
  label: string;
  description?: string;
  code?: string;
  options?: OptionType[];
}

export interface SelectType {
  label: string;
  value: string;
  name?: string;
  error?: string;
  options?: OptionGroupType[];
  className?: string;
  placment?: PopperPlacementType;
  disableScroll?: boolean;
  onChange?: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
    v: string | null
  ) => void;
}

const SelectUnstyledCustom = styled(SelectUnstyled)<any>(() => ({
    '&.Mui-expanded img': {
      transform: "rotate(180deg)",
    },
}));

const Select: FC<SelectType> = forwardRef<HTMLButtonElement, SelectType>(
  ({ options, label, name, onChange, className, error, value, placment = 'bottom-start', disableScroll }, ref) => {
    if (!options) return null;

    function renderValue(option: OptionType | null) {
      return <SelectRenderValue value={option?.label} label={label} />;
    }

    return (
      <div className={styles.wrapper}>
        <SelectUnstyledCustom
          slotProps={{
            listbox: { className: clsx(styles.listbox, disableScroll ? styles.disableScroll : '') },
            popper: {
              className: styles.popper,
              popperOptions: {
                placement: placment,
                modifiers: [
                  { name: 'offset', options: { offset: [0, 10] } },
                  { name: 'flip', options: { fallbackPlacements: [placment] } },
                ],
              },
              disablePortal: true,
            },
          }}
          // listboxOpen
          className={clsx(
            styles.field,
            !!error ? styles.error : '',
            options.length > 7 ? styles.withScroll : '',
            className
          )}
          renderValue={renderValue}
          value={value}
          onChange={onChange}
          name={name}
          ref={ref}
        >
          {options.map(group => (
            <Fragment key={group.value}>
              <SelectOption {...group} />
              {group.options?.length
                ? group.options.map(option => <SelectOptionDetail key={option.value} {...option} />)
                : null}
            </Fragment>
          ))}
        </SelectUnstyledCustom>
        {!!error ? <span className={styles.errorText}>{error}</span> : null}
      </div>
    );
  }
);

export default Select;
