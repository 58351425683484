import { TextField } from '@mui/material';
import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { InputProps } from '../Input';
import styles from '../Input.module.scss';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  country: string;
  hotels?: boolean
}

const NumberFormatCustom = forwardRef<HTMLDivElement, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue
          }
        });
      }}
      format={'+###############'} // "+# (###) ### ##-##"
      // mask={'\u1810'}
      // mask={'\u1C50'}
      // mask={'\u07C0'}
      mask=" "
    />
  );
});

const PhoneInput = forwardRef((props: InputProps, ref) => {
  return (
    <div className={styles.wrapper}>
      <TextField
        inputRef={ref}
        autoFocus={props.autoFocus}
        error={!!props.error}
        onChange={props.onChange}
        value={props.value}
        required={props.required}
        label={props.label}
        name={props.name}
        variant="filled"
        helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
        className={styles.input}
        type={props.type}
        autoComplete="new-password"
        InputProps={{
          sx: props.hotels ? {
            '&.Mui-focused': {
              border: '1px solid var(--purple-6100FF) !important'
            }
          } : {},

          inputComponent: NumberFormatCustom as any
        }}
      />
    </div>
  );
});

export default PhoneInput;
