import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPassenger } from '../../types/passenger';
import { RootState } from '../store';
import {
  citizenshipOptions,
  russiaDocumentOptions,
} from '../../components/PassengerForm/components/PersonalDocument/PersonalDocument';

export interface User extends IPassenger {
  email: string;
  phone?: string;
  password?: boolean;

}
interface IUserSlice {
  token: string;
  user: User;
  passengers: IPassenger[];
  isMenuOpened: boolean;
  isMobileLKMenuOpened: boolean;
}

const initialState: IUserSlice = {
  token: '',
  user: {
    id: 0,
    user_id: 0,
    email: '',
    password: true,
    phone: '',
    name: '',
    surname: '',
    patronymic: '',
    gender: 'male',
    citizenship: citizenshipOptions[0].value,
    type: 'adult',
    birth: '',
    validity: '',
    hasValidity: true,
    document: russiaDocumentOptions[0].value,
    passport_id: ''
  },
  passengers: [],
  isMenuOpened: false,
  isMobileLKMenuOpened: true
};

export type ISaveTokenPayload = { token: string };
export type ISaveUserPayload = { user: any };
export type ISavePassengersPayload = { passengers: IPassenger[] };
export type ISavePassengerPayload = { passenger: IPassenger };
export type ISwitchMenuPayload = { value: boolean };

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    saveToken: (state, { payload: { token } }: PayloadAction<ISaveTokenPayload>) => {
      state.token = token;
    },
    clearToken: state => {
      state.token = '';
    },
    saveUser: (state, { payload: { user } }: PayloadAction<ISaveUserPayload>) => {
      let newUser = user.user || user
      state.user = { ...state.user, ...newUser };
    },
    savePassengers: (state, { payload: { passengers } }: PayloadAction<ISavePassengersPayload>) => {
      state.passengers = passengers;
    },
    updatePassenger: (state, { payload: { passenger } }: PayloadAction<ISavePassengerPayload>) => {
      const updatedPassengerIndex = state.passengers.findIndex(p => p.id === passenger.id);
      state.passengers[updatedPassengerIndex] = passenger;
    },
    switchMenu: (state, { payload: { value } }: PayloadAction<ISwitchMenuPayload>) => {
      state.isMenuOpened = value;
    },
    switchMobileLKMenu: (state, { payload: { value } }: PayloadAction<ISwitchMenuPayload>) => {
      state.isMobileLKMenuOpened = value;
    },
    resetUser: () => initialState,
  },
});

export const { saveToken, clearToken, saveUser, savePassengers, updatePassenger, switchMenu, switchMobileLKMenu, resetUser } =
  userSlice.actions;
export default userSlice.reducer;

export const userSelector = (state: RootState) => state.user;
