import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { links } from '../../mock/partner';
import { IPartner, IPartnerLink } from '../../types/partner';
import { RootState } from '../store';
import {User} from "./user";

interface IPartnerSlice {
  partner: IPartner;
  links: IPartnerLink[];
  pagination: { page: number; limit: number };
  linkPagination: { page: number; limit: number };
  date?: string;
  date_from?: string;
  date_to?: string;
}

const initialState: IPartnerSlice = {
  partner: {
    id: 0,
    user_id: 0,
    payment: 'card'
  },
  links: [],
  pagination: { page: 1, limit: 10 },
  linkPagination: { page: 1, limit: 10 },
  date: 'all'
};

export type IAddLinksPayload = { links: IPartnerLink[] };
export type IAddLinkPayload = { link: IPartnerLink };
export type ISavePartnerPayload = { partner: IPartner };
export type IPaginationPayload = { page?: number; limit?: number; };

export type IPeriodPayload = { period: string; };

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    addLinks: (state, { payload: { links } }: PayloadAction<IAddLinksPayload>) => {
      state.links = state.links.concat(links);
    },
    addLink: (state, { payload: { link } }: PayloadAction<IAddLinkPayload>) => {
      state.links = state.links.concat([link]);
    },
    savePartner: (state, { payload: { partner } }: PayloadAction<ISavePartnerPayload>) => {
      state.partner = partner;
    },
    updatePagination: (state, { payload: pagination }: PayloadAction<IPaginationPayload>) => {
      state.pagination = { ...state.pagination, ...pagination };
    },
    updatePeriod: (state, { payload: { period } }: PayloadAction<IPeriodPayload>) => {
      state.date = period;
    },
  },
});

export const { addLinks, addLink, savePartner, updatePagination, updatePeriod } = partnerSlice.actions;
export default partnerSlice.reducer;

export const partnerSelector = (state: RootState) => state.partner;
