import Box from '@mui/material/Box';
import { DateView } from '@mui/x-date-pickers';
import { addDays, addMonths, addYears, startOfMonth, subMonths } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { getNormalDate } from '../../../utils/formatting';
import styles from './RangeDatePickerHotels.module.scss';
import Button from '../../Button';
import SwitchViewButton from './SwitchViewButton';

interface DatePickerHeaderProps {
  viewDate: Date;
  isMobile: boolean;
  currentView: DateView[];
  setCurrentView: Dispatch<SetStateAction<DateView[]>>;
  setCurrentViewDate: Dispatch<SetStateAction<Date>>;
}

const DatePickerHeader = ({
  viewDate,
  isMobile,
  currentView,
  setCurrentView,
  setCurrentViewDate,
}: DatePickerHeaderProps) => {
  const handlePrevMonthClick = () => {
    setCurrentViewDate(subMonths(getNormalDate(viewDate), 1));
  };
  const handleNextMonthClick = () => {
    setCurrentViewDate(addMonths(getNormalDate(viewDate), 1));
  };
  const minDate = startOfMonth(addDays(new Date(), 1)); // minDate is start of tomorrow's month
  const maxDate = startOfMonth(addYears(new Date(), 1)); // maxDate is start of next year's month
  const isPrevMonthDisabled = startOfMonth(viewDate) <= minDate;
  const isNextMonthDisabled = startOfMonth(viewDate) > maxDate;
  const showArrows = currentView.includes('day');

  return (
    <Box className={styles.CalendarHeader}>
      <Button
        disabled={isPrevMonthDisabled || !showArrows}
        secondary
        variant="text"
        className={styles.CalendarNavButton}
        iconBefore="/icons/chevronLeft.svg"
        onClick={handlePrevMonthClick}
      />
      <SwitchViewButton viewDate={viewDate} setCurrentView={setCurrentView} />
      {!isMobile && <SwitchViewButton viewDate={addMonths(viewDate, 1)} setCurrentView={setCurrentView} />}
      <Button
        disabled={isNextMonthDisabled || !showArrows}
        secondary
        variant="text"
        className={styles.CalendarNavButton}
        iconBefore="/icons/chevronRight.svg"
        onClick={handleNextMonthClick}
      />
    </Box>
  );
};

export default DatePickerHeader;
