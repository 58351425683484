import {FunctionComponent} from 'react';
import Input from '../../Input/Input';
import FilterItem from '../FilterItem/FilterItem';
import styles from './FilterTransfers.module.scss';
import { useSelector } from 'react-redux';
import { searchSelector } from '../../../store/slices/search';
import {useTranslation} from "react-i18next";

interface Props {
    onChange?: any;
}

const FilterTransfers: FunctionComponent<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const transfers = [
      { 'name': t('filter.transfersList.none'), 'code': 'none' },
      { 'name': t('filter.transfersList.one'), 'code': 'one' },
      { 'name': t('filter.transfersList.more'), 'code': 'more' },
  ]

  const { transfers_rules } = useSelector(searchSelector);

  return (
    <FilterItem name={t('filter.transfers')}>
      <div className={styles.list}>
          {
              transfers.length > 0 && transfers.map((item) => {
                  return <Input
                    type="checkbox"
                    label={item.name}
                    name={'transfers[' + item.code + ']'}
                    onChange={ (e) => onChange(e) }
                    checked={(transfers_rules.indexOf(item.code) !== -1)}
                  />
              })
          }
      </div>
    </FilterItem>
  );
};

export default FilterTransfers;
