import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {IHelp} from "../../types/help";

interface ReviewsState {
    help: IHelp;
}

const initialState: ReviewsState = {
    help: {},
};

const helpSlice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        setHelp: (state, action: PayloadAction<IHelp>) => {
            state.help = action.payload;
        },
    },
});

export const { setHelp } = helpSlice.actions;
export const helpSelector = (state: RootState) => state.help;

export default helpSlice.reducer;