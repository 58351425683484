import { isAfter, isBefore } from 'date-fns';
import i18n from 'i18next';

import { Terms } from '../types/backend';
import { Price } from '../types/ticket';
import { IOrder } from '../types/order';
import { useTranslation } from 'react-i18next';

const formatDurationFromNumber = (durationInMinutes: number, addEnglish?: boolean) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.round(durationInMinutes - hours * 60);
  return formatDurationFromDuration({ hours, minutes }, addEnglish);
};

const formatDurationFromDuration = (duration: Duration, addEnglish?: boolean) => {
  const days = duration.days || 0;
  const hours = duration.hours ? `${duration.hours + 24 * days} ч` : '';
  const minutes = duration.minutes ? `${duration.minutes} мин` : '';

  const hoursEn = duration.hours ? `${duration.hours + 24 * days} h` : '';
  const minutesEn = duration.minutes ? `${duration.minutes} min` : '';
  const isRussianLanguage = i18n.language.startsWith('ru');


  const result =
    addEnglish ?
      `${hoursEn} ${minutesEn} / ${hours} ${minutes}`.trim()
      : isRussianLanguage ?
        `${hours} ${minutes}`.trim()
        :
        `${hoursEn} ${minutesEn}`.trim();
  return result || '0 мин';
};

export const formatDuration = (duration?: number | Duration, addEnglish?: boolean) => {
  if (!duration && duration !== 0) return;
  if (typeof duration === 'number') return formatDurationFromNumber(duration, addEnglish);
  return formatDurationFromDuration(duration, addEnglish);
};

export const formatTime = (durationInMinutes: number) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.round(durationInMinutes - hours * 60);
  const hoursString = hours ? hours.toFixed(0) : '00';
  const minutesString = minutes ? minutes.toFixed(0) : '00';
  return `${('0' + hoursString).slice(-2)}:${('0' + minutesString).slice(-2)}`;
};

export const formatPrice = (price?: Price | string | number) => {
  if (price === undefined) return;
  if (typeof price === 'string' && price.includes('₽')) return price;
  const priceSymbol = (typeof price === 'object' && price.symbol) || '₽';
  const priceValue = (typeof price === 'object' && price.value) || price;
  const match = priceValue.toString().match(/(\d+?)(?=(\d{3})+(?!\d)|$)/gm);
  return match?.join(' ') + ` ${priceSymbol}`;
};
export const extractPriceFromTerms = (terms?: Terms, tax: number = 0) => {
  if (!terms) return '';
  const keys = Object.keys(terms);
  if (keys.length === 0) return '';
  return formatPrice(terms[keys[0]].price + tax);
};

const phoneRegex = /(\d{1,3})(\d{0,3})?(\d{0,2})?(\d{0,2})?/;
export const formatPhone = (value?: string) => {
  // console.log({ value });
  if (!value) return '';

  const m = deformatPhone(value).match(/^\+?[78](\d{1,10})/);
  const _value = m ? m[1] : value;
  const match = _value.match(phoneRegex);
  // console.log({ value, m, _value, match });
  if (match) {
    let result = '';
    if (match[1]) result = result + '+7 (' + match[1];
    if (match[2]) result = result + ') ' + match[2];
    if (match[3]) result = result + '-' + match[3];
    if (match[4]) result = result + '-' + match[4];
    // console.log({ result });
    return result;
  }
  return _value;
};

export const deformatPhone = (value: string) => {
  if (!value || value === '+7 (') return '';
  let cleanedPhone = value.replaceAll(/[\D]/g, '');
  if (!cleanedPhone.startsWith('7')) cleanedPhone = '7' + cleanedPhone;
  const formattedPhone = cleanedPhone.match(/(\d{1,11})/);
  return formattedPhone ? '+' + formattedPhone[1] : '';
};

interface Forms {
  0: string;
  1: string;
  2: string;
  5: string;
}

const getRightEnding = (total: number, forms: Forms) => {
  const t = total % 100;
  if (!t || t % 10 === 0) {
    return `${total} ${forms[0]}`; // 0, 10, 20...
  } else if (t % 10 === 1) {
    return `${total} ${forms[1]}`; // 1
  } else if (t % 10 < 5 && t % 10 > 0) {
    return `${total} ${forms[2]}`; // от 2 до 4
  } else {
    // значение по-умолчанию (от 5 до 20)
    return `${total} ${forms[5]}`;
  }
};
export const getSummPrice = (price:number| string, count: number)=>{
    const summPrice = Number(price) * count
    if (count)
    return summPrice.toLocaleString('ru-RU');

  }
  export const convertToTimestamp = (dateStr: string): number => {
  // Разделяем строку на день, месяц и год
  const [day, month, year] = dateStr.split('-').map(Number);

  // Создаем объект даты (месяцы в объекте Date нумеруются с 0)
  const date = new Date(year, month - 1, day);

  // Преобразуем дату в timestamp (в секундах)
  return Math.floor(date.getTime() / 1000);
};

export   const formatDates = (isRussianLanguage: boolean, dateFrom: string, dateTo: string): string => {

  const monthNames = [
    "января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
  ];
  const monthNamesEnglish = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


  // Функция для преобразования строки в формат даты
  const formatDate = (dateStr: string): { day: number, month: string } => {

    const [day, month, year] = dateStr.split('-').map(Number);
    return { day, month:isRussianLanguage? monthNames[month - 1]: monthNamesEnglish[month - 1] };
  };

  // Преобразуем обе даты
  const { day: dayFrom, month: monthFrom } = formatDate(dateFrom);
  const { day: dayTo, month: monthTo } = formatDate(dateTo);
  return `${dayFrom} ${monthFrom} - ${dayTo} ${monthTo}`;
};



export function getRightEndingTransfers(total: number) {
  const forms: Forms = i18n.t('main.transfers', { returnObjects: true });
  return getRightEnding(total, forms);
}

export function getRightEndingPassengers(total: number) {
  const forms: Forms = i18n.t('main.passengers', { returnObjects: true });
  return getRightEnding(total, forms);
}
export function getRightEndingGuests(total: number) {
  const forms = {
    0:  i18n.t('guestSelect.getRightEndingGuests.guests') ,
    1:  i18n.t('guestSelect.getRightEndingGuests.guest'),
    2:  i18n.t('guestSelect.getRightEndingGuests.guestsFew'),
    5:  i18n.t('guestSelect.getRightEndingGuests.guests'),
  };
  return getRightEnding(total, forms);
}

export const getNormalDate = (date: Date) => {
  return new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));
};

export const capitalize = (str: string) => str.charAt(0).toLocaleUpperCase() + str.substring(1);

export const isBetween = (a: number, b: number, date: number | Date) => {
  return a < b ? isAfter(date, a) && isBefore(date, b) : isAfter(date, b) && isBefore(date, a);
};

export const getNormalisedDate = (date: string | null | undefined) => {
  if (!date || isNaN(Date.parse(date))) return '';
  const originalDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat(i18n.language, options);
  return formatter.format(originalDate).replace(' г.', '');
};

export const getNormalisedDateWithoutYear = (date: string | null | undefined) => {
  if (!date || isNaN(Date.parse(date))) return '';
  const originalDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };
  const formatter = new Intl.DateTimeFormat(i18n.language, options);
  return formatter.format(originalDate);
};

export const isExpired = (order: IOrder): boolean => {
  const tripDepartureDate = order?.ticket?.trip?.segment?.[0]?.flight?.[0]?.departure_date ?? '';

  const dateArr = tripDepartureDate.split('.');
  const formattedDate = dateArr.reverse().join('-');

  return new Date() > new Date(formattedDate);
};
