import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Modal from '../Modal/Modal';
import styles from './PaymentHotelModal.module.scss';
import { required } from '../../../utils/formValidation';
import Input from '../../Input/Input';
import Button from '../../Button';
import { modalSliceSelector } from '../../../store/slices/modal';
import { bookingSelector } from '../../../store/slices/booking';
import Checkbox from '../../Input/Checkbox/Checkbox';
import { userSelector } from '../../../store/slices/user';
import { IOrderPaymentType, ORDER_PAYMENT_TYPE } from '../../../types/order';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { searchHotelSelector } from '../../../store/slices/searchHotels';
import { format } from 'date-fns';
import i18n from 'i18next';
import { enUS, ru } from 'date-fns/locale';
import { useCreatePaymentHotelMutation } from '../../../store/api/searchHotelService';
import { CircularProgress } from '@mui/material';
import { getSummPrice } from '../../../utils/formatting';
import { links } from '../../../constants/links';
import { routes } from '../../../constants/routes';
import { LangLink } from '../../LangLink/LangLing';

declare const window: Window &
  typeof globalThis & {
  Verbox: any;
};

const StyledAccordion = styled(Accordion)`
  border-radius: 8px;
  background-color: #fff4e3;
  border-top: none;
  margin-bottom: 20px;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.9px;
`;

const StyledLink = styled.a`
  cursor: pointer;
`;
const PaymentHotelModal: FunctionComponent = () => {
  const [createPaymentHotel, { error, isLoading }] = useCreatePaymentHotelMutation();
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { successHotel } = useSelector(modalSliceSelector);
  const { city, persons, persons_count, dateStart, dateEnd, id } = useSelector(searchHotelSelector);
  const [agreement, setAgreement] = useState(true);
  const locale = i18n.language.startsWith('ru') ? ru : enUS;
  const isRussianLanguage = i18n.language.startsWith('ru');


  const BASE_PRICE = (process.env.REACT_APP_BASE_PRICE_HOTEL || 1399);
  const BASE_PRICE_EURO = (process.env.REACT_APP_BASE_PRICE_HOTEL_EURO || 17);




  const handlePayment = async (data: any) => {
    const type: IOrderPaymentType = data.type;
    if (id !== null)
      createPaymentHotel({ order_id: id, type })
        .unwrap()
        .then(response => {
          if (typeof response.data === 'string') {
            if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
              window.location.assign(response.data);
              location.href = response.data;
            } else {
              setTimeout(
                url => {
                  window.open(url, '_blank');
                },
                1,
                response.data
              );
            }
          } else {
            alert(response);
          }
        })
        .catch(console.error);

  };

  // const passengersCount = previewOrder.passengersCount ?? previewOrder.passengers.length ?? 0;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const daysOfWeekShort: { [key: string]: string } = {
    'понедельник': 'пн',
    'вторник': 'вт',
    'среда': 'ср',
    'четверг': 'чт',
    'пятница': 'пт',
    'суббота': 'сб',
    'воскресенье': 'вс'
  };
  const daysOfWeekShortEng: { [key: string]: string } = {
    'Monday': 'mon',
    'Tuesday': 'tue',
    'Wednesday': 'wed',
    'Thursday': 'thu',
    'Friday': 'fri',
    'Saturday': 'sat',
    'Sunday': 'sun'
  };


  const formatWithCustomDay = (date: number) => {
    const formattedDate = format(date, 'dd MMMM, EEEE', { locale: locale });
    const dayOfWeekFull = format(date, 'EEEE', { locale: locale });
    return formattedDate.replace(dayOfWeekFull, isRussianLanguage ? daysOfWeekShort[dayOfWeekFull] : daysOfWeekShortEng[dayOfWeekFull]);
  };


  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal isOpen={successHotel}>
      <div className={styles.payment_modal}>
        <h1>{t('modals.paymentHotelModal.header')}</h1>
        <p className={styles.headerInfo}>{t('modals.paymentModalHotelAbout.orderDetails')}</p>
        <div className={styles.bookingInfo}>
          <div className={styles.containerInfo}>
            <div className={styles.detail}>
              <p className={styles.label}>{t('modals.paymentModalHotelAbout.orderNumber')}</p>
              <p className={styles.infoColumn}>№{id}</p>
            </div>
            <div className={styles.detail}>
              <p className={styles.label}>{t('modals.paymentModalHotelAbout.directionTo')}</p>
              <p className={styles.infoColumn}>{isRussianLanguage ? city?.city : city?.city_en}</p>
            </div>
            <div className={styles.detail}>
              <p className={styles.label}>{t('modals.paymentModalHotelAbout.guestsCount')}</p>
              <div className={styles.guestContainer}>
                <img src="/icons/men.svg" alt="man icon" className={styles.manIcon} />
                <p className={styles.infoColumn}>{persons_count}</p>
              </div>
            </div>
          </div>
          <div className={styles.containerInfo}>
            <div className={styles.detail}>
              <p className={styles.label}>{t('modals.paymentModalHotelAbout.orderAmount')}</p>
              <p className={styles.infoColumn}>
                {currency === 'rub' ?
                  `${getSummPrice(BASE_PRICE, persons_count)} ₽`
                  :
                  `${getSummPrice(BASE_PRICE_EURO, persons_count)} €`
                }
              </p>
            </div>
            <div className={styles.detail}>
              <p className={styles.label}>{t('modals.paymentModalHotelAbout.departureDateBack')}</p>
              <p className={styles.infoColumn}>
                {dateEnd && dateStart
                  ? `${formatWithCustomDay(dateStart)} - ${formatWithCustomDay(dateEnd)}`
                  : ''}
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.bookingGuests} ${isOpen ? styles.open : ''}`}>
          {persons && persons.map((person, index) => (
            <div key={index}
                 className={styles.guestsName}>{`${person.first_name?.toUpperCase()} ${person.last_name?.toUpperCase()}`}</div>
          ))}
        </div>
        <button className={styles.toggleButton} onClick={toggleDetails}>
          {isOpen ?
            <div>
              {t('modals.paymentModalHotelAbout.buttonCollapse')}
              <img className={styles.collapseImg} src="/icons/collapse-icon-purple.svg" alt="collapse-icon-purple" />
            </div>
            :
            <div>
              {t('modals.paymentModalHotelAbout.buttonExpand')}
              <img className={`${styles.collapseImg} ${styles.expand}`} src="/icons/collapse-icon-purple.svg"
                   alt="collapse-icon-purple" />
            </div>
          }
        </button>
        <p className={styles.variantPay}>{t('modals.paymentModalHotelAbout.variantsPay')}</p>
        <form
          onSubmit={handleSubmit(handlePayment)}
        >
          <Controller
            name="type"
            control={control}
            rules={{ required }}
            render={({ field }) => (
              <>
                {currency === 'rub' ?
                  <>
                    <Input
                      hotels={true}
                      {...field}
                      checked={field.value === ORDER_PAYMENT_TYPE.CARD_RU}
                      value={ORDER_PAYMENT_TYPE.CARD_RU}
                      label={t('modals.paymentModal.cardRuLabel')}
                      description={t('modals.paymentModal.cardRuDescription')}
                      type={'radio'}
                      className={styles.payment_modal_radio}
                      error={errors.type?.message}
                    />
                    <Input
                      hotels={true}
                      {...field}
                      checked={field.value === ORDER_PAYMENT_TYPE.SBP}
                      value={ORDER_PAYMENT_TYPE.SBP}
                      label={t('modals.paymentModal.sbpLabel')}
                      description={t('modals.paymentModal.sbpDescription')}
                      type={'radio'}
                      className={styles.payment_modal_radio}
                      error={errors.type?.message}
                    />
                  </>
                  :
                  <Input
                    hotels={true}
                    {...field}
                    checked={field.value === ORDER_PAYMENT_TYPE.CARD}
                    value={ORDER_PAYMENT_TYPE.CARD}
                    label={t('modals.paymentModal.cardLabel')}
                    description={t('modals.paymentModal.cardDescription')}
                    type={'radio'}
                    className={styles.payment_modal_radio}
                    error={errors.type?.message}
                  />
                }
              </>
            )}
          />
          {errors.type ? (
            <div className={styles.error}>
              {t('modals.paymentModal.errorSelectPayment')}
            </div>
          ) : null}
          {currency === 'rub' ?
            <div>
              <div className={styles.payment_modal_sum + ' ' + styles.payment_modal_sum_first}>
                <p className={styles.payment_modal_sum_label}>{t('modals.paymentModal.rublesPaymentLabel')}</p>
                <p className={styles.payment_modal_sum_value}>
                  {getSummPrice(BASE_PRICE, persons_count)} ₽
                </p>
              </div>
            </div>
            :
            <div>
              <div className={styles.payment_modal_sum + ' ' + styles.payment_modal_sum_second}>
                <p>{t('modals.paymentModal.euroPaymentLabel')}</p>
                <p className={styles.payment_modal_sum_value}>
                  {getSummPrice(BASE_PRICE_EURO, persons_count)} €
                </p>
              </div>
            </div>

          }
          <StyledAccordion
            sx={{
              '&::before': {
                display: 'none'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<img alt="" src="/icons/arrow-hollow-down.svg" />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <img alt="" src="/icons/danger.svg" />{' '}
              <Typography
                marginLeft={1}>{currency === 'rub' ? t('modals.paymentModal.howToPayInEuro') : t('modals.paymentModal.howToPayInRub')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledTypography>
                {currency === 'rub' ? t('modals.paymentModal.howToPayInEuroDetails') : t('modals.paymentModal.howToPayInRubDetails')}
              </StyledTypography>
            </AccordionDetails>
          </StyledAccordion>

          <Button hotels={true} type="submit" className={styles.payment_modal_button} disabled={!agreement}>
            {
              isLoading ? <CircularProgress color={'inherit'} size={20} /> : `${t('modals.paymentModal.payButton')}`
            }
          </Button>

          <Controller
            name="agreement"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={agreement}
                className={styles.checkbox}
                onChange={e => setAgreement(e.target.checked)}
                hotels={true}
              >
                <div className={styles.agreementText}>
                  {t('modals.paymentModal.agreementTextPart1')}
                  <LangLink to={routes.confedential_policy} target="_blank" className={styles.link}>
                    {t('modals.paymentModal.agreementLink1')}
                  </LangLink>
                  {t('modals.paymentModal.agreementTextPart2')}
                  <LangLink to={routes.safety_return_policy} target="_blank" className={styles.link}>
                    {t('modals.paymentModal.agreementLink2')}
                  </LangLink>
                  ,
                  <LangLink to={routes.rules} target="_blank" className={styles.link}>
                    {t('modals.paymentModal.agreementLink3')}
                  </LangLink>
                  .
                </div>
              </Checkbox>
            )}
          />
        </form>
      </div>
    </Modal>
  );
};

export default PaymentHotelModal;
