import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {Story} from "../../types/stories";
import {BASE_URL} from "../../utils/api";

const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

export const storiesApi = createApi({
  reducerPath: 'storiesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: builder => ({
    getStories: builder.query<{ data: Story[] }, { token: string }>({
      query: ({ token }) => ({
        url: 'stories',
        headers: { Accept: 'text/html', Authorization: `Bearer ${token}` },
        params: {}
      }),
    }),
  }),
});

export const { useGetStoriesQuery } = storiesApi;
