import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { checkValidEmail, checkValidPhone, emailRegex } from '../../../../utils/formValidation';
import Card from '../../../Card/Card';
import Input from '../../../Input/Input';
import styles from './ContactDetailsHotels.module.scss';
import { useDebounce } from '../../../../utils/useDebounce';
import { useCheckEmailExistsMutation } from '../../../../store/api/userService';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { searchHotelSelector, setEmail, setPhone } from '../../../../store/slices/searchHotels';
import { useAppDispatch } from '../../../../store/store';




interface Props {
  user?: any;
}

const ContactDetailsHotels: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setError,
    getFieldState,
    reset
  } = useFormContext();



  const [checkEmail] = useCheckEmailExistsMutation();
  const [email, setValue] = useState<string>(user.email ?? '');
  const debouncedValue = useDebounce<string>(email, 200);
  const createLK = getFieldState('createLK').isDirty ?? false;
  const dispatch = useAppDispatch()
  const { email: emailUser,phone } = useSelector(searchHotelSelector);




  const rules = {
  email: {
    required: i18n.t('contactDetails.required'),
    validate: checkValidEmail

  },
  phone: {
    required: i18n.t('contactDetails.required'),
    validate: checkValidPhone
  }
};


  useEffect(() => {
    reset({
      email: emailUser || user.email || '',
      phone: phone || user.phone || ''
    });
    dispatch(setEmail(user.email))
  }, []);

  useEffect(() => {
    if (emailRegex.test(debouncedValue.trim()) && createLK) {
      if (user.email === debouncedValue) return;
      // checkValidEmail();
    }
  }, [debouncedValue, user]);


  return (
    <Card>
      <div className={styles.headingsubtitle3}>
        <div className={styles.header}>{t('contactDetails.contactDetails')}</div>
        <div className={styles.description}>
          {t('contactDetails.contactDetailsDescription')}
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.wrapper}>
          <Controller
            name="email"
            control={control}
            rules={rules?.email}
            render={({ field }) =>
              <Input
                hotels={true}
                {...field}
                noCapitalize={true}
                error={errors.email?.message}
                type="text"
                label="E-mail"
                onChange={(e) => {
                  dispatch(setEmail(e.target.value))
                  setValue(e.target.value);
                  field.onChange(e.target.value);
                }}
              />}
          />
        </div>
        <div className={styles.wrapper}>
          <Controller
            name="phone"
            control={control}
            rules={rules?.phone}
            render={({ field }) =>
              <Input
                hotels={true}
                {...field}
                error={errors.phone?.message}
                type="phoneHotels"
                label={t('contactDetails.phone')}
                onChange={(e)=>{
                  dispatch(setPhone(e.target.value))
                  field.onChange(e.target.value);
                }}
              />
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default ContactDetailsHotels;
