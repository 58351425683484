import { FunctionComponent } from 'react';
import styles from './MobileNavigation.module.scss';
import Footer from '../../Footer/Footer';
import LoginButton from '../LoginButton/LoginButton';
import {useTranslation} from "react-i18next";
import { LangLink } from '../../LangLink/LangLing';

type Props = {
  handleSwitchMenu: () => void;
  handleSwitchMobileLKMenu: any;
};



const MobileNavigation: FunctionComponent<Props> = ({ handleSwitchMenu, handleSwitchMobileLKMenu }) => {
  const { t } = useTranslation();

  const links = [
    { label: t('links.tariff'), href: '/tariff' },
    { label: t('links.help'), href: '/help' },
    { label: t('links.blog'), href: '/blog' },
    { label: t('links.feedback'), href: '/feedback' },
  ];

  return (
    <div className={styles.mobile_menu}>
      <nav>
        {links.map(link => (
          <div key={link.label} className={styles.link}>
            <LangLink onClick={handleSwitchMenu} to={link.href}>{link.label}</LangLink>
          </div>
        ))}
        <LoginButton variant={'blue'} type={'mobile'} className={styles.mobile_menu_btn} onClick={handleSwitchMobileLKMenu} />
      </nav>
    </div>
  );
};

export default MobileNavigation;
