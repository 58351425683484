import { ReactNode, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styles from './TypeHotel.module.scss'
import Card from '../Card/Card';
import Select from '../Input/Select/Select';
import { setTypeHotel } from '../../store/slices/searchHotels';
import { useAppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { HotelType } from '../../types/hotelType';



interface TypeHotelProps {
  className?: string;
  children?: ReactNode;
}

type FormValues = {
  hotelType: string;
};


export const TypeHotel = (props: TypeHotelProps) => {
  const {
    className,
    children,
    ...otherProps
  } = props;

  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
  const dispatch =useAppDispatch()
  const {t} = useTranslation()

  const translatedSortOptions: HotelType[] = t('placeOrderHotelPage.typeHotel.sortOptions', { returnObjects: true });
  const [sort, setSort] = useState(translatedSortOptions[0].value);

  const handleSortChange = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
    value: string | null
  ) => {
    setSort(value || '');
    if(value){
      dispatch(setTypeHotel(value))
    }

  };


  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.warningBox}>
          <div className={styles.wrapperIcon}>
            <img className={styles.warningIcon} alt="warning" src="/icons/warning.svg" />
          </div>
          <div>
            <p>{t('placeOrderHotelPage.typeHotel.header')}</p>
            <ul>
              <li>{t('placeOrderHotelPage.typeHotel.paragraph1')}</li>
              <li>{t('placeOrderHotelPage.typeHotel.paragraph2')}</li>
              <li>{t('placeOrderHotelPage.typeHotel.paragraph3')}</li>
              <li>{t('placeOrderHotelPage.typeHotel.paragraph4')}</li>
            </ul>
            <p>{t('placeOrderHotelPage.typeHotel.description')}</p>
          </div>
        </div>
        <Select onChange={handleSortChange} label={t('placeOrderHotelPage.typeHotel.label')} value={sort} options={translatedSortOptions} />

      </div>


    </Card>
  );
};
