import { Link, LinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const LangLink = ({ to, ...props }: LinkProps ) => {

  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const newPath = isEnglish ? `/en${to}` : to;

  return <Link to={newPath} {...props} />;
}
