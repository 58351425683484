const SALT = process.env.REACT_APP_COOKIE_SALT || 'REACT_APP_COOKIE_SALT';

export function getCookie(key: string) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  const value = matches ? matches[1] : null;
  let cookie;
  if (!value) return null;
  try {
    cookie = decodeURIComponent(atob(value));
  } catch (e) {
    return null;
  }
  if (!cookie.includes(SALT)) return null;
  const desalted = cookie.replace(SALT, '');
  return !desalted || desalted === 'undefined' ? null : desalted;
}

export function setCookie(key: string, value: string, maxAge: number | string = 1209600, path: string = '/') {
  const encryptedData = btoa(encodeURIComponent(value + SALT));
  document.cookie = `${key}=${encryptedData}; path=${path}; max-age=${maxAge}; secure;`;
}

export function deleteCookie(key: string) {
  document.cookie = `${key}=""; path=/; max-age=-1; secure;`;
}
