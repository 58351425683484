import { ChangeEventHandler, forwardRef, Fragment, FunctionComponent } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import styles from './TabButtons.module.scss';

type Props = {
  options: { value: string; label: string }[];
  onChange: (value: any) => void;
  value?: string;
  name?: string;
  label?: string;
  error?: string;
};
const TabButtons: FunctionComponent<Props> = forwardRef<HTMLDivElement, Props>(
  ({ options, value, name, label, error, onChange }, ref) => {
    return (
      <div className={styles.root} ref={ref}>
        {label ? <span className={styles.title}>{label}</span> : null}
        <div className={styles.tabButtons}>
          {options.map(option => (
            <Fragment key={option.value}>
              <input
                onChange={(e) => onChange(e.target.value)}
                name={name}
                id={name + option.value}
                value={option.value}
                type="radio"
                className={styles.input}
                checked={value === option.value}
              />
              <label htmlFor={name + option.value} className={styles.label}>
                {option.label}
              </label>
            </Fragment>
          ))}
        </div>
        {error ? <span className={styles.error}>{error}</span> : null}
      </div>
    );
  }
);

export default TabButtons;
