import { FunctionComponent, ReactNode } from 'react';
import HeaderCatalogTickets from '../../../components/HeaderCatalogTickets';
import Footer from '../../../components/Footer/Footer';
import FilterSidebar from '../../../components/Filter/FilterSidebar';
import styles from './PublicLayout.module.scss';
import { useSelector } from 'react-redux';
import { searchSelector } from '../../../store/slices/search';

interface LayoutProps {
  loading?: boolean;
  filter?: boolean;
  search?: boolean;
  height?: number;
  variant?: 'light' | 'dark';
  isWhite?: boolean;
  children: ReactNode | ReactNode[];
}

const PublicLayout: FunctionComponent<LayoutProps> = ({
  loading = false,
  filter = true,
  search = true,
  height,
  variant,
  isWhite,
  children,
}) => {
  const { isLoading } = useSelector(searchSelector);
  if (isLoading) {
    loading = true;
  }
  return (
    <div className={styles.root}>
      <HeaderCatalogTickets search={search} height={height} variant={variant} />
      <div className={isWhite ? `${styles.main} ${styles.main__white}` : `${styles.main}` }>
        <div className={styles.content}>
          {filter ? <FilterSidebar loading={loading} /> : null}
          <div className={styles.children}>{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PublicLayout;
