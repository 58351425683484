import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import InfoMessage from '../../InfoMessage/InfoMessage';
import {
  closeStory,
  nextSlide,
  nextStory,
  prevSlide,
  prevStory,
  storiesSelector
} from '../../../store/slices/stories';
import { useAppDispatch } from '../../../store/store';
import { Story } from '../../../types/stories';
import styles from './ScrollStories.module.scss';
import Button from '../../Button';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import { useLangNavigate } from '../../../utils/langNavigate';

type ScrollStoriesType = {
  story?: Story;
  onClose?: () => void;
};

const SHOW_SLIDE_TIMEOUT = 10000; // 10 секунд

const ScrollStories: FunctionComponent<ScrollStoriesType> = ({ story, onClose }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const { activeSlide, activeStory } = useSelector(storiesSelector);
  let interval = useRef<NodeJS.Timer | null>(null);
  if (!story || activeSlide === null) return null;
  const {t} = useTranslation();
  const langNavigate = useLangNavigate();

  const clear = () => {
    if (!interval.current) return;
    // @ts-ignore
    clearInterval(interval?.current);
    setLoading(0);
  }

  const setSlideInterval = () => {
    clear();
    interval.current = setInterval((r) => {
      if (r <= 100) {
        setLoading(s => s + 1);
      }
    }, (SHOW_SLIDE_TIMEOUT / 100), loading);
  }

  const showNextStory = useCallback(() => {
    dispatch(nextStory());
    setSlideInterval();
  }, []);
  const showPrevStory = useCallback(() => {
    dispatch(prevStory());
    setSlideInterval();
  }, []);

  const showNextSlide = useCallback(() => {
    dispatch(nextSlide());
    setSlideInterval();
  }, []);
  const showPrevSlide = useCallback(() => {
    dispatch(prevSlide());
    setSlideInterval();
  }, []);

  const handleDetailsClick = () => {
      if (story.details) {
          (story.details.startsWith("http") || story.details.startsWith("https"))
              ? window.open(story.details, "_blank")
              : langNavigate(story.details)
      }
    dispatch(closeStory());
  };

  useEffect(() => {
    setSlideInterval();
    const timeout = setTimeout(() => showNextSlide(), SHOW_SLIDE_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [activeSlide, activeStory]);

  return (
    <div className={styles.root}>
      <button className={styles.scrollArrow} onClick={showPrevStory}>
        <img loading="lazy" className={styles.icons16px} alt="" src="/icons/chevronLeftWhite.svg" />
      </button>
      <div className={styles.stories}>
        <div className={styles.storiesContent}>
          <img loading="lazy" className={styles.image} alt="" src={story.slides[activeSlide].image} />
          <div className={styles.contenbutton}>
            <div className={styles.storiesTextBG}>
              <div className={styles.storiesText}>
                <div className={styles.title}>{(!i18n.language.startsWith('ru') && story.slides[activeSlide].header_en) || story.slides[activeSlide].header}</div>
                <div className={styles.subtitle}>{(!i18n.language.startsWith('ru') && story.slides[activeSlide].text_en) || story.slides[activeSlide].text}</div>
              </div>
            </div>
            <div className={styles.slideSwithers}>
              <div className={styles.slideSwithersWrapper}>
                <div className={styles.prev} onClick={showPrevSlide} />
                <div className={styles.next} onClick={showNextSlide} />
              </div>
            </div>
            {story.details ? (
              <Button className={styles.button} variant="contained" onClick={handleDetailsClick}>
                {t('scrollStories.moreDetails')}
              </Button>
            ) : null}

            <div className={styles.stageClosed}>
              <div className={styles.stageStories}>
                {story.slides.map((_, index) => (
                  <div className={clsx(styles.storiesIndexBar, index <= activeSlide ? styles.active : '')}>
                    <div className={styles.storiesIndexBarContent} style={{ width: loading + '%'}} />
                  </div>
                ))}
              </div>
              <Button variant="text" className={styles.close} onClick={onClose}>
                <img src="/icons/closeThin.svg" alt="" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <button className={styles.scrollArrow} onClick={showNextStory}>
        <img className={styles.icons16px} alt="" src="/icons/chevronRightWhite.svg" />
      </button>
    </div>
  );
};

export default ScrollStories;
