import { TextField } from '@mui/material';
import { forwardRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { InputProps } from '../Input';
import styles from '../Input.module.scss';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const PassportInput = forwardRef<HTMLDivElement, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.formattedValue } });
      }}
      format="#########"
      mask="_"
    />
  );
});

const PassportLocalInput = forwardRef<HTMLDivElement, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({ target: { name: props.name, value: values.formattedValue } });
      }}
      format="##########"
      mask="_"
    />
  );
});

const DocumentInput = forwardRef((props: InputProps, ref) => {
  return (
    <div className={styles.wrapper}>
      <TextField
        inputRef={ref}
        autoFocus={props.autoFocus}
        error={!!props.error}
        onChange={props.onChange}
        value={props.value?.toLocaleString().toLocaleUpperCase()}
        required={props.required}
        label={props.label}
        name={props.name}
        variant="filled"
        helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
        className={styles.input}
        type="text"
        autoComplete="new-password"
        InputProps={
          props.type === 'document'
            ? undefined
            : props.type === 'passport_passport'
            ? {
                inputComponent: PassportInput as any,
              }
            : {
                inputComponent: PassportLocalInput as any,
              }
        }
      />
    </div>
  );
});

export default DocumentInput;
