import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Review } from "../../types/review";
import { RootState } from '../store';

interface ReviewsState {
    reviews: Review[];
    reviewsCount: number;
}

const initialState: ReviewsState = {
    reviews: [],
    reviewsCount: 0,
};

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        setReviews: (state, action: PayloadAction<Review[]>) => {
            state.reviews = action.payload;
        },
        setReviewsCount: (state, action: PayloadAction<number>) => {
            state.reviewsCount = action.payload;
        },
        addReview: (state, action: PayloadAction<Review>) => {
            state.reviews.unshift(action.payload);
            state.reviewsCount += 1;
        },
    },
});

export const { setReviews, setReviewsCount, addReview } = reviewsSlice.actions;
export const reviewsSelector = (state: RootState) => state.reviews;

export default reviewsSlice.reducer;