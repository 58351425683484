import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IDatePicker {
  from: number | null;
  to: number | null;
  isOpen: boolean;
  currentOpen: 'from' | 'to' | null;
}

export type ISetFromToPayload = { from?: number | null, to?: number | null };
export type ISetIsOpenPayload = { isOpen: boolean };
export type ISetCurrentOpenPayload = { currentOpen: 'from' | 'to' | null };

const initialState: IDatePicker = { from: null, to: null, isOpen: false, currentOpen: null };

const checkIfFromEarlier = (from: number | null, to: number | null): boolean => {
  // console.log({ from, to }, from && to && from < to);
  if (from && to) {
    return from <= to;
  }
  // just return true, if one of dates is not set
  return true;
};

const datePickerSlice = createSlice({
  name: 'DatePickerHotels',
  initialState,
  reducers: {
    setFrom: (state, { payload: { from } }: PayloadAction<ISetFromToPayload>) => {
      const _from = from || null;
      const areDatesCorrect = checkIfFromEarlier(_from, state.to);
      const newState = areDatesCorrect ? { ...state, from: _from } : { ...state, to: _from, from: state.to };
      // console.log({ setFrom: '', newState });
      return newState;
    },
    setTo: (state, { payload: { to } }: PayloadAction<ISetFromToPayload>) => {
      const _to = to || null;
      const areDatesCorrect = checkIfFromEarlier(state.from, _to);
      const newState = areDatesCorrect ? { ...state, to: _to } : { ...state, to: state.from, from: _to };
      // console.log({ setTo: '', newState });
      return newState;
    },
    setFromTo: (state, { payload: { from, to } }: PayloadAction<ISetFromToPayload>) => {
      const _from = from || null;
      const _to = to || null;
      const areDatesCorrect = checkIfFromEarlier(_from, _to);
      const newState = areDatesCorrect ? { ...state, from: _from, to: _to } : { ...state, to: _from, from: _to };
      // console.log({ setTo: '', newState });
      return newState;
    },
    setIsOpen: (state, { payload: { isOpen } }: PayloadAction<ISetIsOpenPayload>) => ({ ...state, isOpen }),
    setCurrentOpen: (state, { payload: { currentOpen } }: PayloadAction<ISetCurrentOpenPayload>) => ({
      ...state,
      currentOpen,
    }),
    reset: () => initialState,
  },
});

export default datePickerSlice.reducer;
export const { setFrom, setTo, setFromTo, setIsOpen, setCurrentOpen, reset } = datePickerSlice.actions;

export const dateRangeSelectorHotels = (state: RootState) => state.datePickerHotels;
