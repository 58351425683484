import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './PlaceOrderHotelSidebar.module.scss';
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import { CircularProgress } from '@mui/material';
import Button from '../Button';
import { searchHotelSelector } from '../../store/slices/searchHotels';
import { getRightEndingGuests } from '../../utils/formatting';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  hasBooking?: boolean;
  isLoading: boolean;
}


const PlaceOrderHotelSidebar: FC<Props> = ({ className, hasBooking, isLoading }) => {
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');
  const {t} = useTranslation()

  const [isOpen, setIsOpen] = useState(true);
  const { persons_count } = useSelector(searchHotelSelector);
  const BASE_PRICE = (process.env.REACT_APP_BASE_PRICE_HOTEL || 1399);
  const BASE_PRICE_EURO = (process.env.REACT_APP_BASE_PRICE_HOTEL_EURO || 17);



  const getSummPrice = (price: number | string) => {
    const summPrice = Number(price) * persons_count;
    return summPrice.toLocaleString('ru-RU');

  };


  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.order}>
        <div className={styles.basket}>
          <div className={styles.basketHeading}>
          </div>

          <Collapse className={styles.guests} in={isOpen}>
            <div className={styles.containerGuests}>
              <div>{getRightEndingGuests(persons_count)}</div>
              {currency == 'rub' ?
                <div>{getSummPrice(BASE_PRICE)} ₽</div>
                :
                <div>{getSummPrice(BASE_PRICE_EURO)} €</div>
              }
            </div>
            <div className={styles.detailSumm}>
              <div>{t('placeOrderHotelPage.sidebar.headerDetail')}</div>
              {currency === 'rub' ?
              <div>{BASE_PRICE} ₽</div>
                :
              <div>{BASE_PRICE_EURO} €</div>
              }

            </div>
          </Collapse>
        </div>
        <div className={styles.summary}>
          {currency === 'rub' ?
            <div className={styles.totalPrice}>
              {t('placeOrderHotelPage.sidebar.paymentInRubles')}
              <span>
                {getSummPrice(BASE_PRICE)} ₽
              </span>
            </div>
            :
            <div className={styles.totalPriceEur}>
              {t('placeOrderHotelPage.sidebar.paymentInEuros')}
              <span>
                {getSummPrice(BASE_PRICE_EURO)} €
              </span>
            </div>

          }

        </div>
        <div className={styles.warning}>
          <img className={styles.iconWarning} alt="" src="/icons/attention.svg" />
          {t('placeOrderHotelPage.sidebar.validFor14Days')}
        </div>
      </div>
      <div className={styles.button}>
        <Button hotels={true} variant="contained" type="submit" className={styles.button}>
          {
            isLoading ? <CircularProgress color={'inherit'} size={20} /> : `${t('placeOrderHotelPage.sidebar.amountDue')}`
          }
        </Button>
      </div>
    </div>
  );
};

export default PlaceOrderHotelSidebar;
