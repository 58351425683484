import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProposalsEntity } from '../../types/backend';
import { IOrder } from '../../types/order';
import { IPassenger } from '../../types/passenger';
import { ITicket } from '../../types/ticket';
import { RootState } from '../store';

interface IBookingSlice {
  ticket: ITicket | null;
  trip: ProposalsEntity | null;
  back?: ProposalsEntity | null;
  passengers: IPassenger[];
  passengersSuggestion: IPassengersSuggestion;
  previewOrder?: IOrder | null;
  defaultValues?: any;
  isLoading: boolean;
}

export interface IPassengersSuggestion {
  adult: ( IPassenger | undefined )[];
  child: ( IPassenger | undefined )[];
  baby: ( IPassenger | undefined )[];
}

const initialState: IBookingSlice = {
  ticket: null,
  trip: null,
  back: null,
  passengers: [],
  passengersSuggestion: {
    adult: [], child: [], baby: [],
  },
  isLoading: false
};

export type ISetTicketPayload = {
  ticketEntry: { ticket: ITicket | null; trip: ProposalsEntity | null; back?: ProposalsEntity | null };
};
export type ISetPassengersPayload = { passengers: IPassenger[] };
export type ISetPassengersSuggestionPayload = { passengers: IPassengersSuggestion };
export type ISetPreviewOrderPayload = { order: IOrder | null; defaultValues?: any };
export type ISetPreloaderPayload = { value: boolean };

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setTicket: (state, { payload: { ticketEntry } }: PayloadAction<ISetTicketPayload>) => {
      state.ticket = ticketEntry.ticket;
      state.trip = ticketEntry.trip;
      state.back = ticketEntry.back;
    },
    setPassengers: (state, { payload: { passengers } }: PayloadAction<ISetPassengersPayload>) => {
      state.passengers = passengers;
    },
    setPassengersSuggestion: (state, { payload: { passengers } }: PayloadAction<ISetPassengersSuggestionPayload>) => {
        state.passengersSuggestion = passengers;
    },
    setPreviewOrder: (state, { payload: { order, defaultValues } }: PayloadAction<ISetPreviewOrderPayload>) => {
      state.previewOrder = order;
      state.defaultValues = defaultValues;
    },
    setOrderLoading: (state, { payload: { value } }: PayloadAction<ISetPreloaderPayload>) => {
      state.isLoading = value;
    }
  },
});

export const { setTicket, setPassengers, setPassengersSuggestion, setPreviewOrder, setOrderLoading } = bookingSlice.actions;
export default bookingSlice.reducer;

export const bookingSelector = (state: RootState) => state.booking;
