import {Cases} from "./backend";

export interface Airport {
  id?: number | string;
  code: string;
  country: string;
  state?: string | null;
  city: string;
  name: string;
  city_code: string;
  city_en?: string;
  name_en?: string;
  country_en?: string
  main_airport_name?: string | null;
  type?: string;
  weight?: number;
  cases?: Cases
}
export interface City {
  id?: number | string;
  code: string;
  city?: string;
  name?: string;
  city_en?: string;
  name_en?: string;
  country_en?: string;
  country?: string | null;
  cases?: Cases
}

export type TicketType = /*'any' | */'eco' | 'biz';

export const isAirport = (item: Airport | City): item is Airport => {
  return (item as Airport).name !== (item as Airport).city;
};

export interface Company {
  code: string;
  name: string;
  logo: string;
}
export interface Price {
  value: number;
  currency: string;
  symbol: string;
}

export interface RoutePoint {
  airport: Airport;
  date: number;
  date_text?: string;
  time?: string;
  number?: string | null;
}

export interface Transfer {
  airport: Airport;
  duration: number;
}

export interface Tag {
  text: string;
  color: 'success' | 'info' | 'warn' | 'error';
}

export interface Route {
  company: Company;
  from: RoutePoint;
  to: RoutePoint;
  duration: number;
  tag?: Tag;
  transfers?: Transfer[];
}

export interface ITicket {
  id: number | string;
  price: Price;
  to: {
    routes: Route[];
    total_duration?: number;
  };
  from?: {
    routes: Route[];
    total_duration?: number;
  };
  classTrip?: TicketType[];
  classBack?: TicketType[];
}

export interface ITicketFlightData {
  aircraft: string;
  flight_number: string | number;
  departure: string;
  departure_timestamp: number;
  departure_date: string;
  departure_time: string;
  arrival: string;
  arrival_timestamp: number;
  arrival_date: string;
  arrival_time: string;
  duration: number;
  delay: number;
  airline: string;
  class?: TicketType;
}

export interface ITicketData {
  id: string | number;
  stops: number;
  total_duration: number;
  airlines: string[];
  airports: string[];
  price: Price;
  departure_timestamp: number;
  departure_date: string;
  departure_time: string;
  arrival_timestamp: number;
  arrival_date: string;
  arrival_time: string;
  flight: ITicketFlightData[];
  is_direct?: boolean;
  is_charter?: boolean;
  tags?: string[] | null;
  operated_by_carrier: string | null;
  search_id?: string | null,
  terms_url_id?: number | null,
}

export interface ITicketBackend {
  to?: ITicketData | null;
  from?: ITicketData | null;
  price?: Price;
}

export interface ISummaryTicket {
  id: number | string;
  price: Price;
  to: {
    companies: Company[];
    routes: Omit<Route, 'Company'>[];
    total_duration?: number;
  };
  from?: {
    companies: Company[];
    routes: Omit<Route, 'Company'>[];
    total_duration?: number;
  };
}
