import { forwardRef, InputHTMLAttributes, MouseEventHandler, Ref } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import Checkbox from './Checkbox/Checkbox';
import DocumentInput from './DocumentInput/DocumentInput';
import styles from './Input.module.scss';
import PasswordInput from './PasswordInput/PasswordInput';
import PhoneInput from './PhoneInput/PhoneInput';
import RadioInput from './RadioInput/RadioInput';
import { formatDate } from '../../utils/order';

export type ErrorType =
  | string
  | FieldError
  | Partial<{ type: string | number; message: string }>
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;
export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  checkboxIcon?: JSX.Element;
  iconAfter?: string;
  error?: ErrorType;
  description?: string;
  noCapitalize?: boolean;
  onIconClick?: MouseEventHandler;
  hotels?: boolean
};

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  switch (props.type) {
    case 'text':
    case 'number':
    case 'email':
      const value =
        props.noCapitalize || props.type === 'email'
          ? props.value?.toLocaleString()
          : props.value?.toLocaleString().toLocaleUpperCase();
      return (
        <div className={styles.wrapper}>
          <TextField
            inputRef={ref}
            autoFocus={props.autoFocus}
            error={!!props.error}
            onChange={props.onChange}
            value={value}
            required={props.required}
            label={props.label}
            name={props.name}
            variant="filled"
            helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
            className={styles.input}
            type={props.type === 'email' ? 'text' : props.type}
            autoComplete="new-password"
            InputProps={{
              sx: props.hotels ? {
                '&.Mui-focused': {
                  border: '1px solid var(--purple-6100FF) !important'
                }
              } : {},
              classes: { root: props.readOnly ? styles.readOnly : '' },
              endAdornment: props.iconAfter ? (
                <InputAdornment className={styles.InputAdornment} position="start">
                  <IconButton
                    className={styles.passswordButton}
                    aria-label="toggle password visibility"
                    onClick={props.onIconClick}
                    edge="end"
                  >
                    <img src={props.iconAfter} />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }}
          />
        </div>
      );
    case 'textarea':
      return (
        <div className={styles.wrapper}>
          <TextField
            multiline
            minRows={3}
            maxRows={10}
            inputRef={ref}
            autoFocus={props.autoFocus}
            error={!!props.error}
            onChange={props.onChange}
            value={props.value}
            required={props.required}
            label={props.label}
            name={props.name}
            variant="filled"
            helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
            className={styles.input}
            type="text"
            autoComplete="new-password"
            InputProps={{
              sx: props.hotels ? {
                '&.Mui-focused': {
                  border: '1px solid var(--purple-6100FF) !important'
                }
              } : {}
            }}

          />
        </div>
      );
    case 'password':
      return <PasswordInput {...props} ref={ref} />;
    case 'passport_passport':
    case 'passport_local':
    case 'document':
      return <DocumentInput {...props} ref={ref} />;
    case 'phone':
      return <PhoneInput {...props} ref={ref} />;
    case 'phoneHotels':
      return <PhoneInput hotels={true} {...props} ref={ref} />;
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'radio':
      return <RadioInput {...props} ref={ref} />;
    case 'date':
      return (
        <TextField
          inputRef={ref}
          value={props.value}
          error={!!props.error}
          label={props.label}
          placeholder="ДД.ММ.ГГГГ"
          className={styles.input}
          onChange={props.onChange}
          helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
          type={'text'}
          variant="filled"
          disabled={props.disabled}
        />
      );
    default:
      return <input {...props} />;
  }
});

export default Input;
