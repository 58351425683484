import { FunctionComponent } from 'react';
import { updateModal } from '../../../store/slices/modal';
import { useAppDispatch } from '../../../store/store';
import styles from './LanguageButton.module.scss';
import clsx from 'clsx';

type Props = {
  variant?: 'light' | 'dark' | 'blue';
  className?: string;
  type?: string;
};

const LanguageButton: FunctionComponent<Props> = ({ variant, className, type }) => {
  const dispatch = useAppDispatch();
  const onButtonIconContainerClick = () => {
      dispatch(updateModal({ language: true }));
  };
  const style = variant === 'dark' ? styles.buttonIconDark : styles.buttonIconLight;

  return (
    <button
      type="button"
      className={clsx(style, type === 'mobile' ? styles.mobile : '', className)}
      onClick={onButtonIconContainerClick}
    >
      <img
        className={styles.icon}
        alt=""
        src={
          variant !== 'dark' ? '/icons/languageBlue.svg' : '/icons/language.svg'
        }
      />
    </button>
  );
};

export default LanguageButton;
