import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClickAwayListener, TextField } from '@mui/material';

import GuestsDropdown from './GuestsDropdown';
import styles from '../Select/Select.module.scss';
import { getRightEndingGuests, getRightEndingPassengers } from '../../../utils/formatting';
import { searchHotelSelector } from '../../../store/slices/searchHotels';
import { useTranslation } from 'react-i18next';


const GuestSelect: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { persons_count } = useSelector(searchHotelSelector);

  const {t} = useTranslation()
  const value = getRightEndingGuests(persons_count);

  const handleOpenModal = () => {
    setOpen(s => !s);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.PassengersSelect}>
        <TextField
          ref={ref}
          variant="filled"
          sx={{ p: 0, width: '100%' }}
          classes={{ root: styles.textField }}
          label={t('guestSelect.countGuests')}
          onClick={handleOpenModal}
          value={value}
          InputLabelProps={{ shrink: !!value }}
          InputProps={{
            readOnly: true,
            sx: {
              '&.Mui-focused': {
                border: '1px solid var(--purple-6100FF) !important'
              }
            }
          }}
        />
        <GuestsDropdown anchorRef={ref} open={open} id="passengersDropdown" />
      </div>
    </ClickAwayListener>
  );
};

export default GuestSelect;
