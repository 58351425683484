export const getLangMetaData = ( route: string) => {

  const location = window.location.origin

  return (
    <>
      <link rel="alternate" hrefLang="en"
            href={`${location}/en${route}`} />
      <link rel="alternate" hrefLang="ru"
            href={`${location}${route}`} />
      <link rel="alternate" hrefLang="x-default"
            href={`${location}${route}`} />
      <link rel="canonical" href={`${location}${route}`} />
    </>
  )
}
