import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../store';
import {IPost} from "../../types/post";

interface BlogState {
    posts: IPost[]|[];
    selectedPostId: number | null;
    selectedCategories: number[]|[];
    currentPage: number;
}

const initialState: BlogState = {
    posts: [],
    selectedPostId: null,
    selectedCategories: [],
    currentPage: 1
};

const blogsSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setPosts: (state, action: PayloadAction<IPost[]>) => {
            state.posts = action.payload;
        },
        setSelectedPostId: (state, action: PayloadAction<number | null>) => {
            state.selectedPostId = action.payload;
        },
        setSelectedCategories: (state, action: PayloadAction<number[]|[]>) => {
            state.selectedCategories = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        }
    },
});

export const selectedPostSelector = createSelector(
    (state: RootState) => state.blog.posts,
    (state: RootState) => state.blog.selectedPostId,
    (posts, selectedPostId) => {
        if (selectedPostId) {
            return posts.find(post => post.id === selectedPostId);
        }
        return null;
    }
);
export const { setPosts, setSelectedPostId, setSelectedCategories, setCurrentPage } = blogsSlice.actions;
export const postsSelector = (state: RootState) => state.blog.posts;
export const postSelector = (state: RootState) => state.blog.posts;
export const categoriesSelector = (state: RootState)  => state.blog.selectedCategories;

export default blogsSlice.reducer;