import { IconButton, InputAdornment, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { InputProps } from '../Input';
import styles from '../Input.module.scss';

const PasswordInput = forwardRef((props: InputProps, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={styles.wrapper}>
      <TextField
        inputRef={ref}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        value={props.value}
        required={props.required}
        error={!!props.error}
        helperText={typeof props.error === 'string' ? props.error : props.error?.message?.toString()}
        label={props.label}
        name="password"
        variant="filled"
        autoComplete="new-password"
        className={styles.input}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment className={styles.InputAdornment} position="start">
              <IconButton
                className={styles.passswordButton}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <img src="/icons/eyeCrossed.svg" /> : <img src="/icons/eye.svg" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
});

export default PasswordInput;
