import { parse } from 'date-fns';
import {
  citizenshipOptions,
  russiaDocumentOptions,
} from '../components/PassengerForm/components/PersonalDocument/PersonalDocument';
import { ISearchResult } from '../store/api/searchService';
import {
  Airlines,
  Airports,
  FlightEntity,
  ProposalsEntity,
  SegmentEntity,
  Terms, TermsEntitiy
} from '../types/backend';
import { IPassenger } from '../types/passenger';
import { Company, ITicket, ITicketData, Price, Route, Tag, TicketType } from '../types/ticket';
import { makeDate } from './formValidation';
import {getNormalDate} from "./formatting";

const getSymbol = (currency: string): string => {
  switch (currency) {
    case 'rub':
      return '₽';
    case 'usd':
      return '$';
    default:
      return '₽';
  }
};

const getCompany = (carrier: string, airlines: any): Company => {
  return {
    code: carrier,
    name: airlines[carrier]?.name ?? carrier,
    logo: `https://mpics.avs.io/al_square/64/64/${carrier}.png`,
  };
};

const getPrice = (trip: Terms, back?: Terms): Price => {
  const tripTerm = trip ? Object.values(trip)[0] : null;
  const backTerm = back ? Object.values(back)[0] : null;
  return {
    value: (tripTerm ? tripTerm.price : 0) + (backTerm ? backTerm.price : 0),
    currency: (tripTerm ? tripTerm.currency : 'rub'),
    symbol: (tripTerm ? tripTerm.symbol ?? getSymbol('rub') : getSymbol('rub'))
  };
};
const getTag = (tags?: string[] | null): Tag | undefined => {
  return tags?.length ? { text: tags[0], color: 'info' } : undefined;
};
const getRoutes = (proposal: ProposalsEntity, airports: any, airlines: any): Route[] => {
  const segment = proposal.segment?.[0];
  if (!segment || !segment.flight) return [];
  return segment.flight.map(flight => ({
    company: getCompany(proposal.operating_airline ?? flight.operated_by, airlines),
    from: {
      airport: airports[flight.departure] ?? { city_code: flight.departure, country: 'NULL', city: 'NULL', name: 'NULL' },
      date: flight.departure_timestamp * 1000,
      date_text: flight.departure_date,
      time: flight.departure_time,
      number: flight.number ?? null
    },
    to: {
      airport: airports[flight.arrival] ?? { city_code: flight.arrival, country: 'NULL', city: 'NULL', name: 'NULL' },
      date: flight.arrival_timestamp * 1000,
      date_text: flight.arrival_date,
      time: flight.arrival_time,
      number: flight.number ?? null
    },
    duration: flight.duration ?? 0,
    tag: getTag(proposal.tags),
  }));
};

const getOldFormatting = (item: ITicketData | null) => {
    if (item === null) return null;

    const classes: TicketType[] = item.flight.map((i) => <TicketType>(i.class));
    const terms: TermsEntitiy = {
        currency: item.price.currency,
        price: item.price.value,
        unified_price: item.price.value,
        url: item.price.value
    };
    const segment: SegmentEntity = {
        flight: item.flight.map((flight) => <FlightEntity>{
            aircraft: flight.aircraft,
            arrival: flight.arrival,
            arrival_date: flight.arrival_date,
            arrival_time: flight.arrival_time,
            arrival_timestamp: flight.arrival_timestamp,
            delay: flight.delay,
            departure: flight.departure,
            departure_date: flight.departure_date,
            departure_time: flight.departure_time,
            departure_timestamp: flight.departure_timestamp,
            duration: flight.duration,
            equipment: 'temp',
            local_arrival_timestamp: flight.arrival_timestamp,
            local_departure_timestamp: flight.departure_timestamp,
            marketing_carrier: flight.airline,
            number: flight.flight_number,
            operating_carrier: flight.airline,
            operated_by: flight.airline,
            rating: 0,
            trip_class: flight.class ?? 'eco',
            seats: 0
        })
    };
    const result: ProposalsEntity = {
        terms: { 'temp': terms },
        xterms: { 'temp': terms },
        segment: [ segment ],
        total_duration: item.total_duration ?? 0,
        stops_airports: item.airports ?? [],
        is_charter: item.is_charter ?? false,
        max_stops: item.stops ?? false,
        max_stop_duration: 0,
        min_stop_duration: 0,
        carriers: item.airlines ?? [],
        // segment_durations?: [ item.departure_timestamp, item.arrival_timestamp ];
        segments_time: item ? [ [ item.departure_timestamp, item.arrival_timestamp ] ] : null,
        segments_airports: item ? [ item.airports ] : null,
        sign: item.id ?? (Math.random() + '' + Math.random()).toString(),
        is_direct: item.is_direct ?? false,
        tags: item.tags ?? [],
        validating_carrier: item.airlines[0],
        class: classes,
        operating_airline: item.operated_by_carrier ?? null,
        search_id: item.search_id ?? null,
        terms_url_id: item.terms_url_id ?? null,
    };
    return result;
}

export const getOldDataFormat = (to: any, from: any) => {
  const trip: ProposalsEntity | null = getOldFormatting(to);
  const back: ProposalsEntity | null = getOldFormatting(from);
  return { trip, back }
}

export const getFrontendTicket = (
  airports: Airports | {},
  airlines: Airlines | {},
  tripEntity: ProposalsEntity,
  backEntity?: ProposalsEntity | null
): ITicket => {
  return backEntity?.segment
    ? {
      id: `${tripEntity.sign}_${backEntity.sign}`,
      price: getPrice(tripEntity.terms, backEntity.terms),
      to: { routes: getRoutes(tripEntity, airports, airlines), total_duration: tripEntity.total_duration },
      from: { routes: getRoutes(backEntity, airports, airlines), total_duration: backEntity.total_duration },
      classTrip: tripEntity?.class ? tripEntity.class : [],
      classBack: backEntity?.class ? backEntity?.class : []
    }
    : {
      id: tripEntity.sign,
      price: getPrice(tripEntity.terms),
      to: { routes: getRoutes(tripEntity, airports, airlines), total_duration: tripEntity.total_duration },
      classTrip: tripEntity?.class ? tripEntity.class : [],
      classBack: []
    };
};

export const ticketAdapter = (response: ISearchResult): ISearchResult => {
  if (!response) return {};
  return response;
};

const getCorrectFieldKey = (field: string) => {
  if (!field.includes('_')) return ['0', field];
  const [number, ...key] = field.split('_');
  return [number, key.join('_')];
};

export const trimIDFromFileds = (data: any) => {
  return Object.keys(data).reduce((prev: any, curr) => {
    const [id, newKey] = getCorrectFieldKey(curr);
    const dataItem = data[curr] === undefined ? '' : data[curr];
    if (['birth', 'validity'].includes(newKey) && dataItem) {
      const date = makeDate(dataItem);
      prev[newKey] = dateBackendAdapter(date.getTime());
    } else if (typeof dataItem === 'string') prev[newKey] = dataItem;
    else prev[newKey] = dataItem;
    return prev;
  }, {});
};

export const addIdToFields = (passenger?: IPassenger) => {
  if (!passenger)
    return {
      '0_citizenship': citizenshipOptions[0].value,
      '0_document': russiaDocumentOptions[0].value,
      '0_gender': 'male',
      '0_validity': '',
      '0_hasValidity': true,
    };
  const data = Object.keys(passenger).reduce((prev: any, curr) => {
    const key = curr.match(/^\d/) ? curr : `${passenger.id}_${curr}`;
    const dataItem = passenger[curr as keyof IPassenger] ?? '';
    const item = (curr === 'birth' || curr === 'validity') && dataItem ? parseDate(dataItem) : dataItem;
    return { ...prev, [key]: item };
  }, {});
  if (passenger.validity !== '' && passenger.validity != null && passenger.user_id > 0) {
      data[`${passenger.id}_hasValidity`] = true;
  }
  if (passenger.user_id === 0) {
    data[`${passenger.id}_citizenship`] = citizenshipOptions[0].value;
    data[`${passenger.id}_document`] = russiaDocumentOptions[0].value;
    data[`${passenger.id}_gender`] = 'male';
    data[`${passenger.id}_validity`] = '';
    data[`${passenger.id}_hasValidity`] = true;
  }
  return data;
};

export const parseDate = (date: string | number | boolean | null) => {
  let _date = new Date();
  if (!date || date === '') return null;
  if (typeof date === 'string' && date.match(/^\d{2}\.\d{2}\.\d{4}$/)) _date = parse(date, 'dd.MM.yyyy', new Date());
  if (typeof date === 'string' && date.match(/^\d{4}\-\d{2}\-\d{2}$/)) _date = parse(date, 'yyyy-MM-dd', new Date());
  if (typeof date === 'number') _date = new Date(date);
  return _date;
};

export const dateBackendAdapter = (date: Date | number | null) => {
  if (!date) return '';
  // console.log(new Date(date).toLocaleDateString('en'));
  return new Date(date).toLocaleDateString('ru').split('.').reverse().join('-');
};

export const getPassenger = (userPassengers: IPassenger[], passengerData: IPassenger) => {
  const userPassengerIndex = userPassengers.findIndex(p => p.type === passengerData.type);
  if (userPassengerIndex === -1) return passengerData;
  return userPassengers.splice(userPassengerIndex, 1)[0];
};

export const getPassengerById = (data: any, id?: number) => {
    const temp = JSON.parse(JSON.stringify(data));
    let p: { [key: string]: any } = {};
    for (const key in temp) {
        if (['id', 'user_id', 'email', 'phone', 'patronymic'].includes(key)) continue;
        const newKey = (id ? id : (data && data.id > 0 ? data.id : 0)) + '_' + key;
        let dataItem = temp[key] === undefined ? '' : temp[key];
        if (['birth', 'validity'].includes(key) && dataItem) {
            if (dataItem instanceof Date) {
                dataItem = dateBackendAdapter(dataItem);
            }
            if (key === 'validity' && dataItem && dataItem.trim() !== '') {
                const newValidityKey = (id ? id : (data && data.id > 0 ? data.id : 0)) + '_' + 'hasValidity';
                p[newValidityKey] = true;
            }
            p[newKey] = dataItem.indexOf('-') > -1 ? dataItem.substring(0, 10).split('-').reverse().join('.') : dataItem;
        } else {
            p[newKey] = temp[key];
        }
    }
    return p;
}

export const b64_to_utf8 = (str?: string) => {
    return str ? decodeURIComponent(escape(window.atob( str ))) : '';
}

export const setMeta = (title: string, description: string) => {
    // @ts-ignore
    document.querySelector('title').innerText = title;
    // @ts-ignore
    document.querySelector("meta[name='description']")?.setAttribute('content', description);
}
