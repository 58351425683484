import { styled } from '@mui/material/styles';
import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers';

const CustomDatePicker = styled(StaticDatePicker, {
  shouldForwardProp: () => true,
})<StaticDatePickerProps<Date>>(() => ({
  '.MuiDateCalendar-root': {
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 'unset',
  },
  '.MuiDayCalendar-weekContainer': {
    margin: 0,
  },
  '.MuiPickersDay-root': {
    margin: '0',
    width: '40px',
  },
  '.Mui-selected': {
    backgroundColor: 'var(--black-222222) !important',
    color: 'var(--white-ffffff) !important',
    borderRadius: 8,
  },
  '.MuiPickersDay-hiddenDaySpacingFiller': {
    backgroundColor: 'transparent !important',
  },
})) as React.ComponentType<StaticDatePickerProps<Date>>;

export default CustomDatePicker;
