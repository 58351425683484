import { FunctionComponent } from 'react';
import styles from './MainNavigation.module.scss';
import { useTranslation } from 'react-i18next';
import { LangLink } from '../../LangLink/LangLing';

const links = [
  { label: 'links.tariff', href: '/tariff' },
  { label: 'links.help', href: '/help' },
  { label: 'links.blog', href: '/blog' },
  { label: 'links.feedback', href: '/feedback' },
];

type Props = {
  variant?: 'light' | 'dark';
};

const MainNavigation: FunctionComponent<Props> = ({ variant }) => {
  const { t } = useTranslation();

  const style = variant === 'dark' ? styles.navDark : styles.navLight;
  return (
    <nav className={style}>
      {links.map(link => (
        <div key={link.label} className={styles.link}>
          <LangLink to={link.href}>{t(link.label)}</LangLink>
        </div>
      ))}
    </nav>
  );
};

export default MainNavigation;
