import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClickAwayListener, TextField } from '@mui/material';

import PassengersDropdown from './PassengersDropdown';
import styles from '../Select/Select.module.scss';
import { searchSelector } from '../../../store/slices/search';
import { getRightEndingPassengers } from '../../../utils/formatting';
import { useTranslation } from 'react-i18next';

const getValue = (adults: number, children: number, babies: number) => adults + children + babies;

const PassengersSelect: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { adults, children, babies } = useSelector(searchSelector);

  const value = getRightEndingPassengers(getValue(adults, children, babies));

  const handleOpenModal = () => {
    setOpen(s => !s);
  };

  const { t } = useTranslation();

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.PassengersSelect}>
        <TextField
          ref={ref}
          variant="filled"
          sx={{ p: 0, width: '100%' }}
          classes={{ root: styles.textField }}
          label={t('main.searchBar.labels.passengersClass')}
          onClick={handleOpenModal}
          value={value}
          InputLabelProps={{ shrink: !!value }}
          InputProps={{ readOnly: true }}
        />
        <PassengersDropdown anchorRef={ref} open={open} id="passengersDropdown" />
      </div>
    </ClickAwayListener>
  );
};

export default PassengersSelect;
