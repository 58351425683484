import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {ICategory, IPost} from "../../types/post";
import {BASE_URL} from "../../utils/api";

const basicHeaders = {Accept: 'application/json', 'Content-type': 'application/json'};

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: builder => ({
        getCategories: builder.query<{ data: ICategory[] }, {}>({
            query: () => ({
                url: 'blog/categories',
                headers: {Accept: 'text/html'}
            }),
        }),
        getPosts: builder.query<{ data: IPost[] }, {}>({
            query: () => ({
                url: 'blog/posts',
                headers: {Accept: 'text/html'}
            }),
        }),
    }),
});

export const {useGetCategoriesQuery, useGetPostsQuery} = blogApi;